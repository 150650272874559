import axios from 'axios';
import React, { Component } from 'react';
import { string } from '../Constants/string';
import config from '../Constants/config';
import cookieStorage from '../Constants/cookie-storage';
import String from '../Constants/validator';
import Constant from '../Constants/validator';
import api from './api';
import Swal from 'sweetalert2';
import _, { includes } from 'lodash';
import color from '../Constants/color';

import getRegion from '../API_Responses/getRegion.json';
import getClassDetails from '../API_Responses/getClassDetails.json'
import getCountryByRegionId from '../API_Responses/getCountryByRegionId.json'
import getCityByCountryCode from '../API_Responses/getCityByCountryCode.json'
import posytd from '../API_Responses/posytd.json'
import postop5ODsmultibarchart from '../API_Responses/postop5ODsmultibarchart.json'
import posperformanceview from '../API_Responses/posperformanceview.json'
import possalesanalysis from '../API_Responses/possalesanalysis.json'
import postop5agentsmultibarchart from '../API_Responses/postop5agentsmultibarchart.json'
import poscards from '../API_Responses/poscards.json'
import posAgentperformanceview from '../API_Responses/posAgentperformanceview.json'
import posProducttable from '../API_Responses/posProducttable.json'
import posmarketsharetable from '../API_Responses/posmarketsharetable.json'
import topmarkettable from '../API_Responses/topmarkettable.json'
import topODagents from '../API_Responses/topODagents.json'
import topODProducts from '../API_Responses/topODProducts.json'
import channelperformance from '../API_Responses/channelperformance.json'
import posDataMonthly from '../API_Responses/posDataMonthly.json'
import posRegionDrillDown from '../API_Responses/posRegionDrillDown.json'
import posCountryDrillDown from '../API_Responses/posCountryDrillDown.json'
import posCityDrillDown from '../API_Responses/posCityDrillDown.json'
import posCommonODDrillDown from '../API_Responses/posCommonODDrillDown.json'
import posProductDrillDown from '../API_Responses/posProductDrillDown.json'
import posAgentsDrillDown from '../API_Responses/posAgentsDrillDown.json'
import routeytd from '../API_Responses/routeytd.json'
import routecards from '../API_Responses/routecards.json'
import routeGraphAPIGrossWeight from '../API_Responses/routeGraphAPIGrossWeight.json'
import routeGraphAPIAvgFare from '../API_Responses/routeGraphAPIAvgFare.json'
import routeGraphAPIRevenue from '../API_Responses/routeGraphAPIRevenue.json'
import routeGraphAPILoadFactor from '../API_Responses/routeGraphAPILoadFactor.json'
import routeregionwisetable from '../API_Responses/routeregionwisetable.json'
import routeaircraftPerformanceTable from '../API_Responses/routeaircraftPerformanceTable.json'
import routetop5routestable from '../API_Responses/routetop5routestable.json'
import routeDataMonthly from '../API_Responses/routeDataMonthly.json'
import marketsharegraph from '../API_Responses/marketsharegraph.json'
import rpmtd from '../API_Responses/rpmtd.json'
import rpcards from '../API_Responses/rpcards.json'
import rpGraphAPISurplus from '../API_Responses/rpGraphAPISurplus.json'
import rpGraphAPILoadFactor from '../API_Responses/rpGraphAPILoadFactor.json'
import rpGraphAPILoadRCTKM from '../API_Responses/rpGraphAPIRCTKM.json'
import rpGraphAPICCTKM from '../API_Responses/rpGraphAPICCTKM.json'
import rpregionperformance from '../API_Responses/rpregionperformance.json'
import rpaircraftperformance from '../API_Responses/rpaircraftperformance.json'
import rptop10routes from '../API_Responses/rptop10routes.json'
import routeRegionDrillDown from '../API_Responses/routeRegionDrillDown.json'
import routeCountryDrillDown from '../API_Responses/routeCountryDrillDown.json'
import routeCountryRouteDrillDown from '../API_Responses/routeCountryRouteDrillDown.json'
import routeLegDrillDown from '../API_Responses/routeLegDrillDown.json'
import routeFlightDrillDown from '../API_Responses/routeFlightDrillDown.json'
import routeAircraftDrillDown from '../API_Responses/routeAircraftDrillDown.json'
import routeServiceDrillDown from '../API_Responses/routeServiceDrillDown.json'
import routeCountryRouteNonDrillDown from '../API_Responses/routeCountryRouteNonDrillDown.json'
import routeFlightNonDrillDown from '../API_Responses/routeFlightNonDrillDown.json'
import routeAircraftNonDrillDown from '../API_Responses/routeAircraftNonDrillDown.json'
import routeServiceNonDrillDown from '../API_Responses/routeServiceNonDrillDown.json'
import routeprofitabilitymonthly from '../API_Responses/routeprofitabilitymonthly.json'
import rrmonthlydropDown from '../API_Responses/rrmonthlydropDown.json'
import routeProfitabilityRegionDrillDown from '../API_Responses/routeProfitabilityRegionDrillDown.json'
import routeProfitabilityCountryDrillDown from '../API_Responses/routeProfitabilityCountryDrillDown.json'
import routeProfitabilityRouteDrillDown from '../API_Responses/routeProfitabilityRouteDrillDown.json'
import routeProfitabilityLegDrillDown from '../API_Responses/routeProfitabilityLegDrillDown.json'
import routeProfitabilityFlightDrillDown from '../API_Responses/routeProfitabilityFlightDrillDown.json'
import routeProfitabilityAircraftDrillDown from '../API_Responses/routeProfitabilityAircraftDrillDown.json'
import routeProfitabilityServiceDrillDown from '../API_Responses/routeProfitabilityServiceDrillDown.json'
import routeProfitabilityRouteNonDrillDown from '../API_Responses/routeProfitabilityRouteNonDrillDown.json'
import routeProfitabilityFlightNonDrillDown from '../API_Responses/routeProfitabilityFlightNonDrillDown.json'
import routeProfitabilityAircraftNonDrillDown from '../API_Responses/routeProfitabilityAircraftNonDrillDown.json'
import routeProfitabilityServiceNonDrillDown from '../API_Responses/routeProfitabilityServiceNonDrillDown.json'
import routeprofitabilitynoncommercialtable from '../API_Responses/routeprofitabilitynoncommercialtable.json'
import routeprofitabilitycommercialtable from '../API_Responses/routeprofitabilitycommercialtable.json'
import rr4thPageAPI from '../API_Responses/rr4thPageAPI.json'
import getRouteRegion from '../API_Responses/getRouteRegion.json';
import getRouteCountryByRegionId from '../API_Responses/getRouteCountryByRegionId.json'
import getRouteCityByCountryCode from '../API_Responses/getRouteCityByCountryCode.json'

import posDataCity from '../API_Responses/posDataCity.json';
import posDataCountryDrillDown from '../API_Responses/posDataCountryDrillDown.json'
import posDataCommonOD from '../API_Responses/posDataCommonOD.json'

import routeDataCountryDrillDown from '../API_Responses/routeDataCountryDrillDown.json';
import routeDataRouteDrillDown from '../API_Responses/routeDataRouteDrillDown.json';
import routeDataLegDrillDown from '../API_Responses/routeDataLegDrillDown.json';
import routeDataFlightDrillDown from '../API_Responses/routeDataFlightDrillDown.json';
import routeDataAircraftDrillDown from '../API_Responses/routeDataAircraftDrillDown.json'

import routeProfitabilityDataCountryDrillDown from '../API_Responses/routeProfitabilityDataCountryDrillDown.json';
import routeProfitabilityDataRouteDrillDown from '../API_Responses/routeProfitabilityDataRouteDrillDown.json';
import routeProfitabilityDataLegDrillDown from '../API_Responses/routeProfitabilityDataLegDrillDown.json';
import routeProfitabilityDataFlightDrillDown from '../API_Responses/routeProfitabilityDataFlightDrillDown.json';
import routeProfitabilityDataAircraftDrillDown from '../API_Responses/routeProfitabilityDataAircraftDrillDown.json';

const API_URL = config.API_URL;

const currentYear = new Date().getFullYear()

const DashboardParams = (startDate, endDate, regionId, countryId, cityId) => {
    return `startdate=${startDate}&enddate=${endDate}&regionId=${String.addQuotesforMultiSelect(regionId)}&countryId=${String.addQuotesforMultiSelect(countryId)}&cityId=${String.addQuotesforMultiSelect(cityId)}`
}

const RoouteDashboardParams = (startDate, endDate, regionId, countryId, routeId) => {
    return `startdate=${startDate}&enddate=${endDate}&selectedRouteRegion=${String.addQuotesforMultiSelect(regionId)}&selectedRouteCountry=${String.addQuotesforMultiSelect(countryId)}&selectedRoute=${String.addQuotesforMultiSelect(routeId)}`
}

const FilterParams = (regionId, countryId, cityId, getCabinValue) => {
    let cabinValue = getCabinValue !== undefined ? typeof (getCabinValue) === 'string' ? getCabinValue : `'${encodeURIComponent(getCabinValue.join("','"))}'` : 'Null'
    return `regionId=${String.addQuotesforMultiSelect(regionId)}&countryId=${String.addQuotesforMultiSelect(countryId)}&cityId=${String.addQuotesforMultiSelect(cityId)}&getCabinValue=${cabinValue}`
}

const Params = (regionId, countryId, cityId, getCabinValue) => {
    let region = typeof (regionId) === 'string' ? regionId === '*' ? regionId : `${encodeURIComponent(regionId)}` : `'${encodeURIComponent(regionId.join("','"))}'`
    let country = typeof (countryId) === 'string' ? countryId === '*' ? countryId : `${countryId}` : `'${countryId.join("','")}'`
    let city = typeof (cityId) === 'string' ? cityId === '*' ? cityId : `${cityId}` : `'${cityId.join("','")}'`
    let cabinValue = typeof (getCabinValue) === 'string' ? getCabinValue : `'${encodeURIComponent(getCabinValue.join("','"))}'`

    return `regionId=${region}&countryId=${country}&cityId=${city}&getCabinValue=${cabinValue}`
}
const ROUTEParams = (regionId, countryId, routeId, getCabinValue) => {
    let region = typeof (regionId) === 'string' ? regionId === '*' ? regionId : `${encodeURIComponent(regionId)}` : `'${encodeURIComponent(regionId.join("','"))}'`
    let country = typeof (countryId) === 'string' ? countryId === '*' ? countryId : `${countryId}` : `'${countryId.join("','")}'`
    let route = typeof (routeId) === 'string' ? routeId === '*' ? routeId : `${routeId}` : `'${routeId.join("','")}'`
    let cabinValue = typeof (getCabinValue) === 'string' ? getCabinValue : `'${encodeURIComponent(getCabinValue.join("','"))}'`

    return `selectedRouteRegion=${region}&selectedRouteCountry=${country}&selectedRoute=${route}&getCabinValue=${cabinValue}`
}

// const width = window.innerWidth;
// const  = width < 720 ? width / 5 : width / 18
// const  = width < 720 ? width / 5 : width / 15

export default class APIServices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false
        }
    }

    Params = (regionId, countryId, cityId, getCabinValue) => {
        let region = typeof (regionId) === 'string' ? regionId === '*' ? regionId : `${encodeURIComponent(regionId)}` : `'${encodeURIComponent(regionId.join("','"))}'`
        let country = typeof (countryId) === 'string' ? countryId === '*' ? countryId : `${countryId}` : `'${countryId.join("','")}'`
        let city = typeof (cityId) === 'string' ? cityId === '*' ? cityId : `${cityId}` : `'${cityId.join("','")}'`
        let cabinValue = typeof (getCabinValue) === 'string' ? getCabinValue : `'${encodeURIComponent(getCabinValue.join("','"))}'`

        return `regionId=${region}&countryId=${country}&cityId=${city}&getCabinValue=${cabinValue}`
    }

    //Constant Functions
    getDefaultHeader = () => {
        const token = cookieStorage.getCookie('Authorization');
        return (
            {
                headers: {
                    'Authorization': token,
                    // 'bypassauth': 'yes',
                    // 'user': 'vishal@rejolut.com'
                }
            }
        )
    }

    errorHandling = (error) => {
        console.log('error::::::', error, error.response)
        if (error && error.response) {
            if (error.response.status === 500) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong. Please try after some time',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            } else if (error.response.status === 403) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Authorization failed! (Your token has been expired. Please login again)',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    window.location = '/'
                })
            }
        }
    }

    arrowIndicator = (params) => {
        var element = document.createElement("span");
        var icon = document.createElement("i");

        // visually indicate if this months value is higher or lower than last months value
        let VLY = params.value ? params.value : '0';
        const numericVLY = parseFloat(VLY);
        if (VLY === '---') {
            icon.className = ''
        } else if (typeof VLY === 'string') {
            if (VLY.includes('B') || VLY.includes('M') || VLY.includes('K')) {
                icon.className = 'fa fa-arrow-up'
            } else if (numericVLY > 0) {
                icon.className = 'fa fa-arrow-up'
            } else if (numericVLY < 0) {
                icon.className = 'fa fa-arrow-down'
            } else {
                icon.className = ''
            }
        } else if (numericVLY > 0) {
            icon.className = 'fa fa-arrow-up'
        } else if (numericVLY < 0) {
            icon.className = 'fa fa-arrow-down'
        } else {
            icon.className = ''
        }
        element.appendChild(document.createTextNode(VLY));
        element.appendChild(icon);
        return element;
    }

    costArrowIndicator = (params) => {
        var element = document.createElement("span");
        var icon = document.createElement("i");

        // visually indicate if this months value is higher or lower than last months value
        let VLY = params.value ? params.value : '0';
        const numericVLY = parseFloat(VLY);
        if (VLY === '---') {
            icon.className = ''
        } else if (typeof VLY === 'string') {
            if (VLY.includes('B') || VLY.includes('M') || VLY.includes('K')) {
                icon.className = 'fa cost-up fa-arrow-up'
            } else if (numericVLY > 0) {
                icon.className = 'fa cost-up fa-arrow-up'
            } else if (numericVLY < 0) {
                icon.className = 'fa cost-down fa-arrow-down'
            } else {
                icon.className = ''
            }
        } else if (numericVLY > 0) {
            icon.className = 'fa cost-up fa-arrow-up'
        } else if (numericVLY < 0) {
            icon.className = 'fa cost-down fa-arrow-down'
        } else {
            icon.className = ''
        }
        element.appendChild(document.createTextNode(VLY));
        element.appendChild(icon);
        return element;
    }

    accuracyArrowIndicator = (params) => {
        var element = document.createElement("span");
        var icon = document.createElement("i");
        let VLY = params.value ? params.value : '0';
        const accuracy = parseFloat(VLY);
        if (accuracy === 0) {
            icon.className = ''
        } else if (accuracy >= 86 && accuracy <= 100) {
            icon.className = 'fa fa-arrow-up'
        } else if (accuracy <= 86 || accuracy >= 100) {
            icon.className = 'fa fa-arrow-down'
        } else {
            icon.className = ''
        }
        element.appendChild(document.createTextNode(VLY));
        element.appendChild(icon);
        return element;
    }

    barsIndicator() {
        var element = document.createElement("span");
        var icon = document.createElement("i");
        icon.className = 'fa fa-bar-chart-o';
        element.appendChild(icon);
        return element;
    }

    convertZeroValueToBlank(Value) {
        let convertedValue = window.numberFormat(Value) === 0 ? '0' : window.numberFormat(Value);
        return convertedValue
    }

    topperFixed(Value) {
        let convertedValue = window.numberFormat(Value, 2) === '0.00' ? '---' : window.numberFormat(Value, 2);
        return convertedValue
    }

    showPercent(value) {
        return value === null || value === 0 ? '' : '%'
    }

    customSorting = (a, b) => {
        let valueA = window.convertNumberFormat(a);
        let valueB = window.convertNumberFormat(b);
        return valueA - valueB;
    }

    getUserPreferences(key, value, count) {
        let userData = cookieStorage.getCookie('userDetails');
        userData = userData ? JSON.parse(userData) : ''
        const userPreference = api.get(`userpreferences`, 'hideloader')
            .then((response) => {
                if (response && response.data.response.length > 0) {
                    const responseData = response.data.response[0];
                    if (responseData[key] !== value) {
                        this.updateUserPreferences(key, value, userData);
                        const valueWithoutNull = responseData[key] ? responseData[key] : 0
                        return count ? value - valueWithoutNull : true;
                    } else {
                        return count ? 0 : false;
                    }
                } else {
                    this.postUserPreferences(key, value, userData);
                    return count ? 1 : true;
                }
            })
            .catch((err) => {
                console.log("user preferences error", err);
            })

        return userPreference
    }

    postUserPreferences(key, value, userData) {
        const postData = {
            user_email: userData.email,
            [key]: value
        }
        api.post(`userpreferences`, postData, 'hideloader')
            .then((response) => {
                console.log("user preferences response", response);
            })
            .catch((err) => {
                console.log("user preferences error", err);
            })
    }

    updateUserPreferences(key, value, userData) {
        const updatedData = {
            user_email: userData.email,
            [key]: value
        }
        api.put(`userpreferences`, updatedData, 'hideloader')
            .then((response) => {
                console.log("user preferences response", response);
            })
            .catch((err) => {
                console.log("user preferences error", err);
            })
    }


    //Dashboard Common API
    getMonthsRange() {
        const url = `${API_URL}/getmonthrange`;
        var monthsRange = axios.get(url)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
            });
        return monthsRange;
    }

    getRegions() {
        const header = this.getDefaultHeader()
        const url = `${API_URL}`;
        // const url = `${API_URL}/getRegion`;
        var regions = axios.get(url)
            .then((response) => getRegion)
            .catch((error) => {
                console.log(error)
            });
        return regions;
    }

    getCountries(regionId) {
        let region = typeof (regionId) === 'string' ? regionId : `'${encodeURIComponent(regionId.join("','"))}'`
        // const url = `${API_URL}/getCountryByRegionId?regionId=${region}`;
        const url = `${API_URL}`;
        var countries = axios.get(url)
            .then((response) => getCountryByRegionId)
            .catch((error) => {
                console.log(error)
            });
        return countries;
    }

    getCities(regionId, countryId) {
        let country = typeof (countryId) === 'string' ? countryId : `'${countryId.join("','")}'`
        let region = typeof (regionId) === 'string' ? regionId : `'${regionId.join("','")}'`
        // const url = `${API_URL}/getCityByCountryCode?regionId=${region}&countryCode=${country}`;
        const url = `${API_URL}`;
        var cities = axios.get(url)
            .then((response) => getCityByCountryCode)
            .catch((error) => {
                console.log(error)
            });
        return cities;
    }

    getODs(regionId, countryId, cityId) {
        let country = typeof (countryId) === 'string' ? countryId : `'${countryId.join("','")}'`
        let region = typeof (regionId) === 'string' ? regionId : `'${regionId.join("','")}'`
        let city = typeof (cityId) === 'string' ? cityId : `'${cityId.join("','")}'`
        const url = `${API_URL}/getCommonODByCity?regionId=${region}&countryCode=${country}&cityCode=${city}`;
        var ODs = axios.get(url)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
            });
        return ODs;
    }

    getIndicatorsData(startDate, endDate, routeGroup, regionId, countryId, cityId, routeId, service, dashboard) {
        const header = this.getDefaultHeader()
        let link = '';
        let params = null;
        if (dashboard === 'Pos') {
            link = 'posytd'
            params = DashboardParams(startDate, endDate, regionId, countryId, cityId)
        } else if (dashboard === 'Route') {
            link = 'routeytd'
            params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${service}`
        } else if (dashboard === 'Route Revenue Planning') {
            link = 'routeytd'
            params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        } else if (dashboard === 'Route Profitability') {
            link = 'rpmtd'
            params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${String.removeQuotes(service)}`
        }

        if(link == 'posytd'){

            const url = `${API_URL}`;
            // const url = `${API_URL}/${link}?${params}`
            var indicatorsData = axios.get(url, header)
            .then((response) => posytd.data.response)
            .catch((error) => {
                console.log(error)
            });
        }else if(link == 'routeytd'){
            const url = `${API_URL}`;
            // const url = `${API_URL}/${link}?${params}`
            var indicatorsData = axios.get(url, header)
            .then((response) => routeytd.data.response)
            .catch((error) => {
                console.log(error)
            });
        }else if(link == 'rpmtd'){
            const url = `${API_URL}`;
            // const url = `${API_URL}/${link}?${params}`
            var indicatorsData = axios.get(url, header)
            .then((response) => rpmtd.data.response)
            .catch((error) => {
                console.log(error)
            });
        }
        
        return indicatorsData;
    }

    getAnciallaryItems(startDate, endDate, routeGroup, regionId, countryId, cityId, routeId, posDashboard) {
        let that = this;
        const link = posDashboard ? `posProducttable` : `routeancillarytable`
        const params = posDashboard ? DashboardParams(startDate, endDate, regionId, countryId, cityId) : `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        // const url = `${API_URL}/${link}?${params}`;
        const url = `${API_URL}`;
        var ancillaryData = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.Product,
                    headerTooltip: 'Product', field: 'Product', tooltipField: 'Product_AB', width: 300,
                    alignLeft: true
                },
                {
                    headerName: string.columnName.CY,
                    headerTooltip: 'CY', field: 'CY', tooltipField: 'CY_AB',
                    sortable: true,
                    comparator: that.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.LY,
                    headerTooltip: 'LY', field: 'LY', tooltipField: 'LY_AB',
                    sortable: true,
                    comparator: that.customSorting
                },
                {
                    headerName: string.columnName.VLY,
                    headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250,
                    sortable: true, comparator: this.customSorting
                },
                // {
                //     headerName: string.columnName.VTG,
                //     headerTooltip: 'VTG(%)', field: 'VTG', tooltipField: 'VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250,
                //     sortable: true, comparator: this.customSorting
                // }
            ];

            var ancillaryDetails = [];
            posProducttable.data.response[0].TableData.forEach(function (key) {
                ancillaryDetails.push({
                    "Product": key.ProductName,
                    "Product_AB": key.ProductToolTip,
                    "AnciallaryCodeName": key.AncillaryCode,
                    "CY": that.convertZeroValueToBlank(key.CY_Revenue),
                    "LY": that.convertZeroValueToBlank(key.LY_Revenue),
                    "VLY": that.convertZeroValueToBlank(key.Product_VLY),
                    'VTG': that.convertZeroValueToBlank(key.Product_VTG),
                    "CY_AB": window.numberWithCommas(key.CY_Revenue),
                    "LY_AB": window.numberWithCommas(key.LY_Revenue),
                    "VLY_AB": window.numberWithCommas(key.Product_VLY),
                    'VTG_AB': window.numberWithCommas(key.Product_VTG)
                });
            });

            var totalData = [];
            posProducttable.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "Product": 'Total',
                    "AnciallaryCodeName": key.AncillaryCode,
                    "CY": that.convertZeroValueToBlank(key.Product_CY),
                    "LY": that.convertZeroValueToBlank(key.Product_LY),
                    "VLY": that.convertZeroValueToBlank(key.Product_VLY),
                    'VTG': that.convertZeroValueToBlank(key.Product_VTG),
                    "CY_AB": window.numberWithCommas(key.Product_CY),
                    "LY_AB": window.numberWithCommas(key.Product_LY),
                    "VLY_AB": window.numberWithCommas(key.Product_VLY),
                    'VTG_AB': window.numberWithCommas(key.Product_VTG)
                });
            });

            return [{ "columnName": columnName, "ancillaryDetails": ancillaryDetails, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return ancillaryData;
    }

    getAircraftItems(startDate, endDate, routeGroup, regionId, countryId, cityId, routeId, service, posDashboard) {
        let that = this;
        const link = posDashboard ? `posProducttable` : `routeaircraftPerformanceTable`
        const params = posDashboard ? DashboardParams(startDate, endDate, regionId, countryId, cityId) : `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        const servicetype = posDashboard ? `routeService` : `getsvcType`
        // const url = `${API_URL}/${link}?${params}&${servicetype}=${service}`;
        const url = `${API_URL}`;
        var ancillaryData = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.Aircraft,
                    headerTooltip: 'Aircraft', field: 'Aircraft', tooltipField: 'Aircraft', width: 300,
                    alignLeft: true
                },
                {
                    headerName: string.columnName.CY,
                    headerTooltip: 'CY', field: 'CY', tooltipField: 'CY_AB',
                    sortable: true,
                    comparator: that.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.LY,
                    headerTooltip: 'LY', field: 'LY', tooltipField: 'LY_AB',
                    sortable: true,
                    comparator: that.customSorting
                },
                {
                    headerName: string.columnName.VLY,
                    headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250,
                    sortable: true, comparator: this.customSorting
                },
                {
                    headerName: string.columnName.VTG,
                    headerTooltip: 'VTG(%)', field: 'VTG', tooltipField: 'VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250,
                    sortable: true, comparator: this.customSorting
                }
            ];
            var ancillaryDetails = [];
            routeaircraftPerformanceTable.data.response[0].TableData.forEach(function (key) {
                ancillaryDetails.push({
                    "Aircraft": key.Aircraft,
                    "AnciallaryCodeName": key.AncillaryCode,
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    'VTG': that.convertZeroValueToBlank(key.VTG),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VLY_AB": window.numberWithCommas(key.VLY),
                    'VTG_AB': window.numberWithCommas(key.VTG)
                });
            });

            var totalData = [];
            routeaircraftPerformanceTable.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "Aircraft": 'Total',
                    "AnciallaryCodeName": key.AncillaryCode,
                    "CY": that.convertZeroValueToBlank(key.Aircraft_CY),
                    "LY": that.convertZeroValueToBlank(key.Aircraft_LY),
                    "VLY": that.convertZeroValueToBlank(key.Aircraft_VLY),
                    'VTG': that.convertZeroValueToBlank(key.Aircraft_VTG),
                    "CY_AB": window.numberWithCommas(key.Aircraft_CY),
                    "LY_AB": window.numberWithCommas(key.Aircraft_LY),
                    "VLY_AB": window.numberWithCommas(key.Aircraft_VLY),
                    'VTG_AB': window.numberWithCommas(key.Aircraft_VTG)
                });
            });

            return [{ "columnName": columnName, "ancillaryDetails": ancillaryDetails, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return ancillaryData;
    }

    getCabinBudget(startDate, endDate, routeGroup, regionId, countryId, cityId, routeId, posDashboard) {
        let that = this;
        const link = posDashboard ? `posancillarytable` : `routeancillarytable`
        const params = posDashboard ? DashboardParams(startDate, endDate, regionId, countryId, cityId) : `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        const url = `${API_URL}/${link}?${params}`;
        var ancillaryData = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.Cabin,
                    headerTooltip: 'Cabin', field: 'Cabin', tooltipField: 'Cabin', width: 300,
                    alignLeft: true
                },
                {
                    headerName: string.columnName.BUDGET, headerTooltip: 'Budget', field: 'Budget',
                    tooltipField: 'Budget_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.LY, headerTooltip: 'LY', field: 'LY',
                    tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting
                },
                {
                    headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250,
                    cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                }
            ];

            var ancillaryDetails = [];
            response.data.response[0].TableData.forEach(function (key) {
                ancillaryDetails.push({
                    "Cabin": key.AncillaryName,
                    "Budget": that.convertZeroValueToBlank(key.Ancillary_CY),
                    "LY": that.convertZeroValueToBlank(key.Ancillary_LY),
                    "VLY": that.convertZeroValueToBlank(key.Ancillary_VLY),
                    "Budget_AB": window.numberWithCommas(key.Ancillary_CY),
                    "LY_AB": window.numberWithCommas(key.Ancillary_LY),
                    "VLY_AB": window.numberWithCommas(key.Ancillary_VLY)
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "Cabin": 'Total',
                    "Budget": that.convertZeroValueToBlank(key.Ancillary_CY),
                    "LY": that.convertZeroValueToBlank(key.Ancillary_LY),
                    "VLY": that.convertZeroValueToBlank(key.Ancillary_VLY),
                    "Budget_AB": window.numberWithCommas(key.Ancillary_CY),
                    "LY_AB": window.numberWithCommas(key.Ancillary_LY),
                    "VLY_AB": window.numberWithCommas(key.Ancillary_VLY)
                });
            });

            return [{ "columnName": columnName, "ancillaryDetails": ancillaryDetails, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return ancillaryData;
    }

    getDataLoadIndicator() {
        const url = `${API_URL}/dataloadedindicator`;
        var dataloadindicator = axios.get(url).then((response) => {
            var columnName = [
                {
                    headerName: 'Sr.No.', headerTooltip: 'Sr.No.',
                    field: "Sr_No", tooltipField: "Sr_No", alignLeft: true, width: 100
                },
                {
                    headerName: 'Data Source', headerTooltip: 'Data Source',
                    field: 'DataSource', tooltipField: 'DataSource', alignLeft: true
                },
                {
                    headerName: 'Refresh Frequency', headerTooltip: 'Refresh Frequency',
                    field: 'Refresh_Frequency', tooltipField: 'Refresh_Frequency', alignLeft: true
                },
                {
                    headerName: 'Last Updated Date', headerTooltip: 'Last Updated Date',
                    field: 'Last_Updated_Date', tooltipField: 'Last_Updated_Date', alignLeft: true, width: 100
                },
                {
                    headerName: 'Status', headerTooltip: 'Status',
                    field: 'Status', tooltipField: 'Status', alignLeft: true, width: 100,
                    cellStyle: (params) => {
                        var Status = params.data.Status;
                        let color = '';
                        if (Status) {
                            if (Status.toLowerCase().includes('s')) {
                                color = 'rgb(17, 247, 17)'
                            } else if (Status.toLowerCase().includes('f')) {
                                color = 'rgb(216, 62, 62)'
                            }
                        }
                        return {
                            color: color,
                            'text-transform': 'capitalize'
                        };
                    }
                },
                {
                    headerName: 'Comment', headerTooltip: 'Comment',
                    field: 'Comment', tooltipField: 'Comment', alignLeft: true
                }
            ];

            var rowData = [];
            response.data.response.forEach(function (key, i) {
                rowData.push({
                    "Sr_No": i + 1,
                    "DataSource": key.DataSource,
                    "Refresh_Frequency": key.Refresh_Frequency,
                    "Last_Updated_Date": key.Last_Updated_Date,
                    "Status": key.Status,
                    "Comment": key.Comments
                });
            });

            return [{ "columnName": columnName, "rowData": rowData }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error)
            });

        return dataloadindicator;
    }

    //POS Dashboard API
    getODsBarChart(startDate, endDate, regionId, countryId, cityId) {
        // const url = `${API_URL}/postop5ODsmultibarchart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => postop5ODsmultibarchart.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getAgentsBarChart(startDate, endDate, regionId, countryId, cityId) {
        // const url = `${API_URL}/postop10agentsmultibarchart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getRegionBarChart(startDate, endDate, regionId, countryId, cityId) {
        // const url = `${API_URL}/posperformanceview?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => posperformanceview.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getSegmentationBarChart(startDate, endDate, regionId, countryId, cityId) {
        // const url = `${API_URL}/postop5agentsmultibarchart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => postop5agentsmultibarchart.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getSalesAnalysisLineChart(startDate, endDate, regionId, countryId, cityId, trend, graphType, selectedGraph) {
        // const url = `${API_URL}/possalesanalysis?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}&trend=${trend}&graphType=${graphType}&graphCategory=${selectedGraph}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => possalesanalysis.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getPOSCards(startDate, endDate, regionId, countryId, cityId) {
        const header = this.getDefaultHeader()
        // const url = `${API_URL}/poscards?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        var cardData = axios.get(url, header)
            .then((response) => poscards.data.response)
            .catch((error) => {
                console.log(error)
            });
        return cardData;
    }

    getChanneltable(startDate, endDate, regionId, countryId, cityId) {
        // const url = `${API_URL}/posAgentperformanceview?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        var route = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                { headerName: string.columnName.AGENTS, headerTooltip: 'Agents', field: 'Agents', tooltipField: 'Agents', width: 300, alignLeft: true },
                { headerName: string.columnName.CY, headerTooltip: 'CY', field: 'CY', tooltipField: 'CY_AB', sortable: true, comparator: this.customSorting, sort: 'desc' },
                { headerName: string.columnName.LY, headerTooltip: 'LY', field: 'LY', tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting },
                { headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250, sortable: true, comparator: this.customSorting },
                //{ headerName: string.columnName.VTG, headerTooltip: 'VTG(%)', field: "VTG", tooltipField: 'VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250, sortable: true, comparator: this.customSorting },
                // { headerName: 'Budget', headerTooltip: 'Budget', field: 'Budget', tooltipField: 'Budget', width: 250 }
            ];

            let that = this;
            var rowData = [];
            posAgentperformanceview.data.response[0].TableData.forEach(function (key) {
                rowData.push({
                    "Agents": key.AgentName,
                    "CY": that.convertZeroValueToBlank(key.Revenue_CY),
                    "LY": that.convertZeroValueToBlank(key.Revenue_LY),
                    "VLY": that.convertZeroValueToBlank(key.Revenue_VLY),
                    //'VTG': that.convertZeroValueToBlank(key.VTG),
                    "CY_AB": window.numberWithCommas(key.Revenue_CY),
                    "LY_AB": window.numberWithCommas(key.Revenue_LY),
                    "VLY_AB": window.numberWithCommas(key.Revenue_VLY),
                    //'VTG_AB': window.numberWithCommas(key.VTG)
                });
            });

            var totalData = [];
            posAgentperformanceview.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "Agents": 'Total',
                    "CY": that.convertZeroValueToBlank(key.Revenue_CY),
                    "LY": that.convertZeroValueToBlank(key.Revenue_LY),
                    "VLY": that.convertZeroValueToBlank(key.Revenue_VLY),
                    //'VTG': that.convertZeroValueToBlank(key.VTG),
                    "CY_AB": window.numberWithCommas(key.Revenue_CY),
                    "LY_AB": window.numberWithCommas(key.Revenue_LY),
                    "VLY_AB": window.numberWithCommas(key.Revenue_VLY),
                    //'VTG_AB': window.numberWithCommas(key.VTG)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return route;
    }

    getMarketShare(startDate, endDate, regionId, countryId, cityId) {
        // const url = `${API_URL}/posmarketsharetable?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        const url = `${API_URL}`;
        var marketShareDatas = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.OD, headerTooltip: 'OD', field: 'OD', tooltipField: 'OD',
                    width: 200, alignLeft: true
                },
                {
                    headerName: 'CMSV', headerTooltip: 'Current Market Share Value', field: 'CMSV', tooltipField: 'CMSV_AB',
                    sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: 'CMSV ' + string.columnName.VLY, headerTooltip: 'Current Market Share Value VLY(%)', field: "VLY_CM", tooltipField: 'VLY_CM_AB',
                    cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                },
                {
                    headerName: 'CALMS', headerTooltip: 'Current Airline Market Share', field: 'CALMS', tooltipField: 'CALMS_AB',
                    sortable: true, comparator: this.customSorting
                },
                {
                    headerName: 'CALMS ' + string.columnName.VLY, headerTooltip: 'Current Airline Market Share VLY(%)', field: "VLY_CA", tooltipField: 'VLY_CA_AB',
                    cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                }
            ];

            var rowData = [];
            let that = this;
            posmarketsharetable.data.response[0].TableData.forEach(function (key) {
                rowData.push({
                    "OD": key.OD,
                    "CMSV": that.convertZeroValueToBlank(key.CMSV_CY),
                    "VLY_CM": that.convertZeroValueToBlank(key.CMSV_VLY),
                    "CALMS": that.convertZeroValueToBlank(key.CALMS_CY),
                    "VLY_CA": that.convertZeroValueToBlank(key.CALMS_VLY),
                    "CMSV_AB": window.numberWithCommas(key.CMSV_CY),
                    "VLY_CM_AB": window.numberWithCommas(key.CMSV_VLY),
                    "CALMS_AB": window.numberWithCommas(key.CALMS_CY),
                    "VLY_CA_AB": window.numberWithCommas(key.CALMS_VLY)
                });
            });

            var totalData = [];
            posmarketsharetable.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "OD": "Total",
                    "CMSV": that.convertZeroValueToBlank(key.CMSV_CY),
                    "VLY_CM": that.convertZeroValueToBlank(key.CMSV_VLY),
                    "CALMS": that.convertZeroValueToBlank(key.CALMS_CY),
                    "VLY_CA": that.convertZeroValueToBlank(key.CALMS_VLY),
                    "CMSV_AB": window.numberWithCommas(key.CMSV_CY),
                    "VLY_CM_AB": window.numberWithCommas(key.CMSV_VLY),
                    "CALMS_AB": window.numberWithCommas(key.CALMS_CY),
                    "VLY_CA_AB": window.numberWithCommas(key.CALMS_VLY)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, "totalData": totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return marketShareDatas;
    }

    getDDSChartData(startDate, endDate, regionId, countryId, cityId, dataType) {
        const url = `${API_URL}/ddschart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}&dataType=${dataType}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                this.errorHandling(error)
            });
    }

    //Route Dashboard API
    getRouteRegions(routeGroup) {
        let routeGroups = typeof (routeGroup) === 'string' ? routeGroup : `'${encodeURIComponent(routeGroup.join("','"))}'`
        // const url = `${API_URL}/getRouteRegion?routeGroup=${routeGroups}`;
        const url = `${API_URL}`;
        var regions = axios.get(url)
            .then((response) => getRouteRegion.data.response)
            .catch((error) => {
                console.log(error)
            });
        return regions;
    }

    getRouteCountries(routeGroup, regionId) {
        let routeGroups = typeof (routeGroup) === 'string' ? routeGroup : `'${encodeURIComponent(routeGroup.join("','"))}'`
        let region = typeof (regionId) === 'string' ? regionId : `'${encodeURIComponent(regionId.join("','"))}'`
        // const url = `${API_URL}/getRouteCountryByRegionId?routeGroup=${routeGroups}&regionId=${region}`;
        const url = `${API_URL}`;
        var countries = axios.get(url)
            .then((response) => getRouteCountryByRegionId.data.response)
            .catch((error) => {
                console.log(error)
            });
        return countries;
    }

    getRoutes(routeGroup, regionId, countryId) {
        let routeGroups = typeof (routeGroup) === 'string' ? routeGroup : `'${encodeURIComponent(routeGroup.join("','"))}'`
        let region = typeof (regionId) === 'string' ? regionId : `'${regionId.join("','")}'`
        let country = typeof (countryId) === 'string' ? countryId : `'${countryId.join("','")}'`
        // const url = `${API_URL}/getRouteCityByCountryCode?routeGroup=${routeGroups}&regionId=${region}&countryId=${country}`;
        const url = `${API_URL}`;
        var routes = axios.get(url)
            .then((response) => getRouteCityByCountryCode.data.response)
            .catch((error) => {
                console.log(error)
            });
        return routes;
    }

    getRevenueBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, routeService, trend, graphType) {
        // const url = `${API_URL}/routeGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphType=${graphType}&graphName=Revenue&getsvcType=${routeService}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => routeGraphAPIRevenue.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getGrossWeightBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, routeService, trend, graphType) {
        // const url = `${API_URL}/routeGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphType=${graphType}&graphName=GrossWeight&getsvcType=${routeService}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => routeGraphAPIGrossWeight.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getAvgFareBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, routeService, trend, graphType) {
        // const url = `${API_URL}/routeGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphType=${graphType}&graphName=AvgFare&getsvcType=${routeService}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => routeGraphAPIAvgFare.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getLoadFactorBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, routeService, trend, graphType) {
        // const url = `${API_URL}/routeGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphType=${graphType}&graphName=LoadFactor&getsvcType=${routeService}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => routeGraphAPILoadFactor.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getRegionWisePerformanceTable(startDate, endDate, routeGroup, regionId, countryId, routeId, service) {
        // const url = `${API_URL}/routeregionwisetable?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${service}`;
        const url = `${API_URL}`;
        var Regionwiseperformance = axios.get(url, this.getDefaultHeader()).then((response) => {
            var tableHead = routeregionwisetable.data.response[0].ColumnName;
            var columnName = [
                { headerName: tableHead, headerTooltip: tableHead, field: 'ColumnName', tooltipField: 'Region', width: 300, alignLeft: true },
                { headerName: string.columnName.CY, headerTooltip: 'CY', field: 'CY', tooltipField: 'CY_AB', sortable: true, comparator: this.customSorting, sort: 'desc' },
                { headerName: string.columnName.LY, headerTooltip: 'LY', field: 'LY', tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting },
                { headerName: string.columnName.VTG, headerTooltip: 'VTG(%)', field: "VTG", tooltipField: 'VTG_AB', width: 250, cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting }
            ];

            let that = this;
            var rowData = [];
            routeregionwisetable.data.response[0].Data.forEach(function (key) {
                rowData.push({
                    "ColumnName": key.ColumnName,
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VTG_AB": window.numberWithCommas(key.VTG)
                });
            });

            var totalData = [];
            routeregionwisetable.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "ColumnName": 'Total',
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VTG_AB": window.numberWithCommas(key.VTG)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, 'totalData': totalData, 'tableHead': tableHead }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return Regionwiseperformance;
    }

    getRouteTable(startDate, endDate, routeGroup, regionId, countryId, routeId, service, toporbottom) {
        // const url = `${API_URL}/routetop5routestable?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&toporbottom=${toporbottom}&getsvcType=${service}`;
        const url = `${API_URL}`;
        var route = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.ROUTE, headerTooltip: string.columnName.ROUTE,
                    field: 'Route', tooltipField: 'Route', width: 300, alignLeft: true
                },
                {
                    headerName: string.columnName.CY, headerTooltip: string.columnName.CY,
                    field: 'CY', tooltipField: 'CY_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.LY, headerTooltip: string.columnName.LY,
                    field: 'LY', tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting
                },
                {
                    headerName: string.columnName.VLY, headerTooltip: string.columnName.VLY,
                    field: 'VLY', tooltipField: 'VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                },
                {
                    headerName: string.columnName.VTG, headerTooltip: string.columnName.VTG,
                    field: "VTG", tooltipField: 'VTG_AB', width: 250, cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                }
            ];

            var rowData = [];
            let that = this;
            routetop5routestable.data.response.forEach(function (key) {
                rowData.push({
                    "Route": key.Routes,
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VLY_AB": window.numberWithCommas(key.VLY),
                    "VTG_AB": window.numberWithCommas(key.VTG)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return route;
    }

    getRouteCards(startDate, endDate, routeGroup, regionId, countryId, routeId, service) {
        const header = this.getDefaultHeader()
        // const url = `${API_URL}/routecards?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${service}`;
        const url = `${API_URL}`;
        var cardData = axios.get(url, header)
            .then((response) => routecards.data.response)
            .catch((error) => {
                console.log(error)
            });
        return cardData;
    }

    // Route Profitability Dashboard
    getSurplusDeficitBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend, routeService) {
        // const url = `${API_URL}/rpGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphName=Surplus&getsvcType=${String.removeQuotes(routeService)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => rpGraphAPISurplus.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getBreakevenLoadFactorAvgBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend, dropdown, routeService) {
        // const url = `${API_URL}/rpGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphName=${dropdown}&getsvcType=${String.removeQuotes(routeService)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => rpGraphAPILoadFactor.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getCaskRaskBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend, routeService) {
        // const url = `${API_URL}/rpGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphName=RCTKM&getsvcType=${String.removeQuotes(routeService)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => rpGraphAPILoadRCTKM.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getForexFuelBarChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend, routeService) {
        // const url = `${API_URL}/rpGraphAPI?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}&graphName=CCTKM&getsvcType=${String.removeQuotes(routeService)}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => rpGraphAPICCTKM.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getRouteProfitabilityCards(startDate, endDate, routeGroup, regionId, countryId, routeId, routeService) {
        const header = this.getDefaultHeader()
        // const url = `${API_URL}/rpcards?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${String.removeQuotes(routeService)}`;
        const url = `${API_URL}`;
        var cardData = axios.get(url, header)
            .then((response) => rpcards.data.response)
            .catch((error) => {
                console.log(error)
            });
        return cardData;
    }

    getAircraftPerformance(startDate, endDate, routeGroup, regionId, countryId, cityId, routeId, typeofCost) {
        let that = this;
        const params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${String.removeQuotes(typeofCost)}`
        // const url = `${API_URL}/rpaircraftperformance?${params}`;
        const url = `${API_URL}`;
        const isCost = typeofCost.includes('C') ? true : false

        var ancillaryData = axios.get(url, this.getDefaultHeader()).then((response) => {

            var columnName = [
                {
                    headerName: string.columnName.Aircraft_Type,
                    headerTooltip: 'Aircraft_Type', field: 'Aircraft_Type', tooltipField: 'Aircraft_Type', width: 300,
                    alignLeft: true
                },
                {
                    headerName: string.columnName.CY, headerTooltip: 'CY', field: 'CY',
                    tooltipField: 'CY_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250,
                    cellRenderer: isCost ? this.costArrowIndicator : this.arrowIndicator,
                    sortable: true, comparator: this.customSorting
                },
                {
                    headerName: 'VBGT', headerTooltip: 'VBGT', field: 'VTG', tooltipField: 'VTG_AB',
                    cellRenderer: isCost ? this.costArrowIndicator : this.arrowIndicator,
                    sortable: true, comparator: this.customSorting
                }

            ];

            var ancillaryDetails = [];
            rpaircraftperformance.data.response[0].TableData.forEach(function (key) {
                ancillaryDetails.push({
                    "Aircraft_Type": key.Aircraft,
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "VTG_AB": window.numberWithCommas(key.VTG),
                    "VLY_AB": window.numberWithCommas(key.VLY)
                });
            });

            var totalData = [];
            rpaircraftperformance.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "Aircraft_Type": 'Total',
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "VTG_AB": window.numberWithCommas(key.VTG),
                    "VLY_AB": window.numberWithCommas(key.VLY)
                });
            });

            return [{ "columnName": columnName, "ancillaryDetails": ancillaryDetails, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return ancillaryData;
    }

    getRouteRegionPerformanceTable(startDate, endDate, routeGroup, regionId, countryId, routeId, typeofCost) {
        // const url = `${API_URL}/rpregionperformance?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${String.removeQuotes(typeofCost)}`;
        const url = `${API_URL}`;
        const isCost = typeofCost.includes('C') ? true : false

        var Regionwiseperformance = axios.get(url, this.getDefaultHeader()).then((response) => {
            var tableHead = rpregionperformance.data.response[0].ColumnName;
            var columnName = [
                { headerName: tableHead, headerTooltip: tableHead, field: 'ColumnName', tooltipField: 'ColumnName', width: 300, alignLeft: true },
                { headerName: string.columnName.CY, headerTooltip: 'CY', field: 'CY', tooltipField: 'CY_AB', sortable: true, comparator: this.customSorting, sort: 'desc' },
                {
                    headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250,
                    cellRenderer: isCost ? this.costArrowIndicator : this.arrowIndicator,
                    sortable: true, comparator: this.customSorting
                },
                {
                    headerName: 'VBGT', headerTooltip: 'VBGT', field: 'VTG', tooltipField: 'VTG_AB',
                    cellRenderer: isCost ? this.costArrowIndicator : this.arrowIndicator,
                    sortable: true, comparator: this.customSorting
                }
            ];

            let that = this;
            var rowData = [];
            rpregionperformance.data.response[0].Data.forEach(function (key) {
                rowData.push({
                    "ColumnName": key.ColumnName,
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "VTG_AB": window.numberWithCommas(key.VTG),
                    "VLY_AB": window.numberWithCommas(key.VLY)
                });
            });

            var totalData = [];
            rpregionperformance.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "ColumnName": 'Total',
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "VTG_AB": window.numberWithCommas(key.VTG),
                    "VLY_AB": window.numberWithCommas(key.VLY)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, 'totalData': totalData, 'tableHead': tableHead }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return Regionwiseperformance;
    }

    getTopTenRouteBudgetTable(startDate, endDate, routeGroup, regionId, countryId, routeId, typeofCost) {
        // const url = `${API_URL}/rptop10routes?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&getsvcType=${String.removeQuotes(typeofCost)}`;
        const url = `${API_URL}`;
        const isCost = typeofCost.includes('C') ? true : false

        var route = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.ROUTE, headerTooltip: string.columnName.ROUTE,
                    field: 'Route', tooltipField: 'Route', width: 300, alignLeft: true
                },
                {
                    headerName: string.columnName.CY, headerTooltip: 'CY', field: 'CY',
                    tooltipField: 'CY_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250,
                    cellRenderer: isCost ? this.costArrowIndicator : this.arrowIndicator,
                    sortable: true, comparator: this.customSorting
                },
                {
                    headerName: 'VBGT', headerTooltip: 'VBGT', field: 'VTG', tooltipField: 'VTG_AB',
                    cellRenderer: isCost ? this.costArrowIndicator : this.arrowIndicator,
                    sortable: true, comparator: this.customSorting
                }
            ];

            var rowData = [];
            let that = this;
            rptop10routes.data.response.forEach(function (key) {
                rowData.push({
                    "Route": key.Route,
                    "CY": that.convertZeroValueToBlank(key.CY),
                    "VTG": that.convertZeroValueToBlank(key.VTG),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "CY_AB": window.numberWithCommas(key.CY),
                    "VTG_AB": window.numberWithCommas(key.VTG),
                    "VLY_AB": window.numberWithCommas(key.VLY)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return route;
    }

    //Route Revenue Planning Dashboard
    getRevenueBudgetChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend) {
        const url = `${API_URL}/routerevenuemultibarchart?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getRaskBudgetChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend) {
        const url = `${API_URL}/routeraskmultibarchart?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getYeildBudgetChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend) {
        const url = `${API_URL}/routeyieldmultibarchart?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getLoadFactorBudgetChart(startDate, endDate, routeGroup, regionId, countryId, routeId, trend) {
        const url = `${API_URL}/routeloadfactormultibarchart?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&trend=${trend}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getRegionBudgetTable(startDate, endDate, routeGroup, regionId, countryId, routeId) {
        const url = `${API_URL}/routeregionwisetable?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`;
        var Regionwiseperformance = axios.get(url, this.getDefaultHeader()).then((response) => {
            var tableHead = response.data.response[0].ColumnName;
            var columnName = [
                { headerName: tableHead, headerTooltip: tableHead, field: 'ColumnName', tooltipField: 'ColumnName', width: 300, alignLeft: true },
                { headerName: string.columnName.BUDGET, headerTooltip: 'Budget', field: 'Budget', tooltipField: 'Budget_AB', sortable: true, comparator: this.customSorting, sort: 'desc' },
                { headerName: string.columnName.LY, headerTooltip: 'LY', field: 'LY', tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting },
                { headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250, cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting }
            ];

            let that = this;
            var rowData = [];
            response.data.response[0].Data.forEach(function (key) {
                rowData.push({
                    "ColumnName": key.ColumnName,
                    "Budget": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VLY": that.convertZeroValueToBlank(key.VTG),
                    "Budget_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VLY_AB": window.numberWithCommas(key.VTG)
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "ColumnName": 'Total',
                    "Budget": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VLY": that.convertZeroValueToBlank(key.VTG),
                    "Budget_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VLY_AB": window.numberWithCommas(key.VTG)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, 'totalData': totalData, 'tableHead': tableHead }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return Regionwiseperformance;
    }

    getRouteBudgetTable(startDate, endDate, routeGroup, regionId, countryId, routeId, toporbottom) {
        const url = `${API_URL}/routetop5routestable?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}&toporbottom=${toporbottom}`;
        var route = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.ROUTE, headerTooltip: string.columnName.ROUTE,
                    field: 'Route', tooltipField: 'Route', width: 300, alignLeft: true
                },
                {
                    headerName: string.columnName.BUDGET, headerTooltip: 'Budget', field: 'Budget',
                    tooltipField: 'Budget_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.LY, headerTooltip: 'LY', field: 'LY',
                    tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting
                },
                {
                    headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250,
                    cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                }
            ];

            var rowData = [];
            let that = this;
            response.data.response.forEach(function (key) {
                rowData.push({
                    "Route": key.Routes,
                    "Budget": that.convertZeroValueToBlank(key.CY),
                    "LY": that.convertZeroValueToBlank(key.LY),
                    "VLY": that.convertZeroValueToBlank(key.VLY),
                    "Budget_AB": window.numberWithCommas(key.CY),
                    "LY_AB": window.numberWithCommas(key.LY),
                    "VLY_AB": window.numberWithCommas(key.VLY),
                });
            });

            return [{ "columnName": columnName, "rowData": rowData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return route;
    }

    getRouteBudgetCards(startDate, endDate, routeGroup, regionId, countryId, routeId) {
        const header = this.getDefaultHeader()
        const url = `${API_URL}/routecards?selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`;
        var cardData = axios.get(url, header)
            .then((response) => response.data.response)
            .catch((error) => {
                console.log(error)
            });
        return cardData;
    }

    //POS Revenue Planning Dashboard
    getODsBudgetChart(startDate, endDate, regionId, countryId, cityId) {
        const url = `${API_URL}/postop5ODsmultibarchart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getAgentsBudgetChart(startDate, endDate, regionId, countryId, cityId) {
        const url = `${API_URL}/postop10agentsmultibarchart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getRegionBudgetChart(startDate, endDate, regionId, countryId, cityId) {
        const url = `${API_URL}/posperformanceview?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getTargetRationaltChart(startDate, endDate, regionId, countryId, cityId) {
        const url = `${API_URL}/possegmentationbarchart?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getSalesAnalysisBudgetChart(startDate, endDate, regionId, countryId, cityId, trend, graphType, selectedGraph) {
        const url = `${API_URL}/possalesanalysis?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}&trend=${trend}&graphType=${graphType}&graphCategory=${selectedGraph}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    getChannelBudgetTable(startDate, endDate, regionId, countryId, cityId) {
        const url = `${API_URL}/poschannelperformanceview?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        var channelBudget = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                { headerName: string.columnName.CHANNEL, headerTooltip: 'Channel', field: 'Channel', tooltipField: 'Channel', width: 300, alignLeft: true },
                { headerName: string.columnName.BUDGET, headerTooltip: 'Budget', field: 'Budget', tooltipField: 'Budget_AB', sortable: true, comparator: this.customSorting, sort: 'desc' },
                { headerName: string.columnName.LY, headerTooltip: 'LY', field: 'LY', tooltipField: 'LY_AB', sortable: true, comparator: this.customSorting },
                { headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY", tooltipField: 'VLY_AB', width: 250, cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting }
            ];

            let that = this;
            var rowData = [];
            response.data.response[0].TableData.forEach(function (key) {
                rowData.push({
                    "Channel": key.ChannelName,
                    "Budget": that.convertZeroValueToBlank(key.Revenue_CY),
                    "LY": that.convertZeroValueToBlank(key.Revenue_LY),
                    "VLY": that.convertZeroValueToBlank(key.VTG),
                    "Budget_AB": window.numberWithCommas(key.Revenue_CY),
                    "LY_AB": window.numberWithCommas(key.Revenue_LY),
                    "VLY_AB": window.numberWithCommas(key.VTG)
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    "Channel": 'Total',
                    "Budget": that.convertZeroValueToBlank(key.Revenue_CY),
                    "LY": that.convertZeroValueToBlank(key.Revenue_LY),
                    "VLY": that.convertZeroValueToBlank(key.VTG),
                    "Budget_AB": window.numberWithCommas(key.Revenue_CY),
                    "LY_AB": window.numberWithCommas(key.Revenue_LY),
                    "VLY_AB": window.numberWithCommas(key.VTG)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return channelBudget;
    }

    getODBudgetTable(startDate, endDate, regionId, countryId, cityId) {
        const url = `${API_URL}/posmarketsharetable?${DashboardParams(startDate, endDate, regionId, countryId, cityId)}`;
        var ODBudgetDatas = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.OD, headerTooltip: 'OD', field: 'OD', tooltipField: 'OD', width: 300, alignLeft: true
                },
                {
                    headerName: 'BGT', headerTooltip: 'BGT', field: 'BGT', tooltipField: 'BGT_AB',
                    sortable: true, comparator: this.customSorting, sort: 'desc'
                },
                {
                    headerName: string.columnName.VLY, headerTooltip: 'VLY(%)', field: "VLY_CA", tooltipField: 'VLY_CA_AB',
                    cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                },
                {
                    headerName: 'CALMS', headerTooltip: 'CALMS', field: 'CALMS', tooltipField: 'CALMS_AB',
                    sortable: true, comparator: this.customSorting
                },
            ];

            var rowData = [];
            let that = this;
            response.data.response.forEach(function (key) {
                rowData.push({
                    "OD": key.OD,
                    "BGT": that.convertZeroValueToBlank(key.CMSV_CY),
                    "CALMS": that.convertZeroValueToBlank(key.CALMS_CY),
                    "VLY_CA": that.convertZeroValueToBlank(key.CALMS_VLY),
                    "BGT_AB": window.numberWithCommas(key.CMSV_CY),
                    "CALMS_AB": window.numberWithCommas(key.CALMS_CY),
                    "VLY_CA_AB": window.numberWithCommas(key.CALMS_VLY)
                });
            });

            return [{ "columnName": columnName, "rowData": rowData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return ODBudgetDatas;
    }

    getPRPIndicatorsData(startDate, endDate, routeGroup, regionId, countryId, cityId, routeId, dashboard) {
        const header = this.getDefaultHeader()
        let link = '';
        let params = null;
        if (dashboard === 'Pos') {
            link = 'posytd'
            params = DashboardParams(startDate, endDate, regionId, countryId, cityId)
        } else if (dashboard === 'Route') {
            link = 'routeytd'
            params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        } else if (dashboard === 'Route Revenue Planning') {
            link = 'routeytd'
            params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        } else if (dashboard === 'Route Profitability') {
            link = 'routeytd'
            params = `selectedRouteGroup=${routeGroup}&${RoouteDashboardParams(startDate, endDate, regionId, countryId, routeId)}`
        }
        // const url = `${API_URL}/${link}?${params}`;

        if(link == 'posytd'){   
            const url = `${API_URL}`;
            var indicatorsData = axios.get(url, header)
            .then((response) => posytd.data.response)
            .catch((error) => {
                console.log(error)
            });
        }else if(link == 'routeytd'){
            const url = `${API_URL}`;
            var indicatorsData = axios.get(url, header)
            .then((response) => routeytd.data.response)
            .catch((error) => {
                console.log(error)
            });
        }
        return indicatorsData;
    }

    //Agent Dashboard API
    getIncrementalRO(gettingYear, gettingMonth, regionId, countryId, cityId, currency) {
        const url = `${API_URL}/revenueopportunity?getYear=${gettingYear}&gettingMonth=${gettingMonth}&${FilterParams(regionId, countryId, cityId)}&currencyType=${currency}`;
        var ROData = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{
                        headerName: string.columnName.CHANNEL, field: 'Channel', alignLeft: true
                    }],
                },
                {
                    headerName: '',
                    children: [{
                        headerName: string.columnName.RO, field: 'RO', tooltipField: 'RO_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                    }],
                },
                {
                    headerName: string.columnName.SALES,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_Sales', tooltipField: 'CY_Sales_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_Sales', tooltipField: 'YOY_Sales_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.BOOKINGS,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_B', tooltipField: 'CY_B_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_B', tooltipField: 'YOY_B_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'CY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_MS', tooltipField: 'YOY_MS_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }]
                },
            ]

            var rowData = [];
            let that = this;
            response.data.response[0].TableData.forEach(function (key) {
                rowData.push({
                    'Channel': key.ChannelName,
                    'RO': currency === 'BC' ? that.convertZeroValueToBlank(key.RO_BaseCurrency) : that.convertZeroValueToBlank(key.RO_LocalCurrency),
                    'CY_Sales': currency === 'BC' ? that.convertZeroValueToBlank(key.CY_Revenue_BaseCurrency) : that.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency),
                    'YOY_Sales': currency === 'BC' ? that.convertZeroValueToBlank(key.VLY_Revenue_BaseCurrency) : that.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency),
                    'CY_B': that.convertZeroValueToBlank(key.Bookings_CY),
                    'LY_B': that.convertZeroValueToBlank(key.Bookings_LY),
                    'YOY_B': that.convertZeroValueToBlank(key.Bookings_YOY),
                    'CY_MS': that.convertZeroValueToBlank(key.Share_CY),
                    'YOY_MS': that.convertZeroValueToBlank(key.Share_YOY),
                    'RO_AB': currency === 'BC' ? window.numberWithCommas(key.RO_BaseCurrency) : window.numberWithCommas(key.RO_LocalCurrency),
                    'CY_Sales_AB': currency === 'BC' ? window.numberWithCommas(key.CY_Revenue_BaseCurrency) : window.numberWithCommas(key.CY_Revenue_LocalCurrency),
                    'YOY_Sales_AB': currency === 'BC' ? window.numberWithCommas(key.VLY_Revenue_BaseCurrency) : window.numberWithCommas(key.VLY_Revenue_LocalCurrency),
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'LY_B_AB': window.numberWithCommas(key.Bookings_LY),
                    'YOY_B_AB': window.numberWithCommas(key.Bookings_YOY),
                    'CY_MS_AB': window.numberWithCommas(key.Share_CY),
                    'YOY_MS_AB': window.numberWithCommas(key.Share_YOY),
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach(function (key) {
                totalData.push({
                    'Channel': 'Total',
                    'RO': currency === 'BC' ? that.convertZeroValueToBlank(key.RO_BaseCurrency) : that.convertZeroValueToBlank(key.RO_LocalCurrency),
                    'CY_Sales': currency === 'BC' ? that.convertZeroValueToBlank(key.CY_Revenue_BaseCurrency) : that.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency),
                    'YOY_Sales': currency === 'BC' ? that.convertZeroValueToBlank(key.VLY_Revenue_BaseCurrency) : that.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency),
                    'CY_B': that.convertZeroValueToBlank(key.Bookings_CY),
                    'LY_B': that.convertZeroValueToBlank(key.Bookings_LY),
                    'YOY_B': that.convertZeroValueToBlank(key.Bookings_YOY),
                    'CY_MS': that.convertZeroValueToBlank(key.Share_CY),
                    'YOY_MS': that.convertZeroValueToBlank(key.Share_YOY),
                    'RO_AB': currency === 'BC' ? window.numberWithCommas(key.RO_BaseCurrency) : window.numberWithCommas(key.RO_LocalCurrency),
                    'CY_Sales_AB': currency === 'BC' ? window.numberWithCommas(key.CY_Revenue_BaseCurrency) : window.numberWithCommas(key.CY_Revenue_LocalCurrency),
                    'YOY_Sales_AB': currency === 'BC' ? window.numberWithCommas(key.VLY_Revenue_BaseCurrency) : window.numberWithCommas(key.VLY_Revenue_LocalCurrency),
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'LY_B_AB': window.numberWithCommas(key.Bookings_LY),
                    'YOY_B_AB': window.numberWithCommas(key.Bookings_YOY),
                    'CY_MS_AB': window.numberWithCommas(key.Share_CY),
                    'YOY_MS_AB': window.numberWithCommas(key.Share_YOY),
                });
            });

            return [{ "columnName": columnName, "rowData": rowData, 'totalData': totalData }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return ROData;
    }

    getTopAgentWithOD(gettingYear, gettingMonth, regionId, countryId, cityId, currency) {
        const url = `${API_URL}/topagentandtopOD?${FilterParams(regionId, countryId, cityId)}&getYear=${gettingYear}&gettingMonth=${gettingMonth}&currencyType=${currency}`;
        var TopAgentWithOD = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{
                        headerName: string.columnName.OD, field: 'OD', alignLeft: true
                    }],
                },
                {
                    headerName: '',
                    children: [{
                        headerName: string.columnName.RO, field: 'RO', tooltipField: 'RO_AB', sortable: true, comparator: this.customSorting, sort: 'desc'
                    }],
                },
                {
                    headerName: string.columnName.SALES,
                    children: [
                        {
                            headerName: string.columnName.REVENUE, field: 'Revenue', tooltipField: 'Revenue_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_Rev', tooltipField: 'YOY_Rev_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.PASSENGER, field: 'Pax', tooltipField: 'Pax_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_Pax', tooltipField: 'YOY_Pax_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: string.columnName.AVERAGE_FARE_$, field: 'AvgFare', tooltipField: 'AvgFare_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_Avg', tooltipField: 'YOY_Avg_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }],
                },
                {
                    headerName: string.columnName.BOOKINGS,
                    children: [
                        {
                            headerName: string.columnName.FIT, field: 'FIT', tooltipField: 'FIT_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_FIT', tooltipField: 'YOY_FIT_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.GIT, field: 'GIT', tooltipField: 'GIT_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_GIT', tooltipField: 'YOY_GIT_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.SHARE, field: 'Share', tooltipField: 'Share_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_Share', tooltipField: 'YOY_Share_AB',
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },
                        // { headerName: string.columnName.FARE_SHARE, field: 'Fare_Share' },
                        // { headerName: string.columnName.TOP_AL_SHARE, field: 'Top_AL_Share' },
                    ]
                },
            ]

            let data = response.data.response;
            console.log('rahul', data)
            data.forEach((data) => {
                data.Data.forEach((key) => {
                    return (
                        key['OD'] = key.OD,
                        key['RO'] = currency === 'BC' ? this.convertZeroValueToBlank(key.RO_BaseCurrency) : this.convertZeroValueToBlank(key.RO_LocalCurrency),
                        key['Revenue'] = currency === 'BC' ? this.convertZeroValueToBlank(key.CY_Revenue_BaseCurrency) : this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency),
                        key['YOY_Rev'] = currency === 'BC' ? this.convertZeroValueToBlank(key.VLY_Revenue_BaseCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency),
                        key['Pax'] = this.convertZeroValueToBlank(key.TotalPassenger_CY),
                        key['YOY_Pax'] = this.convertZeroValueToBlank(key.YOY_Pax),
                        key['AvgFare'] = currency === 'BC' ? this.convertZeroValueToBlank(key.BaseCurrency_AvgFare_CY) : this.convertZeroValueToBlank(key.LocalCurrency_AvgFare_CY),
                        key['YOY_Avg'] = currency === 'BC' ? this.convertZeroValueToBlank(key.VLY_BaseCurrency_Avg) : this.convertZeroValueToBlank(key.VLY_LocalCurrency_Avg),
                        key['FIT'] = this.convertZeroValueToBlank(key.FIT),
                        key['YOY_FIT'] = this.convertZeroValueToBlank(key.YOY_FIT),
                        key['GIT'] = this.convertZeroValueToBlank(key.GIT),
                        key['YOY_GIT'] = this.convertZeroValueToBlank(key.YOY_GIT),
                        key['Share'] = this.convertZeroValueToBlank(key.Share),
                        key['YOY_Share'] = this.convertZeroValueToBlank(key.YOY_Share),
                        key['RO_AB'] = currency === 'BC' ? window.numberWithCommas(key.RO_BaseCurrency) : window.numberWithCommas(key.RO_LocalCurrency),
                        key['Revenue_AB'] = currency === 'BC' ? window.numberWithCommas(key.CY_Revenue_BaseCurrency) : window.numberWithCommas(key.CY_Revenue_LocalCurrency),
                        key['YOY_Rev_AB'] = currency === 'BC' ? window.numberWithCommas(key.VLY_Revenue_BaseCurrency) : window.numberWithCommas(key.VLY_Revenue_LocalCurrency),
                        key['Pax_AB'] = window.numberWithCommas(key.TotalPassenger_CY),
                        key['YOY_Pax_AB'] = window.numberWithCommas(key.YOY_Pax),
                        key['AvgFare_AB'] = currency === 'BC' ? window.numberWithCommas(key.BaseCurrency_AvgFare_CY) : window.numberWithCommas(key.LocalCurrency_AvgFare_CY),
                        key['YOY_Avg_AB'] = currency === 'BC' ? window.numberWithCommas(key.VLY_BaseCurrency_Avg) : window.numberWithCommas(key.VLY_LocalCurrency_Avg),
                        key['FIT_AB'] = window.numberWithCommas(key.FIT),
                        key['YOY_FIT_AB'] = window.numberWithCommas(key.YOY_FIT),
                        key['GIT_AB'] = window.numberWithCommas(key.GIT),
                        key['YOY_GIT_AB'] = window.numberWithCommas(key.YOY_GIT),
                        key['Share_AB'] = window.numberWithCommas(key.Share),
                        key['YOY_Share_AB'] = window.numberWithCommas(key.YOY_Share)
                        // key['Fare_Share'] = '87',
                        // key['Top_AL_Share'] = 'BA/60%'
                    )
                })
            })
            console.log('rahul', data)
            return [{ "columnName": columnName, "rowData": data }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log('rahul error', error)
            });

        return TopAgentWithOD;
    }

    //Others
    getRealTimeRevenueMultilineChart(getXaxis) {
        const url = `${API_URL}/realtimerevenueMultiline?Xaxis=${getXaxis}`;

        var posregiontable = axios.get(url, this.getDefaultHeader())
            .then(response => response.data)
            .catch((error) => {
                console.log(error)
            });

        return posregiontable;
    }

    getInFareMultilineChart(regionId, countryId, cityId, time) {
        const url = `${API_URL}/infaremultiline?regionId=${regionId}&countryId=${countryId}&cityId=${cityId}&time=${time}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data)
            .catch((error) => {
                // this.errorHandling(error);
            });
    }

    getRegionWisePerformance(regionId, countryId, cityId, commonOD) {
        const url = `${API_URL}/performanceview?regionId=${regionId}&countryId=${countryId}&cityId=${cityId}&commonOD=${commonOD}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data)
            .catch((error) => {
                console.log(error)
            });
    }

    gettop5ODsBarChart(gettingMonth, regionId, countryId, cityId, commonOD) {
        const url = `${API_URL}/top5ODsMultiBarchart?gettingMonth=${gettingMonth}&regionId=${regionId}&countryId=${countryId}&cityId=${cityId}&commonOD=${commonOD}`;

        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data)
            .catch((error) => {
                console.log(error)
            });

    }

    getChannelWisePerformPivot(regionId, countryId, cityId) {
        const url = `${API_URL}/channelperformanceview?regionId=${regionId}&countryId=${countryId}&cityId=${cityId}`;
        var channelwiseperform = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                { headerName: string.columnName.CHANNEL, field: 'Channel', width: 300 },
                { headerName: string.columnName.CY, field: 'CY' },
                { headerName: string.columnName.LY, field: 'LY' },
                { headerName: string.columnName.VLY, field: "VLY", cellRenderer: (params) => this.arrowIndicator(params), width: 250 }
            ];

            var channelwiseperformDatas = [];
            let that = this;
            response.data.forEach(function (key) {
                channelwiseperformDatas.push({
                    "Channel": key.ChannelName,
                    "CY": that.convertZeroValueToBlank(key.Revenue_CY),
                    "LY": that.convertZeroValueToBlank(key.Revenue_LY),
                    "VLY": that.convertZeroValueToBlank(key.Revenue_VLY)
                });
            });

            return [{ "columnName": columnName, "channelwiseperformtableDatas": channelwiseperformDatas }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return channelwiseperform;
    }

    //POS Page API
    getPOSMonthTables(currency, regionId, countryId, cityId, commonOD, getCabinValue) {

        // const url = `${API_URL}/posDataMonthly?${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${String.addQuotesforMultiSelect(commonOD)}`;
        const url = `${API_URL}`;
        var posmonthtable = axios.get(url, this.getDefaultHeader()).then((data) => {


            let response = '';

            if(regionId=='*'){
                response = posDataMonthly
            }else if(countryId=='*'){
                response = posDataCountryDrillDown
            }else if(cityId == '*'){
                response = posDataCity;
            }else if(commonOD == '*'){
                response = posDataCommonOD
            }else{
                response = posDataMonthly
            }

            let avgfarezeroTGT = response.TableData.filter((d) => d.AvgFare_TGT === 0 || d.AvgFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.TableData.length

            let revenuzeroTGT = response.TableData.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.TableData.length

            let passengerzeroTGT = response.TableData.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.TableData.length

            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Month', width: 250, alignLeft: true, underline: true }]
                },
                {
                    headerName: string.columnName.GROSS_WEIGHT,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_G', tooltipField: 'CY_G_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_G', tooltipField: 'TGT_G_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_G', tooltipField: 'VTG_G_AB' },
                        { headerName: string.columnName.VLY, field: 'VLY_G', tooltipField: 'VLY_G_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        { headerName: string.columnName.FORECAST_ACT, field: 'FRCT/Act_C', tooltipField: 'FRCT/Act_C_AB', width: 250 },
                        { headerName: string.columnName.TGT, field: 'TGT_C', tooltipField: 'TGT_C_AB', hide: passengerTGTVisible },
                        { headerName: string.columnName.VTG, field: 'VTG_C', tooltipField: 'VTG_C_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: passengerTGTVisible },
                        { headerName: string.columnName.VLY, field: 'VLY_C', tooltipField: 'VLY_C_AB', cellRenderer: (params) => this.arrowIndicator(params) }]
                },
                {
                    headerName: string.columnName.REVENUE,
                    children: [
                        { headerName: string.columnName.FORECAST_ACT, field: 'FRCT/Act_R', tooltipField: 'FRCT/Act_R_AB', width: 250 },
                        { headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: 'TGT_R_AB', hide: avgfareTGTVisible },
                        { headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: avgfareTGTVisible },
                        { headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_KG,
                    children: [
                        { headerName: string.columnName.FORECAST_ACT, field: 'FRCT/Act_A', tooltipField: 'FRCT/Act_A_AB', width: 250 },
                        { headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: 'TGT_A_AB', hide: revenueTGTVisible },
                        { headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: revenueTGTVisible },
                        { headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_AL', tooltipField: 'CY_AL_AB', width: 250, underline: true },
                        { headerName: string.columnName.VLY, field: 'VLY_AL', tooltipField: 'VLY_AL_AB', cellRenderer: (params) => this.arrowIndicator(params) }]
                }
            ];

            let previosYearTableData = response.TableData.filter((d) => d.Year === currentYear - 1)
            let currentYearTableDta = response.TableData.filter((d) => d.Year === currentYear)
            let nextYearTableData = response.TableData.filter((d) => d.Year === currentYear + 1)

            var responseData = [...response.Total_LY, ...previosYearTableData, ...currentYearTableDta, ...response.Total_NY, ...nextYearTableData]

            var rowData = [];

            responseData.forEach((key) => {
                rowData.push({
                    'Month': key.MonthName === null ? '---' : key.MonthName,
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    'TKT_C': `${this.convertZeroValueToBlank(key.TKT_ChargableWeight)}${this.showPercent(key.TKT_ChargableWeight)}`,
                    'FRCT/Act_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'FRCT/Act_R': currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    'TGT_R': currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    'VTG_R': currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    'FRCT/Act_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    "CY_AL": this.convertZeroValueToBlank(key.CY_AL),
                    "VLY_AL": this.convertZeroValueToBlank(key.VLY_AL),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),
                    'TKT_G_AB': window.numberWithCommas(key.TKT_GrossWeight),
                    'FRCT/Act_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    'FRCT/Act_R_AB': currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    'TGT_R_AB': currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    'VTG_R_AB': currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    'FRCT/Act_A_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    "CY_AL_AB": window.numberWithCommas(key.CY_AL),
                    "VLY_AL_AB": window.numberWithCommas(key.VLY_AL),
                    'Year': key.Year,
                    'MonthName': key.monthfullname
                });
            });

            var totalData = [];
            response.Total_CY.forEach((key) => {
                totalData.push({
                    'Month': 'Total',
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    'TKT_G': `${this.convertZeroValueToBlank(key.TKT_GrossWeight)}${this.showPercent(key.TKT_GrossWeight)}`,
                    'FRCT/Act_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'FRCT/Act_R': currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    'TGT_R': currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    'VTG_R': currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    'FRCT/Act_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    "CY_AL": this.convertZeroValueToBlank(key.CY_AL),
                    "VLY_AL": this.convertZeroValueToBlank(key.VLY_AL),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),
                    'TKT_G_AB': window.numberWithCommas(key.TKT_GrossWeight),
                    'FRCT/Act_C_AB': window.numberWithCommas(key.CY__ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    'FRCT/Act_R_AB': currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    'TGT_R_AB': currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    'VTG_R_AB': currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    'FRCT/Act_A_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    "CY_AL_AB": window.numberWithCommas(key.CY_AL),
                    "VLY_AL_AB": window.numberWithCommas(key.VLY_AL),
                });
            });

            console.log(columnName, rowData, totalData, 'check')

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData,
                "currentAccess": response.CurretAccess,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return posmonthtable;
    }

    getPOSDrillDownData(getYear, currency, gettingMonth, regionId, countryId, cityId, commonOD, getCabinValue, type) {

        // const url = `${API_URL}/posDataDrillDown?getYear=${getYear}&gettingMonth=${gettingMonth}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${String.addQuotesforMultiSelect(commonOD)}&type=${type}`;
        const url = `${API_URL}`;
        var posregiontable = axios.get(url, this.getDefaultHeader()).then((data) => {
            let response = ''
            if(regionId=='*' && type=='Null'){
                response = posRegionDrillDown
            }else if(countryId=='*' && type=='Null'){
                response = posCountryDrillDown
            }else if(cityId=='*' && type=='Null'){
                response = posCityDrillDown
            }else if(commonOD=='*' && type=='Null'){
                response = posCommonODDrillDown
            }else if(type!='Null'){
                if(type=='OD'){
                    response = posCommonODDrillDown
                }else if(type=='Product'){
                    response = posProductDrillDown
                }else if(type=='Agency'){
                    response = posAgentsDrillDown
                }
            }
            const firstColumnName = response.ColumnName;

            let avgfarezeroTGT = response.TableData.filter((d) => d.AvgFare_TGT === 0 || d.AvgFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.TableData.length

            let revenuzeroTGT = response.TableData.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.TableData.length

            let passengerzeroTGT = response.TableData.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.TableData.length

            var columnName = [
                {
                    headerName: '',
                    children: [{
                        headerName: firstColumnName,
                        field: 'firstColumnName',
                        tooltipField: 'Product_AB',
                        width: 250, alignLeft: true, underline: (type === 'Null') && firstColumnName !== 'Product' && firstColumnName !== 'O&D' && type !== 'O&D' ? true : false
                    }]
                },
                // {
                //     headerName: '',
                //     children: [{ headerName: '', field: '', cellRenderer: (params) => this.alerts(params), width: 150 }]
                // },
                {
                    headerName: string.columnName.GROSS_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_G', tooltipField: 'CY_G_AB', hide: firstColumnName === 'Ancillary',
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_G', tooltipField: 'TGT_G_AB', hide: passengerTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_G', tooltipField: 'VTG_G_AB', hide: firstColumnName === 'Ancillary',
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_G', tooltipField: 'VLY_G_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: firstColumnName === 'Ancillary',
                            sortable: true, comparator: this.customSorting,
                        }

                    ]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.FORECAST_ACT, field: 'FRCT/Act_C', tooltipField: 'FRCT/Act_C_AB', width: 250,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_C', tooltipField: 'TGT_C_AB', hide: passengerTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_C', tooltipField: 'VTG_C_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: passengerTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_C', tooltipField: 'VLY_C_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting,
                        }]
                },
                {
                    headerName: string.columnName.REVENUE,
                    children: [
                        {
                            headerName: string.columnName.FORECAST_ACT, field: 'FRCT/Act_R', tooltipField: 'FRCT/Act_R_AB', width: 250,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: 'TGT_R_AB', hide: avgfareTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: avgfareTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting,
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_KG,
                    children: [
                        {
                            headerName: string.columnName.FORECAST_ACT, field: 'FRCT/Act_A', tooltipField: 'FRCT/Act_A_AB', width: 250,
                            sortable: true, comparator: this.customSorting, sort: 'desc', underline: true
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: 'TGT_A_AB', hide: revenueTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: revenueTGTVisible,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting,
                        }
                    ]
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: string.columnName.AVAIL, field: 'Avail', tooltipField: 'Avail_AB', hide: firstColumnName === 'Ancillary',
                            sortable: true, comparator: this.customSorting,
                        }]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_AL', tooltipField: 'CY_AL_AB', width: 250, hide: firstColumnName === 'Ancillary', underline: type === 'Null' || type === 'OD' ? true : false,
                            sortable: true, comparator: this.customSorting,
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_AL', tooltipField: 'VLY_AL_AB', cellRenderer: (params) => this.arrowIndicator(params), hide: firstColumnName === 'Ancillary',
                            sortable: true, comparator: this.customSorting,
                        }]
                }
            ];

            var rowData = [];
            response.TableData.forEach((key) => {
                rowData.push({
                    'firstColumnName': key.ColumnName === null ? '---' : key.ColumnName,
                    '': '',
                    'Product_AB':key.ProductToolTip,
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    'TKT_G': `${this.convertZeroValueToBlank(key.TKT_GrossWeight)}${this.showPercent(key.TKT_GrossWeight)}`,
                    'FRCT/Act_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'FRCT/Act_R': currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    'TGT_R': currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    'VTG_R': currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    'FRCT/Act_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'Avail': this.convertZeroValueToBlank(key.Avail),
                    "CY_AL": this.convertZeroValueToBlank(key.CY_AL),
                    "VLY_AL": this.convertZeroValueToBlank(key.VLY_AL),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.Bookings_VLY),
                    'TKT_G_AB': window.numberWithCommas(key.Bookings_TKT),
                    'FRCT/Act_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    'FRCT/Act_R_AB': currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    'TGT_R_AB': currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    'VTG_R_AB': currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    'FRCT/Act_A_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    'Avail_AB': window.numberWithCommas(key.Avail),
                    "CY_AL_AB": window.numberWithCommas(key.CY_AL),
                    "VLY_AL_AB": window.numberWithCommas(key.VLY_AL),
                    'isAlert': key.is_alert
                });
            });

            var totalData = [];
            response.Total.forEach((key) => {

                totalData.push({
                    'Ancillary_Full_Name': 'Total',
                    'firstColumnName': 'Total',
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    'TKT_G': `${this.convertZeroValueToBlank(key.TKT_GrossWeight)}${this.showPercent(key.TKT_GrossWeight)}`,
                    'FRCT/Act_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'FRCT/Act_R': currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    'TGT_R': currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    'VTG_R': currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    'FRCT/Act_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'Avail': this.convertZeroValueToBlank(key.Avail),
                    "CY_AL": this.convertZeroValueToBlank(key.CY_AL),
                    "VLY_AL": this.convertZeroValueToBlank(key.VLY_AL),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.Bookings_VLY),
                    'TKT_G_AB': window.numberWithCommas(key.Bookings_TKT),
                    'FRCT/Act_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    'FRCT/Act_R_AB': currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    'TGT_R_AB': currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    'VTG_R_AB': currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    'FRCT/Act_A_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    'Avail_AB': window.numberWithCommas(key.Avail),
                    "CY_AL_AB": window.numberWithCommas(key.CY_AL),
                    "VLY_AL_AB": window.numberWithCommas(key.VLY_AL),
                    'isAlert': key.is_alert
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                "currentAccess": response.CurrentAccess,
                'totalData': totalData,
                'tabName': response.ColumnName,
                'firstTabName': response.first_ColumnName,

            }];
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return posregiontable;
    }

    getPOSCabinDetails(getYear, gettingMonth, regionId, countryId, cityId, commonOD, getCabinValue) {

        const url = `${API_URL}/poscabinWiseDetails?getYear=${getYear}&gettingMonth=${gettingMonth}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}`;

        var cabinTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.RBD, field: 'RBD', tooltipField: 'RBD_AB', alignLeft: true
                },
                {
                    headerName: 'Bookings', field: 'Booking', tooltipField: 'Booking_AB',
                },
                {
                    headerName: 'VLY(%)', field: 'VLY(%)', tooltipField: 'VLY(%)_AB', cellRenderer: (params) => this.arrowIndicator(params)
                },
                {
                    headerName: 'Ticketed Average Fare(SR)', field: 'Ticketed Average Fare(SR)', tooltipField: 'Ticketed Average Fare(SR)_AB'
                },
                {
                    headerName: 'VLY(%)TKT', field: 'VLY(%)TKT', tooltipField: 'VLY(%)TKT_AB', cellRenderer: (params) => this.arrowIndicator(params)
                }
            ];

            var F = response.data.Data.filter((d) => d.Cabin === 'F')
            var J = response.data.Data.filter((d) => d.Cabin === 'J')
            var Y = response.data.Data.filter((d) => d.Cabin === 'Y')

            var Total_F = response.data.Total.filter((d) => d.RBD === 'Total of F')
            var Total_J = response.data.Total.filter((d) => d.RBD === 'Total of J')
            var Total_Y = response.data.Total.filter((d) => d.RBD === 'Total of Y')

            var mergedCabinData = [...Total_F, ...F, ...Total_J, ...J, ...Total_Y, ...Y]
            var cabinData = [];

            mergedCabinData.forEach((key) => {
                cabinData.push({
                    'Cabin': key.Cabin,
                    'RBD': key.RBD,
                    'Booking': this.convertZeroValueToBlank(key.Bookings_CY),
                    'VLY(%)': this.convertZeroValueToBlank(key.Bookings_VLY),
                    'Ticketed Average Fare(SR)': this.convertZeroValueToBlank(key.TicketedAverage_CY),
                    'VLY(%)TKT': this.convertZeroValueToBlank(key.TicketedAverage_VLY),
                    'Booking_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY(%)_AB': window.numberWithCommas(key.Bookings_VLY),
                    'Ticketed Average Fare(SR)_AB': window.numberWithCommas(key.TicketedAverage_CY),
                    'VLY(%)TKT_AB': window.numberWithCommas(key.TicketedAverage_VLY),
                });
            });

            return [{
                "columnName": columnName,
                "cabinData": cabinData,
            }];
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return cabinTable;
    }

    getAvailabilityDetails(getYear, gettingMonth, regionId, countryId, cityId, commonOD, getCabinValue, type, typeParameter) {
        let that = this;
        const url = `${API_URL}/seatavailability?getYear=${getYear}&gettingMonth=${gettingMonth}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}&type=${type}&typeParameter=${typeParameter}`;
        var availabilityData = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.RBD, field: 'RBD', tooltipField: 'RBD_AB', alignLeft: true
                },
                {
                    headerName: 'Availability', field: 'Availability', tooltipField: 'Availability_AB', alignLeft: true
                },
            ]

            var F = response.data.Data.filter((d) => d.Cabin === 'F')
            var J = response.data.Data.filter((d) => d.Cabin === 'J')
            var Y = response.data.Data.filter((d) => d.Cabin === 'Y')

            var Total_F = response.data.Total.filter((d) => d.RBD === 'Total of F')
            var Total_J = response.data.Total.filter((d) => d.RBD === 'Total of J')
            var Total_Y = response.data.Total.filter((d) => d.RBD === 'Total of Y')

            var mergedCabinData = [...Total_F, ...F, ...Total_J, ...J, ...Total_Y, ...Y]
            const rowData = [];
            mergedCabinData.forEach(function (key) {
                rowData.push({
                    'Cabin': key.Cabin,
                    "RBD": key.RBD,
                    "Availability": that.convertZeroValueToBlank(key.Availability),
                    "Availability_AB": window.numberWithCommas(key.Availability),
                })
            })

            return [{ "columnName": columnName, "rowData": rowData }];
        })
            .catch((error) => {
                that.errorHandling(error)
            });
        return availabilityData;
    }

    getPOSLineCharts(displayName, region, country, city, od, getCabin) {
        let link = '';

        if (displayName === string.columnName.BOOKINGS) {
            link = 'posbooking'
        }
        if (displayName === 'Passenger-(O&D)') {
            link = 'pospassenger'
        }
        if (displayName === string.columnName.AVERAGE_FARE_$) {
            link = 'posavgfare'
        }
        if (displayName === string.columnName.REVENUE_$) {
            link = 'posrevenue'
        }
        // const url = `${API_URL}/${link}?regionId=${region}&countryId=${country}&cityId=${city}&commonOD=${od}&getCabinValue=${getCabin}`;
        const url = `${API_URL}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    exportCSVPOSMonthlyURL(regionId, countryId, cityId, commonOD, getCabinValue) {
        const url = `posDataMonthly?${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}`;
        return url;
    }

    exportCSVPOSDrillDownURL(getYear, gettingMonth, regionId, countryId, cityId, commonOD, getCabinValue, type) {
        const url = `posDataDrillDown?getYear=${getYear}&gettingMonth=${gettingMonth}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}&type=${type}`;
        return url;
    }

    // Cabin List
    getClassNameDetails() {
        // const url = `${API_URL}/getClassDetails`;
        const url = `${API_URL}`;
        var classNameDetails = axios.get(url).then(function (response) {
            var posClasstableDatas = [];
            getClassDetails.forEach(function (key) {

                posClasstableDatas.push({
                    "ClassText": key.Product,
                    "ClassValue": key.Product,
                    "Product_AB":key.ProductToolTip
                });

            });

            return [{ "classDatas": posClasstableDatas }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });
        return classNameDetails;
    }


    // AlertsNew Month Data
    getAlertsMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, getCabinValue) {

        // const url = `${API_URL}/routeDataMonthly?selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}`;
        const url = `${API_URL}`;
        var routemonthtable = axios.get(url, this.getDefaultHeader()).then((data) => {


            let response = '';

            if(regionId=='*'){
                response = routeDataMonthly
            }else if(countryId=='*'){
                response = routeDataCountryDrillDown
            }else if(routeId == '*'){
                response = routeDataRouteDrillDown;
            }else if(leg == '*'){
                response = routeDataLegDrillDown
            }else if(flight == "*"){
                response = routeDataFlightDrillDown
            }else if(aircraftType == "*"){
                response = routeDataAircraftDrillDown
            }else{
                response = routeDataAircraftDrillDown
            }
            /*let avgfarezeroTGT = response.data.TableData.filter((d) => d.AvgFare_TGT === 0 || d.AvgFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.data.TableData.length

            let revenuzeroTGT = response.data.TableData.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.TableData.length

            let passengerzeroTGT = response.data.TableData.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.data.TableData.length*/

            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Month', width: 250, alignLeft: true, underline: true }]
                },
                {
                    headerName: string.columnName.REVENUE, headerTooltip: string.columnName.REVENUE,
                    // headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: 'TGT_R_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.GROSS_WEIGHT, headerTooltip: string.columnName.GROSS_WEIGHT,
                    //   headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_G', tooltipField: 'CY_G_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_G', tooltipField: 'TGT_G_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_G', tooltipField: 'VTG_G_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_G', tooltipField: 'VLY_G_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        //{ headerName: string.columnName.TKT, field: 'TKT_B', tooltipField: 'TKT_B_AB', }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE, headerTooltip: string.columnName.AVERAGE_FARE,
                    //  headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_A', tooltipField: 'CY_A_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: 'TGT_A_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.LOAD_FACTOR, headerTooltip: string.columnName.LOAD_FACTOR,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_L', tooltipField: 'CY_L_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_L', tooltipField: 'TGT_L_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_L', tooltipField: 'VTG_L_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_L', tooltipField: 'VLY_L_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                },
                {
                    headerName: string.columnName.CTKM, headerTooltip: string.columnName.CTKM,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_CT', tooltipField: 'CY_CT_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_CT', tooltipField: 'TGT_CT_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_CT', tooltipField: 'VTG_CT_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_CT', tooltipField: 'VLY_CT', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT, headerTooltip: string.columnName.CHARGEABLE_WEIGHT,
                    //   headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_C', tooltipField: 'CY_C_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_C', tooltipField: 'TGT_C_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_C', tooltipField: 'VTG_C_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_C', tooltipField: 'VLY_C_AB', cellRenderer: (params) => this.arrowIndicator(params) }]
                },


                // {
                //     headerName: string.columnName.AL_MARKET_SHARE,
                //     children: [
                //         { headerName: string.columnName.CY, field: 'CY_AL', tooltipField: 'CY_AL' },
                //         { headerName: string.columnName.VLY, field: 'VLY_AL', tooltipField: 'VLY_AL', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                // },

                {
                    headerName: string.columnName.Block_Hours, headerTooltip: string.columnName.Block_Hours,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_B', tooltipField: 'CY_B_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_B', tooltipField: 'TGT_B_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_B', tooltipField: 'VTG_B_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_B', tooltipField: 'VLY_B', cellRenderer: (params) => this.arrowIndicator(params) }]
                },
                // {
                //     headerName: string.columnName.YIELD, headerTooltip: string.columnName.YIELD,
                //     children: [
                //         { headerName: string.columnName.CY, field: 'CY_Y', tooltipField: 'CY_Y_AB' },
                //         { headerName: string.columnName.VLY, field: 'VLY_Y', tooltipField: 'VLY_Y_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                // },

            ]

            let previosYearTableData = response.TableData.filter((d) => d.Year === currentYear - 1)
            let currentYearTableDta = response.TableData.filter((d) => d.Year === currentYear)
            let nextYearTableData = response.TableData.filter((d) => d.Year === currentYear + 1)

            var responseData = [...response.Total_LY, ...previosYearTableData, ...currentYearTableDta, ...response.Total_NY, ...nextYearTableData]

            var routemonthtableDatas = [];

            responseData.forEach((key) => {
                routemonthtableDatas.push({
                    "Month": key.MonthName === null ? '---' : key.MonthName,
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    "CY_A": this.convertZeroValueToBlank(key.CY_AvgFare),
                    "TGT_A": this.convertZeroValueToBlank(key.TGT_AvgFare),
                    "VTG_A": this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    "TGT_L": this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    "VTG_L": this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),
                    "CY_CT": this.convertZeroValueToBlank(key.CY_CTKM),
                    "TGT_CT": this.convertZeroValueToBlank(key.TGT_CTKM),
                    "VTG_CT": this.convertZeroValueToBlank(key.VTG_CTKM),
                    "VLY_CT": this.convertZeroValueToBlank(key.VLY_CTKM),
                    "CY_C": this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    "TGT_C": this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    "VTG_C": this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    "CY_B": this.convertZeroValueToBlank(key.CY_BlockHours),
                    "TGT_B": this.convertZeroValueToBlank(key.TGT_BlockHours),
                    "VTG_B": this.convertZeroValueToBlank(key.VTG_BlockHours),
                    "VLY_B": this.convertZeroValueToBlank(key.LY_BlockHours),

                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),
                    "CY_A_AB": window.numberWithCommas(key.CY_AvgFare),
                    "TGT_A_AB": window.numberWithCommas(key.TGT_AvgFare),
                    "VTG_A_AB": window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    "TGT_L_AB": window.numberWithCommas(key.TGT_LoadFactor),
                    "VTG_L_AB": window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),
                    "CY_CT_AB": window.numberWithCommas(key.CY_CTKM),
                    "TGT_CT_AB": window.numberWithCommas(key.TGT_CTKM),
                    "VTG_CT_AB": window.numberWithCommas(key.VTG_CTKM),
                    "VLY_CT_AB": window.numberWithCommas(key.VLY_CTKM),
                    "CY_C_AB": window.numberWithCommas(key.CY__ChargableWeight),
                    "TGT_C_AB": window.numberWithCommas(key.TGT_ChargableWeight),
                    "VTG_C_AB": window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    "CY_B_AB": window.numberWithCommas(key.CY_Block_Hours),
                    "TGT_B_AB": window.numberWithCommas(key.TGT_Block_Hours),
                    "VTG_B_AB": window.numberWithCommas(key.VTG_Block_Hours),
                    "VLY_B_AB": window.numberWithCommas(key.VLY_Block_Hours),
                    "Year": key.Year,
                    "MonthName": key.monthfullname
                });
            });

            var totalData = [];

            response.Total_CY.forEach((key) => {
                totalData.push({
                    "Month": "Total",
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    "CY_A": this.convertZeroValueToBlank(key.CY_AvgFare),
                    "TGT_A": this.convertZeroValueToBlank(key.TGT_AvgFare),
                    "VTG_A": this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    "TGT_L": this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    "VTG_L": this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),
                    "CY_CT": this.convertZeroValueToBlank(key.CY_CTKM),
                    "TGT_CT": this.convertZeroValueToBlank(key.TGT_CTKM),
                    "VTG_CT": this.convertZeroValueToBlank(key.VTG_CTKM),
                    "VLY_CT": this.convertZeroValueToBlank(key.VLY_CTKM),
                    "CY_C": this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    "TGT_C": this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    "VTG_C": this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    "CY_B": this.convertZeroValueToBlank(key.CY_BlockHours),
                    "TGT_B": this.convertZeroValueToBlank(key.TGT_BlockHours),
                    "VTG_B": this.convertZeroValueToBlank(key.VTG_BlockHours),
                    "VLY_B": this.convertZeroValueToBlank(key.LY_BlockHours),

                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),
                    "CY_A_AB": window.numberWithCommas(key.CY_AvgFare),
                    "TGT_A_AB": window.numberWithCommas(key.TGT_AvgFare),
                    "VTG_A_AB": window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    "TGT_L_AB": window.numberWithCommas(key.TGT_LoadFactor),
                    "VTG_L_AB": window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),
                    "CY_CT_AB": window.numberWithCommas(key.CY_CTKM),
                    "TGT_CT_AB": window.numberWithCommas(key.TGT_CTKM),
                    "VTG_CT_AB": window.numberWithCommas(key.VTG_CTKM),
                    "VLY_CT_AB": window.numberWithCommas(key.VLY_CTKM),
                    "CY_C_AB": window.numberWithCommas(key.CY__ChargableWeight),
                    "TGT_C_AB": window.numberWithCommas(key.TGT_ChargableWeight),
                    "VTG_C_AB": window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    "CY_B_AB": window.numberWithCommas(key.CY_Block_Hours),
                    "TGT_B_AB": window.numberWithCommas(key.TGT_Block_Hours),
                    "VTG_B_AB": window.numberWithCommas(key.VTG_Block_Hours),
                    "VLY_B_AB": window.numberWithCommas(key.VLY_Block_Hours),
                });

            });

            return [{
                "columnName": columnName,
                "routemonthtableDatas": routemonthtableDatas,
                "currentAccess": response.CurretAccess,
                "totalData": totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log('error', error)
            });

        return routemonthtable;
    }

    // AlertsNew DrillDown Data
    getAlertsDrillDownData(getYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, type, getCabinValue) {
        // const url = `${API_URL}/routeDataDrillDown?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}&type=${type}`;
        const url = `${API_URL}`;
        var routeRegionTable = axios.get(url, this.getDefaultHeader()).then((data) => {

            let response = ''
            if(regionId=='*' && type=='Null'){
                response = routeRegionDrillDown
            }else if(countryId=='*' && type=='Null'){
                response = routeCountryDrillDown
            }else if(routeId=='*' && type=='Null'){
                response = routeCountryRouteDrillDown
            }else if(leg=='*' && type=='Null'){
                response = routeLegDrillDown
            }else if(flight=='*' && type=='Null'){
                response = routeFlightDrillDown
            }else if(aircraftType=='*' && type=='Null'){
                response = routeAircraftDrillDown
            }else if(type!='Null'){
                if(type=='Route'){
                    response = routeCountryRouteNonDrillDown
                }else if(type=='Flights'){
                    response = routeFlightNonDrillDown
                }else if(type=='Aircraft Type'){
                    response = routeAircraftNonDrillDown
                }else if(type=='Service Type'){
                    response = routeServiceNonDrillDown
                }
            }else{
                response = routeServiceDrillDown
            }

            let avgfarezeroTGT = response.TableData.filter((d) => d.AvgFare_TGT === 0 || d.AvgFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.TableData.length

            let revenuzeroTGT = response.TableData.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.TableData.length

            let passengerzeroTGT = response.TableData.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.TableData.length

            const firstColumnName = response.ColumnName;
            const isAncillary = firstColumnName === 'Ancillary' ? true : false

            var columnName = [
                {
                    headerName: '',
                    children: [
                        {
                            headerName: firstColumnName, field: 'firstColumnName', tooltipField: firstColumnName === 'Ancillary' ? 'Ancillary_Full_Name' : 'Product_AB',
                            width: 250, alignLeft: true, underline: type === 'Null' && firstColumnName !== 'Service Type' ? true : false
                        }]
                },
                {
                    headerName: string.columnName.REVENUE, headerTooltip: string.columnName.REVENUE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: 'TGT_R_AB', sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.GROSS_WEIGHT, headerTooltip: string.columnName.GROSS_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_G', tooltipField: 'CY_G_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_G', tooltipField: 'TGT_G_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_G', tooltipField: 'VTG_G_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_G', tooltipField: 'VLY_G_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE, headerTooltip: string.columnName.AVERAGE_FARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_A', tooltipField: 'CY_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: 'TGT_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.LOAD_FACTOR, headerTooltip: string.columnName.LOAD_FACTOR,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_L', tooltipField: 'CY_L_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_L', tooltipField: 'TGT_L_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_L', tooltipField: 'VTG_L_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_L', tooltipField: 'VLY_L_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.CTKM, headerTooltip: string.columnName.CTKM,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_CT', tooltipField: 'CY_CT_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_CT', tooltipField: 'TGT_CT_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_CT', tooltipField: 'VTG_CT_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_CT', tooltipField: 'VLY_CT_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT, headerTooltip: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_C', tooltipField: 'CY_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_C', tooltipField: 'TGT_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_C', tooltipField: 'VTG_C_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_C', tooltipField: 'VLY_C_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.Block_Hours, headerTooltip: string.columnName.Block_Hours,
                    children: [{
                        headerName: string.columnName.CY, field: 'CY_B', tooltipField: 'CY_B_AB',
                        sortable: true, comparator: this.customSorting
                    },
                    {
                        headerName: string.columnName.TGT, field: 'TGT_B', tooltipField: 'TGT_B_AB',
                        sortable: true, comparator: this.customSorting
                    },
                    {
                        headerName: string.columnName.VTG, field: 'VTG_B', tooltipField: 'VTG_B_AB', cellRenderer: (params) => this.arrowIndicator(params),
                        sortable: true, comparator: this.customSorting
                    },
                    {
                        headerName: string.columnName.VLY, field: 'VLY_B', tooltipField: 'VLY_B_AB', cellRenderer: (params) => this.arrowIndicator(params),
                        sortable: true, comparator: this.customSorting
                    }]
                }
            ]

            var routeRegionTableDatas = [];
            response.TableData.forEach((key) => {
                routeRegionTableDatas.push({
                    'Ancillary_Full_Name': key.AncillaryName,
                    "firstColumnName": key.ColumnName === null ? '---' : key.ColumnName,
                    "Product_AB":key.ProductToolTip,
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),

                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),

                    'CY_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),

                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    'TGT_L': this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    'VTG_L': this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),

                    'CY_CT': this.convertZeroValueToBlank(key.CY_CTKM),
                    'TGT_CT': this.convertZeroValueToBlank(key.TGT_CTKM),
                    'VTG_CT': this.convertZeroValueToBlank(key.VTG_CTKM),
                    'VLY_CT': this.convertZeroValueToBlank(key.VLY_CTKM),

                    'CY_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),

                    'CY_B': this.convertZeroValueToBlank(key.CY_BlockHours),
                    'TGT_B': this.convertZeroValueToBlank(key.TGT_BlockHours),
                    'VTG_B': this.convertZeroValueToBlank(key.VTG_BlockHours),
                    'VLY_B': this.convertZeroValueToBlank(key.VLY_BlockHours),



                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    'TGT_G_AB': window.numberWithCommas(key.TGT_GrossWeight),
                    'VTG_G_AB': window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),

                    'CY_A_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),

                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    'TGT_L_AB': window.numberWithCommas(key.TGT_LoadFactor),
                    'VTG_L_AB': window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),

                    'CY_CT_AB': window.numberWithCommas(key.CY_CTKM),
                    'TGT_CT_AB': window.numberWithCommas(key.TGT_CTKM),
                    'VTG_CT_AB': window.numberWithCommas(key.VTG_CTKM),
                    'VLY_CT_AB': window.numberWithCommas(key.VLY_CTKM),

                    'CY_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),

                    'CY_B_AB': window.numberWithCommas(key.CY_BlockHours),
                    'TGT_B_AB': window.numberWithCommas(key.TGT_BlockHours),
                    'VTG_B_AB': window.numberWithCommas(key.VTG_BlockHours),
                    'VLY_B_AB': window.numberWithCommas(key.VLY_BlockHours),

                });
            });

            var totalData = [];
            response.Total.forEach((key) => {
                totalData.push({
                    // 'Ancillary_Full_Name': 'Total',
                    "firstColumnName": 'Total',
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),

                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),

                    'CY_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),

                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    'TGT_L': this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    'VTG_L': this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),

                    'CY_CT': this.convertZeroValueToBlank(key.CY_CTKM),
                    'TGT_CT': this.convertZeroValueToBlank(key.TGT_CTKM),
                    'VTG_CT': this.convertZeroValueToBlank(key.VTG_CTKM),
                    'VLY_CT': this.convertZeroValueToBlank(key.VLY_CTKM),

                    'CY_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),

                    'CY_B': this.convertZeroValueToBlank(key.CY_BlockHours),
                    'TGT_B': this.convertZeroValueToBlank(key.TGT_BlockHours),
                    'VTG_B': this.convertZeroValueToBlank(key.VTG_BlockHours),
                    'VLY_B': this.convertZeroValueToBlank(key.LY_BlockHours),



                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    'TGT_G_AB': window.numberWithCommas(key.TGT_GrossWeight),
                    'VTG_G_AB': window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),

                    'CY_A_AB': window.numberWithCommas(key.FRCT_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),

                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    'TGT_L_AB': window.numberWithCommas(key.TGT_LoadFactor),
                    'VTG_L_AB': window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),

                    'CY_CT_AB': window.numberWithCommas(key.CY_CTKM),
                    'TGT_CT_AB': window.numberWithCommas(key.TGT_CTKM),
                    'VTG_CT_AB': window.numberWithCommas(key.VTG_CTKM),
                    'VLY_CT_AB': window.numberWithCommas(key.VLY_CTKM),

                    'CY_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),

                    'CY_B_AB': window.numberWithCommas(key.CY_BlockHours),
                    'TGT_B_AB': window.numberWithCommas(key.TGT_BlockHours),
                    'VTG_B_AB': window.numberWithCommas(key.VTG_BlockHours),
                    'VLY_B_AB': window.numberWithCommas(key.VLY_BlockHours),

                });
            });

            return [{
                "columnName": columnName,
                "routeRegionTableDatas": routeRegionTableDatas,
                "currentAccess": response.CurrentAccess,
                'tabName': response.ColumnName,
                'firstTabName': response.first_ColumnName,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return routeRegionTable;
    }





    // Route Page API Data Processing
    getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, getCabinValue) {

        // const url = `${API_URL}/routeDataMonthly?selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}`;
        const url = `${API_URL}`;
        var routemonthtable = axios.get(url, this.getDefaultHeader()).then((data) => {


            let response = '';

            if(regionId=='*'){
                response = routeDataMonthly
            }else if(countryId=='*'){
                response = routeDataCountryDrillDown
            }else if(routeId == '*'){
                response = routeDataRouteDrillDown;
            }else if(leg == '*'){
                response = routeDataLegDrillDown
            }else if(flight == "*"){
                response = routeDataFlightDrillDown
            }else if(aircraftType == "*"){
                response = routeDataAircraftDrillDown
            }else{
                response = routeDataAircraftDrillDown
            }
            /*let avgfarezeroTGT = response.data.TableData.filter((d) => d.AvgFare_TGT === 0 || d.AvgFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.data.TableData.length

            let revenuzeroTGT = response.data.TableData.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.TableData.length

            let passengerzeroTGT = response.data.TableData.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.data.TableData.length*/

            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Month', width: 250, alignLeft: true, underline: true }]
                },
                {
                    headerName: string.columnName.REVENUE, headerTooltip: string.columnName.REVENUE,
                    // headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: 'TGT_R_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.GROSS_WEIGHT, headerTooltip: string.columnName.GROSS_WEIGHT,
                    //   headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_G', tooltipField: 'CY_G_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_G', tooltipField: 'TGT_G_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_G', tooltipField: 'VTG_G_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_G', tooltipField: 'VLY_G_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        //{ headerName: string.columnName.TKT, field: 'TKT_B', tooltipField: 'TKT_B_AB', }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE, headerTooltip: string.columnName.AVERAGE_FARE,
                    //  headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_A', tooltipField: 'CY_A_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: 'TGT_A_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.LOAD_FACTOR, headerTooltip: string.columnName.LOAD_FACTOR,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_L', tooltipField: 'CY_L_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_L', tooltipField: 'TGT_L_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_L', tooltipField: 'VTG_L_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_L', tooltipField: 'VLY_L_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                },
                {
                    headerName: string.columnName.CTKM, headerTooltip: string.columnName.CTKM,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_CT', tooltipField: 'CY_CT_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_CT', tooltipField: 'TGT_CT_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_CT', tooltipField: 'VTG_CT_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_CT', tooltipField: 'VLY_CT', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT, headerTooltip: string.columnName.CHARGEABLE_WEIGHT,
                    //   headerGroupComponent: 'customHeaderGroupComponent',
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_C', tooltipField: 'CY_C_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_C', tooltipField: 'TGT_C_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_C', tooltipField: 'VTG_C_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_C', tooltipField: 'VLY_C_AB', cellRenderer: (params) => this.arrowIndicator(params) }]
                },


                // {
                //     headerName: string.columnName.AL_MARKET_SHARE,
                //     children: [
                //         { headerName: string.columnName.CY, field: 'CY_AL', tooltipField: 'CY_AL' },
                //         { headerName: string.columnName.VLY, field: 'VLY_AL', tooltipField: 'VLY_AL', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                // },

                {
                    headerName: string.columnName.Block_Hours, headerTooltip: string.columnName.Block_Hours,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_B', tooltipField: 'CY_B_AB' },
                        { headerName: string.columnName.TGT, field: 'TGT_B', tooltipField: 'TGT_B_AB' },
                        { headerName: string.columnName.VTG, field: 'VTG_B', tooltipField: 'VTG_B_AB', cellRenderer: (params) => this.arrowIndicator(params) },
                        { headerName: string.columnName.VLY, field: 'VLY_B', tooltipField: 'VLY_B', cellRenderer: (params) => this.arrowIndicator(params) }]
                },
                // {
                //     headerName: string.columnName.YIELD, headerTooltip: string.columnName.YIELD,
                //     children: [
                //         { headerName: string.columnName.CY, field: 'CY_Y', tooltipField: 'CY_Y_AB' },
                //         { headerName: string.columnName.VLY, field: 'VLY_Y', tooltipField: 'VLY_Y_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250 }]
                // },

            ]

            let previosYearTableData = response.TableData.filter((d) => d.Year === currentYear - 1)
            let currentYearTableDta = response.TableData.filter((d) => d.Year === currentYear)
            let nextYearTableData = response.TableData.filter((d) => d.Year === currentYear + 1)

            var responseData = [...response.Total_LY, ...previosYearTableData, ...currentYearTableDta, ...response.Total_NY, ...nextYearTableData]

            var routemonthtableDatas = [];

            responseData.forEach((key) => {
                routemonthtableDatas.push({
                    "Month": key.MonthName === null ? '---' : key.MonthName,
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    "CY_A": this.convertZeroValueToBlank(key.CY_AvgFare),
                    "TGT_A": this.convertZeroValueToBlank(key.TGT_AvgFare),
                    "VTG_A": this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    "TGT_L": this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    "VTG_L": this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),
                    "CY_CT": this.convertZeroValueToBlank(key.CY_CTKM),
                    "TGT_CT": this.convertZeroValueToBlank(key.TGT_CTKM),
                    "VTG_CT": this.convertZeroValueToBlank(key.VTG_CTKM),
                    "VLY_CT": this.convertZeroValueToBlank(key.VLY_CTKM),
                    "CY_C": this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    "TGT_C": this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    "VTG_C": this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    "CY_B": this.convertZeroValueToBlank(key.CY_BlockHours),
                    "TGT_B": this.convertZeroValueToBlank(key.TGT_BlockHours),
                    "VTG_B": this.convertZeroValueToBlank(key.VTG_BlockHours),
                    "VLY_B": this.convertZeroValueToBlank(key.LY_BlockHours),

                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),
                    "CY_A_AB": window.numberWithCommas(key.CY_AvgFare),
                    "TGT_A_AB": window.numberWithCommas(key.TGT_AvgFare),
                    "VTG_A_AB": window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    "TGT_L_AB": window.numberWithCommas(key.TGT_LoadFactor),
                    "VTG_L_AB": window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),
                    "CY_CT_AB": window.numberWithCommas(key.CY_CTKM),
                    "TGT_CT_AB": window.numberWithCommas(key.TGT_CTKM),
                    "VTG_CT_AB": window.numberWithCommas(key.VTG_CTKM),
                    "VLY_CT_AB": window.numberWithCommas(key.VLY_CTKM),
                    "CY_C_AB": window.numberWithCommas(key.CY__ChargableWeight),
                    "TGT_C_AB": window.numberWithCommas(key.TGT_ChargableWeight),
                    "VTG_C_AB": window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    "CY_B_AB": window.numberWithCommas(key.CY_Block_Hours),
                    "TGT_B_AB": window.numberWithCommas(key.TGT_Block_Hours),
                    "VTG_B_AB": window.numberWithCommas(key.VTG_Block_Hours),
                    "VLY_B_AB": window.numberWithCommas(key.VLY_Block_Hours),
                    "Year": key.Year,
                    "MonthName": key.monthfullname
                });
            });

            var totalData = [];

            response.Total_CY.forEach((key) => {
                totalData.push({
                    "Month": "Total",
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),
                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    "CY_A": this.convertZeroValueToBlank(key.CY_AvgFare),
                    "TGT_A": this.convertZeroValueToBlank(key.TGT_AvgFare),
                    "VTG_A": this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),
                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    "TGT_L": this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    "VTG_L": this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),
                    "CY_CT": this.convertZeroValueToBlank(key.CY_CTKM),
                    "TGT_CT": this.convertZeroValueToBlank(key.TGT_CTKM),
                    "VTG_CT": this.convertZeroValueToBlank(key.VTG_CTKM),
                    "VLY_CT": this.convertZeroValueToBlank(key.VLY_CTKM),
                    "CY_C": this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    "TGT_C": this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    "VTG_C": this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    "CY_B": this.convertZeroValueToBlank(key.CY_BlockHours),
                    "TGT_B": this.convertZeroValueToBlank(key.TGT_BlockHours),
                    "VTG_B": this.convertZeroValueToBlank(key.VTG_BlockHours),
                    "VLY_B": this.convertZeroValueToBlank(key.LY_BlockHours),

                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),
                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    "TGT_G_AB": window.numberWithCommas(key.TGT_GrossWeight),
                    "VTG_G_AB": window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),
                    "CY_A_AB": window.numberWithCommas(key.CY_AvgFare),
                    "TGT_A_AB": window.numberWithCommas(key.TGT_AvgFare),
                    "VTG_A_AB": window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),
                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    "TGT_L_AB": window.numberWithCommas(key.TGT_LoadFactor),
                    "VTG_L_AB": window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),
                    "CY_CT_AB": window.numberWithCommas(key.CY_CTKM),
                    "TGT_CT_AB": window.numberWithCommas(key.TGT_CTKM),
                    "VTG_CT_AB": window.numberWithCommas(key.VTG_CTKM),
                    "VLY_CT_AB": window.numberWithCommas(key.VLY_CTKM),
                    "CY_C_AB": window.numberWithCommas(key.CY__ChargableWeight),
                    "TGT_C_AB": window.numberWithCommas(key.TGT_ChargableWeight),
                    "VTG_C_AB": window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),
                    "CY_B_AB": window.numberWithCommas(key.CY_Block_Hours),
                    "TGT_B_AB": window.numberWithCommas(key.TGT_Block_Hours),
                    "VTG_B_AB": window.numberWithCommas(key.VTG_Block_Hours),
                    "VLY_B_AB": window.numberWithCommas(key.VLY_Block_Hours),
                });

            });

            return [{
                "columnName": columnName,
                "routemonthtableDatas": routemonthtableDatas,
                "currentAccess": response.CurretAccess,
                "totalData": totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log('error', error)
            });

        return routemonthtable;
    }

    getRouteDrillDownData(getYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, type, getCabinValue) {
        // const url = `${API_URL}/routeDataDrillDown?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}&type=${type}`;
        const url = `${API_URL}`;
        var routeRegionTable = axios.get(url, this.getDefaultHeader()).then((data) => {

            let response = ''
            if(regionId=='*' && type=='Null'){
                response = routeRegionDrillDown
            }else if(countryId=='*' && type=='Null'){
                response = routeCountryDrillDown
            }else if(routeId=='*' && type=='Null'){
                response = routeCountryRouteDrillDown
            }else if(leg=='*' && type=='Null'){
                response = routeLegDrillDown
            }else if(flight=='*' && type=='Null'){
                response = routeFlightDrillDown
            }else if(aircraftType=='*' && type=='Null'){
                response = routeAircraftDrillDown
            }else if(type!='Null'){
                if(type=='Route'){
                    response = routeCountryRouteNonDrillDown
                }else if(type=='Flights'){
                    response = routeFlightNonDrillDown
                }else if(type=='Aircraft Type'){
                    response = routeAircraftNonDrillDown
                }else if(type=='Service Type'){
                    response = routeServiceNonDrillDown
                }
            }else{
                response = routeServiceDrillDown
            }

            let avgfarezeroTGT = response.TableData.filter((d) => d.AvgFare_TGT === 0 || d.AvgFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.TableData.length

            let revenuzeroTGT = response.TableData.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.TableData.length

            let passengerzeroTGT = response.TableData.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.TableData.length

            const firstColumnName = response.ColumnName;
            const isAncillary = firstColumnName === 'Ancillary' ? true : false

            var columnName = [
                {
                    headerName: '',
                    children: [
                        {
                            headerName: firstColumnName, field: 'firstColumnName', tooltipField: firstColumnName === 'Ancillary' ? 'Ancillary_Full_Name' : 'Product_AB',
                            width: 250, alignLeft: true, underline: type === 'Null' && firstColumnName !== 'Service Type' ? true : false
                        }]
                },
                {
                    headerName: string.columnName.REVENUE, headerTooltip: string.columnName.REVENUE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: 'TGT_R_AB', sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.GROSS_WEIGHT, headerTooltip: string.columnName.GROSS_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_G', tooltipField: 'CY_G_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_G', tooltipField: 'TGT_G_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_G', tooltipField: 'VTG_G_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_G', tooltipField: 'VLY_G_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE, headerTooltip: string.columnName.AVERAGE_FARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_A', tooltipField: 'CY_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: 'TGT_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.LOAD_FACTOR, headerTooltip: string.columnName.LOAD_FACTOR,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_L', tooltipField: 'CY_L_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_L', tooltipField: 'TGT_L_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_L', tooltipField: 'VTG_L_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_L', tooltipField: 'VLY_L_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.CTKM, headerTooltip: string.columnName.CTKM,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_CT', tooltipField: 'CY_CT_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_CT', tooltipField: 'TGT_CT_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_CT', tooltipField: 'VTG_CT_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_CT', tooltipField: 'VLY_CT_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT, headerTooltip: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_C', tooltipField: 'CY_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_C', tooltipField: 'TGT_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_C', tooltipField: 'VTG_C_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_C', tooltipField: 'VLY_C_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.Block_Hours, headerTooltip: string.columnName.Block_Hours,
                    children: [{
                        headerName: string.columnName.CY, field: 'CY_B', tooltipField: 'CY_B_AB',
                        sortable: true, comparator: this.customSorting
                    },
                    {
                        headerName: string.columnName.TGT, field: 'TGT_B', tooltipField: 'TGT_B_AB',
                        sortable: true, comparator: this.customSorting
                    },
                    {
                        headerName: string.columnName.VTG, field: 'VTG_B', tooltipField: 'VTG_B_AB', cellRenderer: (params) => this.arrowIndicator(params),
                        sortable: true, comparator: this.customSorting
                    },
                    {
                        headerName: string.columnName.VLY, field: 'VLY_B', tooltipField: 'VLY_B_AB', cellRenderer: (params) => this.arrowIndicator(params),
                        sortable: true, comparator: this.customSorting
                    }]
                }
            ]

            var routeRegionTableDatas = [];
            response.TableData.forEach((key) => {
                routeRegionTableDatas.push({
                    'Ancillary_Full_Name': key.AncillaryName,
                    "firstColumnName": key.ColumnName === null ? '---' : key.ColumnName,
                    "Product_AB":key.ProductToolTip,
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),

                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),

                    'CY_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),

                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    'TGT_L': this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    'VTG_L': this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),

                    'CY_CT': this.convertZeroValueToBlank(key.CY_CTKM),
                    'TGT_CT': this.convertZeroValueToBlank(key.TGT_CTKM),
                    'VTG_CT': this.convertZeroValueToBlank(key.VTG_CTKM),
                    'VLY_CT': this.convertZeroValueToBlank(key.VLY_CTKM),

                    'CY_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),

                    'CY_B': this.convertZeroValueToBlank(key.CY_BlockHours),
                    'TGT_B': this.convertZeroValueToBlank(key.TGT_BlockHours),
                    'VTG_B': this.convertZeroValueToBlank(key.VTG_BlockHours),
                    'VLY_B': this.convertZeroValueToBlank(key.VLY_BlockHours),



                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    'TGT_G_AB': window.numberWithCommas(key.TGT_GrossWeight),
                    'VTG_G_AB': window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),

                    'CY_A_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),

                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    'TGT_L_AB': window.numberWithCommas(key.TGT_LoadFactor),
                    'VTG_L_AB': window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),

                    'CY_CT_AB': window.numberWithCommas(key.CY_CTKM),
                    'TGT_CT_AB': window.numberWithCommas(key.TGT_CTKM),
                    'VTG_CT_AB': window.numberWithCommas(key.VTG_CTKM),
                    'VLY_CT_AB': window.numberWithCommas(key.VLY_CTKM),

                    'CY_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),

                    'CY_B_AB': window.numberWithCommas(key.CY_BlockHours),
                    'TGT_B_AB': window.numberWithCommas(key.TGT_BlockHours),
                    'VTG_B_AB': window.numberWithCommas(key.VTG_BlockHours),
                    'VLY_B_AB': window.numberWithCommas(key.VLY_BlockHours),

                });
            });

            var totalData = [];
            response.Total.forEach((key) => {
                totalData.push({
                    // 'Ancillary_Full_Name': 'Total',
                    "firstColumnName": 'Total',
                    "CY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.CY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.CY_Revenue),
                    "TGT_R": currency === 'lc' ? this.convertZeroValueToBlank(key.TGT_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.TGT_Revenue),
                    "VTG_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VTG_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VTG_Revenue),
                    "VLY_R": currency === 'lc' ? this.convertZeroValueToBlank(key.VLY_Revenue_LocalCurrency) : this.convertZeroValueToBlank(key.VLY_Revenue),

                    "CY_G": this.convertZeroValueToBlank(key.CY_GrossWeight),
                    "TGT_G": this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    "VTG_G": this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    "VLY_G": this.convertZeroValueToBlank(key.VLY_GrossWeight),

                    'CY_A': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_A': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_A': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    "VLY_A": this.convertZeroValueToBlank(key.VLY_AvgFare),

                    "CY_L": this.convertZeroValueToBlank(key.CY_LoadFactor),
                    'TGT_L': this.convertZeroValueToBlank(key.TGT_LoadFactor),
                    'VTG_L': this.convertZeroValueToBlank(key.VTG_LoadFactor),
                    "VLY_L": this.convertZeroValueToBlank(key.VLY_LoadFactor),

                    'CY_CT': this.convertZeroValueToBlank(key.CY_CTKM),
                    'TGT_CT': this.convertZeroValueToBlank(key.TGT_CTKM),
                    'VTG_CT': this.convertZeroValueToBlank(key.VTG_CTKM),
                    'VLY_CT': this.convertZeroValueToBlank(key.VLY_CTKM),

                    'CY_C': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_C': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_C': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    "VLY_C": this.convertZeroValueToBlank(key.VLY_ChargableWeight),

                    'CY_B': this.convertZeroValueToBlank(key.CY_BlockHours),
                    'TGT_B': this.convertZeroValueToBlank(key.TGT_BlockHours),
                    'VTG_B': this.convertZeroValueToBlank(key.VTG_BlockHours),
                    'VLY_B': this.convertZeroValueToBlank(key.LY_BlockHours),



                    "CY_R_AB": currency === 'lc' ? window.numberWithCommas(key.CY_Revenue_LocalCurrency) : window.numberWithCommas(key.CY_Revenue),
                    "TGT_R_AB": currency === 'lc' ? window.numberWithCommas(key.TGT_Revenue_LocalCurrency) : window.numberWithCommas(key.TGT_Revenue),
                    "VTG_R_AB": currency === 'lc' ? window.numberWithCommas(key.VTG_Revenue_LocalCurrency) : window.numberWithCommas(key.VTG_Revenue),
                    "VLY_R_AB": currency === 'lc' ? window.numberWithCommas(key.VLY_Revenue_LocalCurrency) : window.numberWithCommas(key.VLY_Revenue),

                    "CY_G_AB": window.numberWithCommas(key.CY_GrossWeight),
                    'TGT_G_AB': window.numberWithCommas(key.TGT_GrossWeight),
                    'VTG_G_AB': window.numberWithCommas(key.VTG_GrossWeight),
                    "VLY_G_AB": window.numberWithCommas(key.VLY_GrossWeight),

                    'CY_A_AB': window.numberWithCommas(key.FRCT_AvgFare),
                    'TGT_A_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_A_AB': window.numberWithCommas(key.VTG_AvgFare),
                    "VLY_A_AB": window.numberWithCommas(key.VLY_AvgFare),

                    "CY_L_AB": window.numberWithCommas(key.CY_LoadFactor),
                    'TGT_L_AB': window.numberWithCommas(key.TGT_LoadFactor),
                    'VTG_L_AB': window.numberWithCommas(key.VTG_LoadFactor),
                    "VLY_L_AB": window.numberWithCommas(key.VLY_LoadFactor),

                    'CY_CT_AB': window.numberWithCommas(key.CY_CTKM),
                    'TGT_CT_AB': window.numberWithCommas(key.TGT_CTKM),
                    'VTG_CT_AB': window.numberWithCommas(key.VTG_CTKM),
                    'VLY_CT_AB': window.numberWithCommas(key.VLY_CTKM),

                    'CY_C_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_C_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_C_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    "VLY_C_AB": window.numberWithCommas(key.VLY_ChargableWeight),

                    'CY_B_AB': window.numberWithCommas(key.CY_BlockHours),
                    'TGT_B_AB': window.numberWithCommas(key.TGT_BlockHours),
                    'VTG_B_AB': window.numberWithCommas(key.VTG_BlockHours),
                    'VLY_B_AB': window.numberWithCommas(key.VLY_BlockHours),

                });
            });

            return [{
                "columnName": columnName,
                "routeRegionTableDatas": routeRegionTableDatas,
                "currentAccess": response.CurrentAccess,
                'tabName': response.ColumnName,
                'firstTabName': response.first_ColumnName,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return routeRegionTable;
    }

    getRouteCabinDetails(getYear, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue) {

        const url = `${API_URL}/routecabinWiseDetails?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${flight}`;

        var cabinTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: string.columnName.RBD, field: 'RBD', tooltipField: 'RBD_AB', alignLeft: true
                },
                {
                    headerName: 'Bookings', field: 'Booking', tooltipField: 'Booking_AB',
                },
                {
                    headerName: 'VLY(%)', field: 'VLY(%)', tooltipField: 'VLY(%)_AB', cellRenderer: (params) => this.arrowIndicator(params)
                },
                {
                    headerName: 'Ticketed Average Fare(SR)', field: 'Ticketed Average Fare(SR)', tooltipField: 'Ticketed Average Fare(SR)_AB'
                },
                {
                    headerName: 'VLY(%)TKT', field: 'VLY(%)TKT', tooltipField: 'VLY(%)TKT_AB', cellRenderer: (params) => this.arrowIndicator(params)
                }
            ];

            var F = response.data.Data.filter((d) => d.Cabin === 'F')
            var J = response.data.Data.filter((d) => d.Cabin === 'J')
            var Y = response.data.Data.filter((d) => d.Cabin === 'Y')

            var Total_F = response.data.Total.filter((d) => d.RBD === 'Total of F')
            var Total_J = response.data.Total.filter((d) => d.RBD === 'Total of J')
            var Total_Y = response.data.Total.filter((d) => d.RBD === 'Total of Y')

            var mergedCabinData = [...Total_F, ...F, ...Total_J, ...J, ...Total_Y, ...Y]
            var cabinData = [];

            mergedCabinData.forEach((key) => {
                cabinData.push({
                    'Cabin': key.Cabin,
                    'RBD': key.RBD,
                    'Booking': this.convertZeroValueToBlank(key.Bookings_CY),
                    'VLY(%)': this.convertZeroValueToBlank(key.Bookings_VLY),
                    'Ticketed Average Fare(SR)': this.convertZeroValueToBlank(key.TicketedAverage_CY),
                    'VLY(%)TKT': this.convertZeroValueToBlank(key.TicketedAverage_VLY),
                    'Booking_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY(%)_AB': window.numberWithCommas(key.Bookings_VLY),
                    'Ticketed Average Fare(SR)_AB': window.numberWithCommas(key.TicketedAverage_CY),
                    'VLY(%)TKT_AB': window.numberWithCommas(key.TicketedAverage_VLY),
                });
            });

            return [{
                "columnName": columnName,
                "cabinData": cabinData,
            }];
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return cabinTable;
    }

    getRouteLineCharts(displayName, group, region, country, route, leg, flight, getCabin) {
        let link = '';

        if (displayName === string.columnName.BOOKINGS) {
            link = 'routebooking'
        }
        if (displayName === string.columnName.PASSENGER_OD) {
            link = 'routepassenger'
        }
        if (displayName === string.columnName.AVERAGE_FARE_$) {
            link = 'routeavgfare'
        }
        if (displayName === string.columnName.REVENUE_$) {
            link = 'routerevenue'
        }
        const url = `${API_URL}/${link}?selectedRouteGroup=${group}&selectedRouteRegion=${region}&selectedRouteCountry=${country}&selectedRoute=${route}&selectedLeg=${leg}&flight=${flight}&getCabinValue=${getCabin}`;
        return axios.get(url, this.getDefaultHeader())
            .then(response => response.data.response)
            .catch((error) => {
                console.log(error)
            });
    }

    exportCSVRouteMonthlyURL(routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue) {
        const url = `routeDataMonthly?selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${flight}`;
        return url;
    }

    exportCSVRouteDrillDownURL(getYear, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type) {
        const url = `routeDataDrillDown?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${flight}&type=${type}`;
        return url;
    }

    getPOSContributionData(getYear, currency, gettingMonth, regionId, countryId, routeId, getCabinValue, posContributionTable, count, leg, flight) {
        const url = `${API_URL}/poscontributionnew?getYear=${getYear}&gettingMonth=${gettingMonth}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&tableType=${posContributionTable}&page_num=${count}`;
        var posContri = axios.get(url, this.getDefaultHeader()).then((response) => {

            var columnNameOD = [
                {
                    headerName: '',
                    children: [
                        {
                            headerName: string.columnName.ROUTE, headerTooltip: string.columnName.ROUTE,
                            field: 'Route', tooltipField: 'Route', width: 300, alignLeft: true
                        }]
                },
                {
                    headerName: string.columnName.POS, headerTooltip: string.columnName.POS,
                    field: 'POS', tooltipField: 'POS', width: 300, alignLeft: true
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: 'Leg', headerTooltip: 'Leg',
                            field: 'Leg', tooltipField: 'Leg', width: 300, alignLeft: true
                        }]
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: string.columnName.FLIGHT, headerTooltip: string.columnName.FLIGHT,
                            field: 'Flight', tooltipField: 'Flight', width: 300, alignLeft: true
                        }]
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: string.columnName.OD, headerTooltip: string.columnName.OD,
                            field: 'OD', tooltipField: 'OD', width: 300, alignLeft: true, hide: posContributionTable == 'Segment'
                        }]
                },
                {
                    headerName: '',
                    children: [
                        {
                            headerName: 'Segment', headerTooltip: 'Segment',
                            field: 'Segment', tooltipField: 'Segment', width: 300, alignLeft: true, hide: posContributionTable == 'OD'
                        }]
                },
                {
                    headerName: string.columnName.PASSENGER_OD, headerTooltip: string.columnName.PASSENGER_OD,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_Passenger', tooltipField: 'CY_Passenger_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_P', tooltipField: 'VTG_P_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting, hide: posContributionTable == 'Segment'
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_P', tooltipField: 'VLY_P_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_$, headerTooltip: string.columnName.AVERAGE_FARE_$,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'AvgFare_CY', tooltipField: 'AvgFare_CY_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: 'VTG_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting, hide: posContributionTable == 'Segment'
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE_$, headerTooltip: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_Revenue', tooltipField: 'CY_Revenue_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting, hide: posContributionTable == 'Segment'
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_Rev', tooltipField: 'VLY_Rev_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
            ];

            var rowDataOD = [];
            let that = this;
            let resp = {}
            if (response.data.response) {
                resp = response.data.response;
            } else {
                resp = response.data
            }
            resp.TableData.forEach(function (key) {
                rowDataOD.push({
                    "Route": (key.Route),
                    "POS": (key.POS),
                    "Leg": (key.Leg),
                    "Flight": (key.FlightNumber),
                    "OD": (key.OD),
                    "Segment": (key.Segment),
                    "CY_Passenger": that.convertZeroValueToBlank(key.CY_Passenger),
                    "CY_Passenger_AB": window.numberWithCommas(key.CY_Passenger),
                    'VTG_P': that.convertZeroValueToBlank(key.Passenger_VTG),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    "VLY_P": that.convertZeroValueToBlank(key.Passenger_VLY),
                    "VLY_P_AB": window.numberWithCommas(key.Passenger_VLY),
                    "CY_Revenue": that.convertZeroValueToBlank(key.CY_Revenue),
                    "CY_Revenue_AB": window.numberWithCommas(key.CY_Revenue),
                    'VTG_R': that.convertZeroValueToBlank(key.Revenue_VTG),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_Rev': that.convertZeroValueToBlank(key.Revenue_VLY),
                    'VLY_Rev_AB': window.numberWithCommas(key.Revenue_VLY),
                    "AvgFare_CY": that.convertZeroValueToBlank(key.AvgFare_CY),
                    "AvgFare_CY_AB": window.numberWithCommas(key.AvgFare_CY),
                    'VTG_A': that.convertZeroValueToBlank(key.AvgFare_VTG),
                    'VTG_A_AB': window.numberWithCommas(key.AvgFare_VTG),
                    'VLY_A': that.convertZeroValueToBlank(key.AvgFare_VLY),
                    'VLY_A_AB': window.numberWithCommas(key.AvgFare_VLY),

                });
            });

            return [{
                "columnNameOD": columnNameOD, "rowDataOD": rowDataOD,
                currentPage: resp.pageNumber,
                totalPages: resp.totalPages,
                totalRecords: resp.totalRecords,
                paginationSize: resp.paginationLimit,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return posContri;
    }

    //Forecast Accuracy API
    getForecastMonthTable(startDate, regionId, countryId, cityId, commonOD, getCabinValue) {

        const url = `${API_URL}/forecastaccuracymonthly?startdate=${startDate}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}`;

        var forecastMonthTable = axios.get(url, this.getDefaultHeader()).then((response) => {

            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Month', width: 250, alignLeft: true, underline: true }]
                },
                {
                    headerName: string.columnName.PASSENGER,
                    children: [
                        { headerName: string.columnName.TARGET, field: 'target_p', tooltipField: 'target_p_AB' },
                        { headerName: string.columnName.Forecast, field: 'forecast_p', tooltipField: 'forecast_p_AB' },
                        { headerName: string.columnName.ACTUAL, field: 'actual_p', tooltipField: 'actual_p_AB' },
                        { headerName: string.columnName.ACCURACY, field: 'error_p', tooltipField: 'error_p_AB', cellRenderer: (params) => this.accuracyArrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_$,
                    children: [
                        { headerName: string.columnName.TARGET, field: 'target_a', tooltipField: 'target_a_AB' },
                        { headerName: string.columnName.Forecast, field: 'forecast_a', tooltipField: 'forecast_a_AB' },
                        { headerName: string.columnName.ACTUAL, field: 'actual_a', tooltipField: 'actual_a_AB' },
                        { headerName: string.columnName.ACCURACY, field: 'error_a', tooltipField: 'error_a_AB', cellRenderer: (params) => this.accuracyArrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE,
                    children: [
                        { headerName: string.columnName.TARGET, field: 'target_r', tooltipField: 'target_r_AB' },
                        { headerName: string.columnName.Forecast, field: 'forecast_r', tooltipField: 'forecast_r_AB' },
                        { headerName: string.columnName.ACTUAL, field: 'actual_r', tooltipField: 'actual_r_AB' },
                        { headerName: string.columnName.ACCURACY, field: 'error_r', tooltipField: 'error_r_AB', cellRenderer: (params) => this.accuracyArrowIndicator(params) }
                    ]
                }
            ];

            var rowData = [];

            var responseData = response.data.response

            responseData.TableData.forEach((key) => {
                rowData.push({
                    'Month': key.MonthName === null ? '---' : key.MonthName,
                    'target_p': this.convertZeroValueToBlank(key.Target_pax),
                    'forecast_p': this.convertZeroValueToBlank(key.Forecast_pax),
                    'actual_p': this.convertZeroValueToBlank(key.Actual_Pax),
                    "error_p": this.convertZeroValueToBlank(key.Error_Pax),
                    'target_a': this.convertZeroValueToBlank(key.target_avg),
                    'forecast_a': this.convertZeroValueToBlank(key.Forecast_avg),
                    'actual_a': this.convertZeroValueToBlank(key.Actual_avg),
                    'error_a': this.convertZeroValueToBlank(key.Error_Avg),
                    'target_r': this.convertZeroValueToBlank(key.Target_Rev),
                    'forecast_r': this.convertZeroValueToBlank(key.Forecast_Rev),
                    'actual_r': this.convertZeroValueToBlank(key.Actual_Rev),
                    "error_r": this.convertZeroValueToBlank(key.Error_Rev),
                    'target_p_AB': window.numberWithCommas(key.Target_pax),
                    'forecast_p_AB': window.numberWithCommas(key.Forecast_pax),
                    'actual_p_AB': window.numberWithCommas(key.Actual_Pax),
                    "error_p_AB": window.numberWithCommas(key.Error_Pax),
                    'target_a_AB': window.numberWithCommas(key.target_avg),
                    'forecast_a_AB': window.numberWithCommas(key.Forecast_avg),
                    'actual_a_AB': window.numberWithCommas(key.Actual_avg),
                    "error_a_AB": window.numberWithCommas(key.Error_Avg),
                    'target_r_AB': window.numberWithCommas(key.Target_Rev),
                    'forecast_r_AB': window.numberWithCommas(key.Forecast_Rev),
                    'actual_r_AB': window.numberWithCommas(key.Actual_Rev),
                    'error_r_AB': window.numberWithCommas(key.Error_Rev),
                    'Year': key.Year,
                    'MonthName': key.monthfullname
                });
            });

            var totalData = [];
            responseData.Total.forEach((key) => {
                totalData.push({
                    'Month': 'Total',
                    'target_p': this.convertZeroValueToBlank(key.Target_pax),
                    'forecast_p': this.convertZeroValueToBlank(key.Forecast_pax),
                    'actual_p': this.convertZeroValueToBlank(key.Actual_Pax),
                    "error_p": this.convertZeroValueToBlank(key.Error_Pax),
                    'target_a': this.convertZeroValueToBlank(key.target_avg),
                    'forecast_a': this.convertZeroValueToBlank(key.Forecast_avg),
                    'actual_a': this.convertZeroValueToBlank(key.Actual_avg),
                    'error_a': this.convertZeroValueToBlank(key.Error_Avg),
                    'target_r': this.convertZeroValueToBlank(key.Target_Rev),
                    'forecast_r': this.convertZeroValueToBlank(key.Forecast_Rev),
                    'actual_r': this.convertZeroValueToBlank(key.Actual_Rev),
                    "error_r": this.convertZeroValueToBlank(key.Error_Rev),
                    'target_p_AB': window.numberWithCommas(key.Target_pax),
                    'forecast_p_AB': window.numberWithCommas(key.Forecast_pax),
                    'actual_p_AB': window.numberWithCommas(key.Actual_Pax),
                    "error_p_AB": window.numberWithCommas(key.Error_Pax),
                    'target_a_AB': window.numberWithCommas(key.target_avg),
                    'forecast_a_AB': window.numberWithCommas(key.Forecast_avg),
                    'actual_a_AB': window.numberWithCommas(key.Actual_avg),
                    "error_a_AB": window.numberWithCommas(key.Error_Avg),
                    'target_r_AB': window.numberWithCommas(key.Target_Rev),
                    'forecast_r_AB': window.numberWithCommas(key.Forecast_Rev),
                    'actual_r_AB': window.numberWithCommas(key.Actual_Rev),
                    'error_r_AB': window.numberWithCommas(key.Error_Rev),
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData,
                "currentAccess": responseData.CurretAccess,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log(error)
            });

        return forecastMonthTable;
    }

    getForecastDrillDownData(getYear, startDate, gettingMonth, regionId, countryId, cityId, commonOD, getCabinValue, type) {
        let currentMonth = new Date().getMonth() + 1;
        const url = `${API_URL}/forecastaccuracydrilldown?startdate=${startDate}&getYear=${getYear}&gettingMonth=${gettingMonth == currentMonth ? gettingMonth - 1 : gettingMonth}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}&type=${type}`;
        var forecastRegionTable = axios.get(url, this.getDefaultHeader()).then((response) => {

            const firstColumnName = response.data.response.ColumnName;
            const currentMonth = new Date().getMonth() + 1;
            const date = new Date();
            const currmonthdate = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
            var columnName = [
                {
                    headerName: '',
                    children: [{
                        headerName: firstColumnName,
                        field: 'firstColumnName',
                        tooltipField: 'firstColumnName',
                        width: 250, alignLeft: true, underline: type === 'Null' && firstColumnName !== 'Cabin' && firstColumnName !== 'POS' && startDate !== currmonthdate ? true : false
                    }]
                },
                {
                    headerName: string.columnName.PASSENGER,
                    children: [
                        { headerName: string.columnName.TARGET, field: 'target_p', tooltipField: 'target_p_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.Forecast, field: 'forecast_p', tooltipField: 'forecast_p_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.ACTUAL, field: 'actual_p', tooltipField: 'actual_p_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.ACCURACY, field: 'error_p', tooltipField: 'error_p_AB', sortable: true, comparator: this.customSorting, cellRenderer: (params) => this.accuracyArrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_$,
                    children: [
                        { headerName: string.columnName.TARGET, field: 'target_a', tooltipField: 'target_a_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.Forecast, field: 'forecast_a', tooltipField: 'forecast_a_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.ACTUAL, field: 'actual_a', tooltipField: 'actual_a_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.ACCURACY, field: 'error_a', tooltipField: 'error_a_AB', sortable: true, comparator: this.customSorting, cellRenderer: (params) => this.accuracyArrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE,
                    children: [
                        { headerName: string.columnName.TARGET, field: 'target_r', tooltipField: 'target_r_AB', sortable: true, comparator: this.customSorting, sort: 'desc' },
                        { headerName: string.columnName.Forecast, field: 'forecast_r', tooltipField: 'forecast_r_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.ACTUAL, field: 'actual_r', tooltipField: 'actual_r_AB', sortable: true, comparator: this.customSorting },
                        { headerName: string.columnName.ACCURACY, field: 'error_r', tooltipField: 'error_r_AB', sortable: true, comparator: this.customSorting, cellRenderer: (params) => this.accuracyArrowIndicator(params) }
                    ]
                }
            ];

            var rowData = [];
            var responseData = response.data.response
            responseData.TableData.forEach((key) => {
                rowData.push({
                    'firstColumnName': key.ColumnName === null ? '---' : key.ColumnName,
                    'target_p': this.convertZeroValueToBlank(key.Target_pax),
                    'forecast_p': this.convertZeroValueToBlank(key.Forecast_pax),
                    'actual_p': this.convertZeroValueToBlank(key.Actual_Pax),
                    "error_p": this.convertZeroValueToBlank(key.Error_Pax),
                    'target_a': this.convertZeroValueToBlank(key.target_avg),
                    'forecast_a': this.convertZeroValueToBlank(key.Forecast_avg),
                    'actual_a': this.convertZeroValueToBlank(key.Actual_avg),
                    'error_a': this.convertZeroValueToBlank(key.Error_Avg),
                    'target_r': this.convertZeroValueToBlank(key.Target_Rev),
                    'forecast_r': this.convertZeroValueToBlank(key.Forecast_Rev),
                    'actual_r': this.convertZeroValueToBlank(key.Actual_Rev),
                    "error_r": this.convertZeroValueToBlank(key.Error_Rev),
                    'target_p_AB': window.numberWithCommas(key.Target_pax),
                    'forecast_p_AB': window.numberWithCommas(key.Forecast_pax),
                    'actual_p_AB': window.numberWithCommas(key.Actual_Pax),
                    "error_p_AB": window.numberWithCommas(key.Error_Pax),
                    'target_a_AB': window.numberWithCommas(key.target_avg),
                    'forecast_a_AB': window.numberWithCommas(key.Forecast_avg),
                    'actual_a_AB': window.numberWithCommas(key.Actual_avg),
                    "error_a_AB": window.numberWithCommas(key.Error_Avg),
                    'target_r_AB': window.numberWithCommas(key.Target_Rev),
                    'forecast_r_AB': window.numberWithCommas(key.Forecast_Rev),
                    'actual_r_AB': window.numberWithCommas(key.Actual_Rev),
                    'error_r_AB': window.numberWithCommas(key.Error_Rev),
                });
            });

            var totalData = [];

            responseData.Total.forEach((key) => {
                totalData.push({
                    'Ancillary_Full_Name': 'Total',
                    'firstColumnName': 'Total',
                    'target_p': this.convertZeroValueToBlank(key.Target_pax),
                    'forecast_p': this.convertZeroValueToBlank(key.Forecast_pax),
                    'actual_p': this.convertZeroValueToBlank(key.Actual_Pax),
                    "error_p": this.convertZeroValueToBlank(key.Error_Pax),
                    'target_a': this.convertZeroValueToBlank(key.target_avg),
                    'forecast_a': this.convertZeroValueToBlank(key.Forecast_avg),
                    'actual_a': this.convertZeroValueToBlank(key.Actual_avg),
                    'error_a': this.convertZeroValueToBlank(key.Error_Avg),
                    'target_r': this.convertZeroValueToBlank(key.Target_Rev),
                    'forecast_r': this.convertZeroValueToBlank(key.Forecast_Rev),
                    'actual_r': this.convertZeroValueToBlank(key.Actual_Rev),
                    "error_r": this.convertZeroValueToBlank(key.Error_Rev),
                    'target_p_AB': window.numberWithCommas(key.Target_pax),
                    'forecast_p_AB': window.numberWithCommas(key.Forecast_pax),
                    'actual_p_AB': window.numberWithCommas(key.Actual_Pax),
                    "error_p_AB": window.numberWithCommas(key.Error_Pax),
                    'target_a_AB': window.numberWithCommas(key.target_avg),
                    'forecast_a_AB': window.numberWithCommas(key.Forecast_avg),
                    'actual_a_AB': window.numberWithCommas(key.Actual_avg),
                    "error_a_AB": window.numberWithCommas(key.Error_Avg),
                    'target_r_AB': window.numberWithCommas(key.Target_Rev),
                    'forecast_r_AB': window.numberWithCommas(key.Forecast_Rev),
                    'actual_r_AB': window.numberWithCommas(key.Actual_Rev),
                    'error_r_AB': window.numberWithCommas(key.Error_Rev),
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                "currentAccess": responseData.CurrentAccess,
                'totalData': totalData,
                'tabName': responseData.ColumnName,
                'firstTabName': responseData.first_ColumnName,

            }];
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return forecastRegionTable;
    }

    //Top Markets API
    getTopMarkets(page_num, startDate, endDate, regionId, countryId, cityId, getCabinValue, getOD, getLeftTableValue) {
        // const url = `${API_URL}/topmarkettable?page_num=${page_num}&startDate=${startDate}&endDate=${endDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}`;
        const url = `${API_URL}`;
        var topMarketTable = axios.get(url, this.getDefaultHeader()).then((response) => {

            /*let forecastzeroTGT = response.data.response.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let forecastTGTVisible = forecastzeroTGT.length === response.data.response.length

            let revenuzeroTGT = response.data.response.filter((d) => d.Revenue_VTG === 0 || d.Revenue_VTG === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.response.length*/

            var columnName = [
                {
                    headerName: '',
                    children: [{
                        headerName: string.columnName.OD, field: string.columnName.OD, alignLeft: true, underline: true
                    }],
                    cellStyle: { color: 'red' }
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_CW', tooltipField: 'CY_CW_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_CW', tooltipField: 'LY_CW_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_CW', tooltipField: 'VTG_CW_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_R', tooltipField: 'LY_R_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: 'VTG_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_KG,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_AF', tooltipField: 'CY_AF_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_AF', tooltipField: 'LY_AF_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_AF', tooltipField: 'VTG_AF_AB', width: 300, cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'LY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        }, {
                            headerName: string.columnName.LY, field: 'LY_MS', tooltipField: 'LY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_MS', tooltipField: 'VTG_MS_AB', cellRenderer: (params) => this.arrowIndicator(params),
                        //     sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: 'YTD(Abs)', field: 'YTD_AL', tooltipField: 'YTD_AL_AB',
                        //     sortable: true, comparator: this.customSorting
                        // }
                    ]
                },
                // {
                //     headerName: '',
                //     children: [{ headerName: string.columnName.TOP_COMPETITOR, field: 'TOP Competitor %', width: 300 }]
                // }
            ]

            var rowData = [];
            var responseData = topmarkettable.data.response[0]
            responseData.TableData.forEach((key) => {
                rowData.push({
                    'OD': key.OD,
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'LY_CW': this.convertZeroValueToBlank(key.LY_ChargableWeight),
                    'VTG_CW': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'LY_R': this.convertZeroValueToBlank(key.LY_Revenue),
                    'VTG_R': this.convertZeroValueToBlank(key.VTG_Revenue),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'LY_AF': this.convertZeroValueToBlank(key.LY_AvgFare),
                    'VTG_AF': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_MarketShare),
                    'LY_MS': this.convertZeroValueToBlank(key.LY_MarketShare),
                    // 'VTG_MS': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    'TOP Competitor %': '---',
                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'LY_CW_AB': window.numberWithCommas(key.LY_ChargableWeight),
                    'VTG_CW_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'LY_R_AB': window.numberWithCommas(key.LY_Revenue),
                    'VTG_R_AB': window.numberWithCommas(key.VTG_Revenue),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'LY_AF_AB': window.numberWithCommas(key.LY_AvgFare),
                    'VTG_AF_AB': window.numberWithCommas(key.VTG_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_MarketShare),
                    'LY_MS_AB': window.numberWithCommas(key.LY_MarketShare),
                    // 'VTG_MS_AB': window.numberWithCommas(key.VTG_AvgFare),
                });
            });

            var totalData = [];
            responseData.Total.forEach((key) => {
                totalData.push({
                    'OD': 'Total',
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'LY_CW': this.convertZeroValueToBlank(key.LY_ChargableWeight),
                    'VTG_CW': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'LY_R': this.convertZeroValueToBlank(key.LY_Revenue),
                    'VTG_R': this.convertZeroValueToBlank(key.VTG_Revenue),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'LY_AF': this.convertZeroValueToBlank(key.LY_AvgFare),
                    'VTG_AF': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_MarketShare),
                    'LY_MS': this.convertZeroValueToBlank(key.LY_MarketShare),
                    // 'VTG_MS': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    'TOP Competitor %': '---',
                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'LY_CW_AB': window.numberWithCommas(key.LY_ChargableWeight),
                    'VTG_CW_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'LY_R_AB': window.numberWithCommas(key.LY_Revenue),
                    'VTG_R_AB': window.numberWithCommas(key.VTG_Revenue),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'LY_AF_AB': window.numberWithCommas(key.LY_AvgFare),
                    'VTG_AF_AB': window.numberWithCommas(key.VTG_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_MarketShare),
                    'LY_MS_AB': window.numberWithCommas(key.LY_MarketShare),
                    // 'VTG_MS_AB': window.numberWithCommas(key.VTG_AvgFare),
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData,
                "currentPage": responseData.pageNumber,
                "totalPages": responseData.totalPages,
                "totalRecords": responseData.totalRecords,
                "paginationSize": responseData.paginationLimit,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topMarketTable;
    }

    getTopCompetitors(startDate, endDate, regionId, countryId, cityId, getCabinValue, getOD, getLeftTableValue) {
        const url = `${API_URL}/topOdcompetitors?startDate=${startDate}&endDate=${endDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getOD=${getOD}&getLeftTableValue=${encodeURIComponent(getLeftTableValue)}`;

        var topCompetitorsTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.AIRLINE, field: 'Airline', tooltipField: 'Airline', alignLeft: true, underline: true }]
                },
                // {
                //     headerName: string.columnName.MIDT_BOOKED_PASSENGER,
                //     children: [{ headerName: string.columnName.CY, field: 'CY_MIDT' }, { headerName: string.columnName.VLY, field: 'VLY(%)_MIDT', cellRenderer: (params) => this.arrowIndicator(params) }, { headerName: string.columnName.YTD, field: 'YTD_MIDT' }]
                // },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MIDTA', tooltipField: 'CY_MIDTA_AB', underline: getLeftTableValue === 'Null' ? true : false,
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        // {
                        //     headerName: string.columnName.LY, field: 'LY_MIDTA', tooltipField: 'LY_MIDTA_AB',
                        //     sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY(%)_MIDTA', tooltipField: 'VLY(%)_MIDTA_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.YIELD,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_AL', tooltipField: 'CY_AL_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY(%)_AL', tooltipField: 'VLY(%)_AL_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                    // { headerName: string.columnName.YTD, field: 'YTD_AL', tooltipField: 'YTD_AL_AB', sortable: true, comparator: this.customSorting }]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        { headerName: string.columnName.CY_ABS, field: 'CY_ABS', width: 200 },
                        {
                            headerName: string.columnName.VLY, field: 'VLY(%)_AL', tooltipField: 'VLY(%)_AL_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                }
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'Airline': key.airlines,
                    // 'CY_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    // 'VLY(%)_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    // 'YTD_MIDT': this.convertZeroValueToBlank(key.MIDT_Booked_Airlines_YTD),
                    'CY_MIDTA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_MIDTA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY(%)_MIDTA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_AL': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'VLY(%)_AL': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'YTD_AL': this.convertZeroValueToBlank(key.MS_Airlines_YTD),
                    'CY_MIDTA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_MIDTA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY(%)_MIDTA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_AL_AB': window.numberWithCommas(key.MarketShare_CY),
                    'VLY(%)_AL_AB': window.numberWithCommas(key.MarketShare_VLY),
                    'YTD_AL_AB': window.numberWithCommas(key.MS_Airlines_YTD),
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Airline': 'Total',
                    // 'CY_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    // 'VLY(%)_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    // 'YTD_MIDT': this.convertZeroValueToBlank(key.MIDT_Booked_Airlines_YTD),
                    'CY_MIDTA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_MIDTA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY(%)_MIDTA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_AL': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'VLY(%)_AL': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'YTD_AL': this.convertZeroValueToBlank(key.MS_Airlines_YTD),
                    'CY_MIDTA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_MIDTA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY(%)_MIDTA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_AL_AB': window.numberWithCommas(key.MarketShare_CY),
                    'VLY(%)_AL_AB': window.numberWithCommas(key.MarketShare_VLY),
                    'YTD_AL_AB': window.numberWithCommas(key.MS_Airlines_YTD),
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topCompetitorsTable;
    }

    getTopAgents(startDate, endDate, regionId, countryId, cityId, getCabinValue, getOD) {

        // const url = `${API_URL}/topODagents?startDate=${startDate}&endDate=${endDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getOD=${getOD}&tableParams=Agents`;
        const url = `${API_URL}`;
        var topAgentsTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.NAME, field: 'Name', tooltipField: 'Name', width: 300, alignLeft: true, underline: false }]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_CW', tooltipField: 'CY_CW_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_CW', tooltipField: 'VLY_CW_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_KG,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_AF', tooltipField: 'CY_AF_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_AF', tooltipField: 'VLY_AF_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'CY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_MS', tooltipField: 'VLY_MS_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
            ]

            var rowData = [];

            topODagents.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'Name': key.ColumnName,
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'VLY_CW': this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'VLY_AF': this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_MarketShare),
                    'VLY_MS': this.convertZeroValueToBlank(key.VLY_MarketShare),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'VLY_R': this.convertZeroValueToBlank(key.VLY_Revenue),

                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'VLY_CW_AB': window.numberWithCommas(key.VLY_ChargableWeight),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'VLY_AF_AB': window.numberWithCommas(key.VLY_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_MarketShare),
                    'VLY_MS_AB': window.numberWithCommas(key.VLY_MarketShare),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'VLY_R_AB': window.numberWithCommas(key.VLY_Revenue)
                });
            });

            var totalData = [];
            topODagents.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Name': 'Total',
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'VLY_CW': this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'VLY_AF': this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_MarketShare),
                    'VLY_MS': this.convertZeroValueToBlank(key.VLY_MarketShare),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'VLY_R': this.convertZeroValueToBlank(key.VLY_Revenue),

                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'VLY_CW_AB': window.numberWithCommas(key.VLY_ChargableWeight),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'VLY_AF_AB': window.numberWithCommas(key.VLY_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_MarketShare),
                    'VLY_MS_AB': window.numberWithCommas(key.VLY_MarketShare),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'VLY_R_AB': window.numberWithCommas(key.VLY_Revenue)
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topAgentsTable;
    }


    getTopProducts(startDate, endDate, regionId, countryId, cityId, getCabinValue, getOD) {

        // const url = `${API_URL}/topODagents?startDate=${startDate}&endDate=${endDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getOD=${getOD}&tableParams=Product`;
        const url = `${API_URL}`;
        var topProductsTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.Product, field: 'Product', tooltipField: 'Product_AB', width: 300, alignLeft: true, underline: false }]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_CW', tooltipField: 'CY_CW_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_CW', tooltipField: 'VLY_CW_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_KG,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_AF', tooltipField: 'CY_AF_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_AF', tooltipField: 'VLY_AF_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'CY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_MS', tooltipField: 'VLY_MS_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_R', tooltipField: 'CY_R_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
            ]

            var rowData = [];

            topODProducts.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'Product': key.ColumnName,
                    'Product_AB': key.ProductToolTip,
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'VLY_CW': this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'VLY_AF': this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_MarketShare),
                    'VLY_MS': this.convertZeroValueToBlank(key.VLY_MarketShare),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'VLY_R': this.convertZeroValueToBlank(key.VLY_Revenue),

                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'VLY_CW_AB': window.numberWithCommas(key.VLY_ChargableWeight),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'VLY_AF_AB': window.numberWithCommas(key.VLY_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_MarketShare),
                    'VLY_MS_AB': window.numberWithCommas(key.VLY_MarketShare),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'VLY_R_AB': window.numberWithCommas(key.VLY_Revenue)
                });
            });

            var totalData = [];
            topODProducts.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Product': 'Total',
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'VLY_CW': this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'VLY_AF': this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_MarketShare),
                    'VLY_MS': this.convertZeroValueToBlank(key.VLY_MarketShare),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'VLY_R': this.convertZeroValueToBlank(key.VLY_Revenue),

                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'VLY_CW_AB': window.numberWithCommas(key.VLY_ChargableWeight),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'VLY_AF_AB': window.numberWithCommas(key.VLY_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_MarketShare),
                    'VLY_MS_AB': window.numberWithCommas(key.VLY_MarketShare),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'VLY_R_AB': window.numberWithCommas(key.VLY_Revenue)
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topProductsTable;
    }


    //Competitor Analysis
    getCompetitorAnalysis(endDate, startDate, regionId, countryId, cityId, getCabinValue, getAirline) {
        const url = `${API_URL}/competitorcabin?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getAirline=${encodeURIComponent(getAirline)}`;

        var topMarketCabin = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.CABIN, field: 'Cabin', alignLeft: true }]
                },
                {
                    headerName: getAirline,
                    children: [
                        {
                            headerName: string.columnName.MIDT_CY_BOOKINGS, field: 'MIDT CY Bookings_C', tooltipField: 'MIDT CY Bookings_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.MIDT_LY_BOOKINGS, field: 'MIDT LY Bookings_C', tooltipField: 'MIDT LY Bookings_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.MIDT_BOOKINGS_VLY, field: 'MIDT Bookings VLY(%)_C', tooltipField: 'MIDT Bookings VLY(%)_C_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250,
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.CY_MS, field: 'CY MS_C', tooltipField: 'CY MS_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.LY_MS, field: 'LY MS_C', tooltipField: 'LY MS_C_AB',
                            sortable: true, comparator: this.customSorting
                        },
                    ]
                },
                {
                    headerName: string.columnName.AL,
                    children: [
                        {
                            headerName: string.columnName.MIDT_CY_BOOKINGS, field: 'MIDT CY Bookings_A', tooltipField: 'MIDT CY Bookings_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.MIDT_LY_BOOKINGS, field: 'MIDT LY Bookings_A', tooltipField: 'MIDT LY Bookings_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.MIDT_BOOKINGS_VLY, field: 'MIDT Bookings VLY(%)_A', tooltipField: 'MIDT Bookings VLY(%)_A_AB', cellRenderer: (params) => this.arrowIndicator(params), width: 250,
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.CY_MS, field: 'CY MS_A', tooltipField: 'CY MS_A_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.LY_MS, field: 'LY MS_A', tooltipField: 'LY MS_A_AB',
                            sortable: true, comparator: this.customSorting
                        },
                    ]
                }
            ]

            var rowData = []

            response.data.response[0].TableData.forEach((A) => {
                rowData.push({
                    'Cabin': A.CX_Cabin,
                    'MIDT CY Bookings_C': this.convertZeroValueToBlank(A.CX_MIDT_cy),
                    'MIDT LY Bookings_C': this.convertZeroValueToBlank(A.CX_MIDT_ly),
                    'MIDT Bookings VLY(%)_C': this.convertZeroValueToBlank(A.CX_VLY),
                    'CY MS_C': this.convertZeroValueToBlank(A.CX_CY_MS),
                    'LY MS_C': this.convertZeroValueToBlank(A.CX_LY_MS),
                    'MIDT CY Bookings_A': this.convertZeroValueToBlank(A.AL_MIDT_cy),
                    'MIDT LY Bookings_A': this.convertZeroValueToBlank(A.AL_MIDT_ly),
                    'MIDT Bookings VLY(%)_A': this.convertZeroValueToBlank(A.AL_VLY),
                    'CY MS_A': this.convertZeroValueToBlank(A.AL_CY_MS),
                    'LY MS_A': this.convertZeroValueToBlank(A.AL_LY_MS),
                    'MIDT CY Bookings_C_AB': window.numberWithCommas(A.CX_MIDT_cy),
                    'MIDT LY Bookings_C_AB': window.numberWithCommas(A.CX_MIDT_ly),
                    'MIDT Bookings VLY(%)_C_AB': window.numberWithCommas(A.CX_VLY),
                    'CY MS_C_AB': window.numberWithCommas(A.CX_CY_MS),
                    'LY MS_C_AB': window.numberWithCommas(A.CX_LY_MS),
                    'MIDT CY Bookings_A_AB': window.numberWithCommas(A.AL_MIDT_cy),
                    'MIDT LY Bookings_A_AB': window.numberWithCommas(A.AL_MIDT_ly),
                    'MIDT Bookings VLY(%)_A_AB': window.numberWithCommas(A.AL_VLY),
                    'CY MS_A_AB': window.numberWithCommas(A.AL_CY_MS),
                    'LY MS_A_AB': window.numberWithCommas(A.AL_LY_MS),
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((A) => {
                totalData.push({
                    'Cabin': 'Total',
                    'MIDT CY Bookings_C': this.convertZeroValueToBlank(A.CX_MIDT_cy),
                    'MIDT LY Bookings_C': this.convertZeroValueToBlank(A.CX_MIDT_ly),
                    'MIDT Bookings VLY(%)_C': this.convertZeroValueToBlank(A.CX_VLY),
                    'CY MS_C': this.convertZeroValueToBlank(A.CX_CY_MS),
                    'LY MS_C': this.convertZeroValueToBlank(A.CX_LY_MS),
                    'MIDT CY Bookings_A': this.convertZeroValueToBlank(A.AL_MIDT_cy),
                    'MIDT LY Bookings_A': this.convertZeroValueToBlank(A.AL_MIDT_ly),
                    'MIDT Bookings VLY(%)_A': this.convertZeroValueToBlank(A.AL_VLY),
                    'CY MS_A': this.convertZeroValueToBlank(A.AL_CY_MS),
                    'LY MS_A': this.convertZeroValueToBlank(A.AL_LY_MS),
                    'MIDT CY Bookings_C_AB': window.numberWithCommas(A.CX_MIDT_cy),
                    'MIDT LY Bookings_C_AB': window.numberWithCommas(A.CX_MIDT_ly),
                    'MIDT Bookings VLY(%)_C_AB': window.numberWithCommas(A.CX_VLY),
                    'CY MS_C_AB': window.numberWithCommas(A.CX_CY_MS),
                    'LY MS_C_AB': window.numberWithCommas(A.CX_LY_MS),
                    'MIDT CY Bookings_A_AB': window.numberWithCommas(A.AL_MIDT_cy),
                    'MIDT LY Bookings_A_AB': window.numberWithCommas(A.AL_MIDT_ly),
                    'MIDT Bookings VLY(%)_A_AB': window.numberWithCommas(A.AL_VLY),
                    'CY MS_A_AB': window.numberWithCommas(A.AL_CY_MS),
                    'LY MS_A_AB': window.numberWithCommas(A.AL_LY_MS),
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
                // 'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topMarketCabin;
    }

    getTopMarketsForCompetitors(endDate, startDate, regionId, countryId, cityId, getCabinValue, getAirline, getTopMarkets) {
        const url = `${API_URL}/competitortopmarkets?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getAirline=${encodeURIComponent(getAirline)}&getTopMarkets=${getTopMarkets}`;

        var topMarketTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{
                        headerName: string.columnName.OD, field: 'OD', tooltipField: 'OD', alignLeft: true, underline: true
                    }]
                },
                {
                    headerName: string.columnName.MIDT_BOOKINGS,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_M', tooltipField: 'CY_M_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_M', tooltipField: 'LY_M_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_M', tooltipField: 'VLY_M_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'CY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_MS', tooltipField: 'LY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        }]
                },
                // {
                //     headerName: '',
                //     children: [{ headerName: string.columnName.AIRLINE_RANK, field: 'Airline Rank', width: 300 }]
                // }
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'OD': key.OD,
                    // 'MIDT': 'CX',
                    'CY_M': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_M': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY_M': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_MS': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'LY_MS': this.convertZeroValueToBlank(key.MarketShare_LY),
                    'CY_M_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_M_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY_M_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_MS_AB': window.numberWithCommas(key.MarketShare_CY),
                    'LY_MS_AB': window.numberWithCommas(key.MarketShare_LY),
                    'Airline Rank': '---',
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'OD': 'Total',
                    // 'MIDT': 'CX',
                    'CY_M': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_M': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY_M': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_MS': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'LY_MS': this.convertZeroValueToBlank(key.MarketShare_LY),
                    'CY_M_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_M_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY_M_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_MS_AB': window.numberWithCommas(key.MarketShare_CY),
                    'LY_MS_AB': window.numberWithCommas(key.MarketShare_LY),
                    'Airline Rank': '---',
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topMarketTable;
    }

    getTopAgentsForCompetitors(endDate, startDate, regionId, countryId, cityId, getCabinValue, getAirline, getTopMarkets, getOD) {

        const url = `${API_URL}/competitortopagents?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getAirline=${encodeURIComponent(getAirline)}&getTopMarkets=${getTopMarkets}&getOD=${getOD}`;

        var topAgentsTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [{ headerName: string.columnName.NAME, field: 'Name', tooltipField: 'Name', width: 300, alignLeft: true, underline: true }]
                },
                {
                    headerName: string.columnName.MIDT_BOOKED_PASSENGER,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MIDT', tooltipField: 'CY_MIDT_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY(%)_MIDT', tooltipField: 'VLY(%)_MIDT_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YTD, field: 'YTD_MIDT', tooltipField: 'YTD_MIDT_AB',
                            sortable: true, comparator: this.customSorting
                        }]
                }, {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MIDTA', tooltipField: 'CY_MIDTA_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY(%)_MIDTA', tooltipField: 'VLY(%)_MIDTA_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YTD, field: 'YTD_MIDTA', tooltipField: 'YTD_MIDTA_AB',
                            sortable: true, comparator: this.customSorting
                        }]
                },
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'Name': key.AgentName,
                    'CY_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    'VLY(%)_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    'YTD_MIDT': this.convertZeroValueToBlank(key.Booked_YTD),
                    'CY_MIDTA': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'VLY(%)_MIDTA': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'YTD_MIDTA': this.convertZeroValueToBlank(key.MS_YTD),
                    'CY_MIDT_AB': window.numberWithCommas(key.BookedPassenger_CY),
                    'VLY(%)_MIDT_AB': window.numberWithCommas(key.BookedPassenger_VLY),
                    'YTD_MIDT_AB': window.numberWithCommas(key.Booked_YTD),
                    'CY_MIDTA_AB': window.numberWithCommas(key.MarketShare_CY),
                    'VLY(%)_MIDTA_AB': window.numberWithCommas(key.MarketShare_VLY),
                    'YTD_MIDTA_AB': window.numberWithCommas(key.MS_YTD),
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Name': 'Total',
                    'CY_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    'VLY(%)_MIDT': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    'YTD_MIDT': this.convertZeroValueToBlank(key.Booked_YTD),
                    'CY_MIDTA': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'VLY(%)_MIDTA': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'YTD_MIDTA': this.convertZeroValueToBlank(key.MS_YTD),
                    'CY_MIDT_AB': window.numberWithCommas(key.BookedPassenger_CY),
                    'VLY(%)_MIDT_AB': window.numberWithCommas(key.BookedPassenger_VLY),
                    'YTD_MIDT_AB': window.numberWithCommas(key.Booked_YTD),
                    'CY_MIDTA_AB': window.numberWithCommas(key.MarketShare_CY),
                    'VLY(%)_MIDTA_AB': window.numberWithCommas(key.MarketShare_VLY),
                    'YTD_MIDTA_AB': window.numberWithCommas(key.MS_YTD),
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topAgentsTable;
    }

    //Agent Analysis
    getAgentAnalysis(endDate, startDate, regionId, countryId, cityId, getCabinValue, agentname) {
        const url = `${API_URL}/agentanalysisreport?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&agentname=${encodeURIComponent(agentname)}`;

        var agentAnalysisTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [
                        { headerName: string.columnName.AGENT_NAME, field: 'AgentName', tooltipField: 'AgentName', alignLeft: true }]
                },
                {
                    headerName: string.columnName.MIDT_BOOKED_PASSENGER,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_M', tooltipField: 'CY_M_AB' },
                        { headerName: string.columnName.LY, field: 'LY_M', tooltipField: 'LY_M_AB' },
                        { headerName: string.columnName.VLY, field: 'VLY_M', tooltipField: 'VLY_M_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.MIDT_AIRLINES_BOOKED_PASSENGER,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_MA', tooltipField: 'CY_MA_AB' },
                        { headerName: string.columnName.LY, field: 'LY_MA', tooltipField: 'LY_MA_AB' },
                        { headerName: string.columnName.VLY, field: 'VLY_MA', tooltipField: 'VLY_MA_AB', cellRenderer: (params) => this.arrowIndicator(params) }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        { headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'CY_MS_AB' },
                        { headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_MS', tooltipField: 'YOY_MS_AB' },
                    ]
                },
                {
                    headerName: string.columnName.AL_MARKET_SHARE,
                    children: [
                        { headerName: string.columnName.AL_PERCENTAGE, field: 'AL', tooltipField: 'AL_AB' },
                        { headerName: string.columnName.YOY_PTS, field: 'YOY(pts)', tooltipField: 'YOY(pts)_AB' },
                    ]
                }
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'AgentName': key.AgentName,
                    'CY_M': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    'LY_M': this.convertZeroValueToBlank(key.BookedPassenger_LY),
                    'VLY_M': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    'CY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_MS': this.convertZeroValueToBlank(key.Agent_MS_CY),
                    'YOY_MS': this.convertZeroValueToBlank(key.Agent_MS_YOY),
                    'AL': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'YOY(pts)': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'CY_M_AB': window.numberWithCommas(key.BookedPassenger_CY),
                    'LY_M_AB': window.numberWithCommas(key.BookedPassenger_LY),
                    'VLY_M_AB': window.numberWithCommas(key.BookedPassenger_VLY),
                    'CY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_MS_AB': window.numberWithCommas(key.Agent_MS_CY),
                    'YOY_MS_AB': window.numberWithCommas(key.Agent_MS_YOY),
                    'AL_AB': window.numberWithCommas(key.MarketShare_CY),
                    'YOY(pts)_AB': window.numberWithCommas(key.MarketShare_VLY)
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return agentAnalysisTable;
    }

    getTopMarketsForAgent(endDate, startDate, regionId, countryId, cityId, getCabinValue, getTopMarkets, agentname) {
        const url = `${API_URL}/agenttopmarkets?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, getCabinValue)}&getTopMarkets=${getTopMarkets}&agentname=${encodeURIComponent(agentname)}`;

        var topMarketTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            var columnName = [
                {
                    headerName: '',
                    children: [
                        { headerName: string.columnName.OD, field: 'OD', alignLeft: true }]
                },
                {
                    headerName: string.columnName.MIDT_BOOKED_PASSENGER,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_M', tooltipField: 'CY_M_AB',
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_M', tooltipField: 'LY_M_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_M', tooltipField: 'VLY_M_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.MIDT_AIRLINES_BOOKED_PASSENGER,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MA', tooltipField: 'CY_MA_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.LY, field: 'LY_MA', tooltipField: 'LY_MA_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_MA', tooltipField: 'VLY_MA_AB', cellRenderer: (params) => this.arrowIndicator(params),
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: 'CY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PERCENTAGE, field: 'YOY_MS', tooltipField: 'YOY_MS_AB',
                            sortable: true, comparator: this.customSorting
                        },
                    ]
                },
                {
                    headerName: string.columnName.AL_MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.AL_PERCENTAGE, field: 'AL', tooltipField: 'AL_AB',
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.YOY_PTS, field: 'YOY(pts)', tooltipField: 'YOY(pts)_AB',
                            sortable: true, comparator: this.customSorting
                        },
                    ]
                }
            ]
            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'OD': key.OD,
                    'CY_M': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    'LY_M': this.convertZeroValueToBlank(key.BookedPassenger_LY),
                    'VLY_M': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    'CY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_MS': this.convertZeroValueToBlank(key.Agent_MS_CY),
                    'YOY_MS': this.convertZeroValueToBlank(key.Agent_MS_YOY),
                    'AL': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'YOY(pts)': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'CY_M_AB': window.numberWithCommas(key.BookedPassenger_CY),
                    'LY_M_AB': window.numberWithCommas(key.BookedPassenger_LY),
                    'VLY_M_AB': window.numberWithCommas(key.BookedPassenger_VLY),
                    'CY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_MS_AB': window.numberWithCommas(key.Agent_MS_CY),
                    'YOY_MS_AB': window.numberWithCommas(key.Agent_MS_YOY),
                    'AL_AB': window.numberWithCommas(key.MarketShare_CY),
                    'YOY(pts)_AB': window.numberWithCommas(key.MarketShare_VLY)
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'OD': 'Total',
                    'CY_M': this.convertZeroValueToBlank(key.BookedPassenger_CY),
                    'LY_M': this.convertZeroValueToBlank(key.BookedPassenger_LY),
                    'VLY_M': this.convertZeroValueToBlank(key.BookedPassenger_VLY),
                    'CY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_CY),
                    'LY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_LY),
                    'VLY_MA': this.convertZeroValueToBlank(key.AirlinesBookedPassenger_VLY),
                    'CY_MS': this.convertZeroValueToBlank(key.Agent_MS_CY),
                    'YOY_MS': this.convertZeroValueToBlank(key.Agent_MS_YOY),
                    'AL': this.convertZeroValueToBlank(key.MarketShare_CY),
                    'YOY(pts)': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    'CY_M_AB': window.numberWithCommas(key.BookedPassenger_CY),
                    'LY_M_AB': window.numberWithCommas(key.BookedPassenger_LY),
                    'VLY_M_AB': window.numberWithCommas(key.BookedPassenger_VLY),
                    'CY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_CY),
                    'LY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_LY),
                    'VLY_MA_AB': window.numberWithCommas(key.AirlinesBookedPassenger_VLY),
                    'CY_MS_AB': window.numberWithCommas(key.Agent_MS_CY),
                    'YOY_MS_AB': window.numberWithCommas(key.Agent_MS_YOY),
                    'AL_AB': window.numberWithCommas(key.MarketShare_CY),
                    'YOY(pts)_AB': window.numberWithCommas(key.MarketShare_VLY)
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return topMarketTable;
    }

    //Distribution Channel Performance
    getChannelPerformance(endDate, startDate, regionId, countryId, cityId, getCabinValue) {
        // const url = `${API_URL}/channelperformance?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, 'Null')}`;
        const url = `${API_URL}`;
        var channelData = axios.get(url, this.getDefaultHeader()).then((response) => {

            /*let avgfarezeroTGT = response.data.response.filter((d) => d.AverageFare_TGT === 0 || d.AverageFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.data.response.length

            let revenuzeroTGT = response.data.response.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.response.length

            let passengerzeroTGT = response.data.response.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.data.response.length*/

            var columnName = [
                {
                    headerName: '',
                    children: [
                        { headerName: string.columnName.AGENT_FORWARDERS, field: 'Agent_Forwarders', tooltipField: "Agent_Forwarders", width: 320, alignLeft: true, underline: false }
                    ]
                },
                {
                    headerName: string.columnName.GROSS_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_GW', tooltipField: "CY_GW_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        /*{
                            headerName: string.columnName.TGT, field: 'TGT_GW', tooltipField: "TGT_GW_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_GW', tooltipField: "VTG_GW_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },*/
                        {
                            headerName: string.columnName.VLY, field: 'VLY_GW', tooltipField: "VLY_GW_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }

                    ]
                },
                {
                    headerName: string.columnName.CHARGEABLE_WEIGHT,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_CW', tooltipField: "CY_CW_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        /*{
                            headerName: string.columnName.TGT, field: 'TGT_CW', tooltipField: "TGT_CW_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_CW', tooltipField: "VTG_CW_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },*/
                        {
                            headerName: string.columnName.VLY, field: 'VLY_CW', tooltipField: "VLY_CW_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_R', tooltipField: "CY_R_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        /*{
                            headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: "TGT_R_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: "VTG_R_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },*/
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: "VLY_R_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_KG,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_AF', tooltipField: "CY_AF_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        /*{
                            headerName: string.columnName.TGT, field: 'TGT_AF', tooltipField: "TGT_AF_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_AF', tooltipField: "VTG_AF_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },*/
                        {
                            headerName: string.columnName.VLY, field: 'VLY_AF', tooltipField: "VLY_AF_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.MARKET_SHARE,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_MS', tooltipField: "CY_MS_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        /*{
                            headerName: string.columnName.TGT, field: 'TGT_MS', tooltipField: "TGT_MS_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_MS', tooltipField: "VTG_MS_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },*/
                        {
                            headerName: string.columnName.VLY, field: 'VLY_MS', tooltipField: "VLY_MS_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                }
            ]

            var rowData = [];
            channelperformance.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'Agent_Forwarders': key.AgentName,
                    'CY_GW': this.convertZeroValueToBlank(key.CY_GrossWeight),
                    //'TGT_GW': this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    //'VTG_GW': this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    'VLY_GW': this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    //'TGT_CW': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    //'VTG_CW': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    'VLY_CW': this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    //'TGT_R': this.convertZeroValueToBlank(key.TGT_Revenue),
                    //'VTG_R': this.convertZeroValueToBlank(key.VTG_Revenue),
                    'VLY_R': this.convertZeroValueToBlank(key.VLY_Revenue),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    //'TGT_AF': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    //'VTG_AF': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    'VLY_AF': this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_AL),
                    //'TGT_MS': this.convertZeroValueToBlank(key.TGT_MarketShare),
                    //'VTG_MS': this.convertZeroValueToBlank(key.VTG_MarketShare),
                    'VLY_MS': this.convertZeroValueToBlank(key.VLY_AL),
                    // 'Market Share': this.convertZeroValueToBlank(key.MarketShare_CY),
                    // 'VLY_MS': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    // 'SA Share': '---',
                    // 'VLY_SA': '---',
                    'CY_GW_AB': window.numberWithCommas(key.CY_GrossWeight),
                    //'TGT_GW_AB': window.numberWithCommas(key.TGT_GrossWeight),
                    //'VTG_GW_AB': window.numberWithCommas(key.VTG_GrossWeight),
                    'VLY_GW_AB': window.numberWithCommas(key.VLY_GrossWeight),
                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    //'TGT_CW_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    //'VTG_CW_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    'VLY_CW_AB': window.numberWithCommas(key.VLY_ChargableWeight),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    //'TGT_R_AB': window.numberWithCommas(key.TGT_Revenue),
                    //'VTG_R_AB': window.numberWithCommas(key.VTG_Revenue),
                    'VLY_R_AB': window.numberWithCommas(key.VLY_Revenue),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    //'TGT_AF_AB': window.numberWithCommas(key.TGT_AvgFare),
                    //'VTG_AF_AB': window.numberWithCommas(key.VTG_AvgFare),
                    'VLY_AF_AB': window.numberWithCommas(key.VLY_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_AL),
                    //'TGT_MS_AB': window.numberWithCommas(key.TGT_MarketShare),
                    //'VTG_MS_AB': window.numberWithCommas(key.VTG_MarketShare),
                    'VLY_MS_AB': window.numberWithCommas(key.VLY_AL),

                });
            });

            var totalData = [];
            channelperformance.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Agent_Forwarders': 'Total',
                    'CY_GW': this.convertZeroValueToBlank(key.CY_GrossWeight),
                    'TGT_GW': this.convertZeroValueToBlank(key.TGT_GrossWeight),
                    'VTG_GW': this.convertZeroValueToBlank(key.VTG_GrossWeight),
                    'VLY_GW': this.convertZeroValueToBlank(key.VLY_GrossWeight),
                    'CY_CW': this.convertZeroValueToBlank(key.CY_ChargableWeight),
                    'TGT_CW': this.convertZeroValueToBlank(key.TGT_ChargableWeight),
                    'VTG_CW': this.convertZeroValueToBlank(key.VTG_ChargableWeight),
                    'VLY_CW': this.convertZeroValueToBlank(key.VLY_ChargableWeight),
                    'CY_R': this.convertZeroValueToBlank(key.CY_Revenue),
                    'TGT_R': this.convertZeroValueToBlank(key.TGT_Revenue),
                    'VTG_R': this.convertZeroValueToBlank(key.VTG_Revenue),
                    'VLY_R': this.convertZeroValueToBlank(key.VLY_Revenue),
                    'CY_AF': this.convertZeroValueToBlank(key.CY_AvgFare),
                    'TGT_AF': this.convertZeroValueToBlank(key.TGT_AvgFare),
                    'VTG_AF': this.convertZeroValueToBlank(key.VTG_AvgFare),
                    'VLY_AF': this.convertZeroValueToBlank(key.VLY_AvgFare),
                    'CY_MS': this.convertZeroValueToBlank(key.CY_AL),
                    'TGT_MS': this.convertZeroValueToBlank(key.TGT_MarketShare),
                    'VTG_MS': this.convertZeroValueToBlank(key.VTG_MarketShare),
                    'VLY_MS': this.convertZeroValueToBlank(key.VLY_AL),
                    // 'Market Share': this.convertZeroValueToBlank(key.MarketShare_CY),
                    // 'VLY_MS': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    // 'SA Share': '---',
                    // 'VLY_SA': '---',
                    'CY_GW_AB': window.numberWithCommas(key.CY_GrossWeight),
                    'TGT_GW_AB': window.numberWithCommas(key.TGT_GrossWeight),
                    'VTG_GW_AB': window.numberWithCommas(key.VTG_GrossWeight),
                    'VLY_GW_AB': window.numberWithCommas(key.VLY_GrossWeight),
                    'CY_CW_AB': window.numberWithCommas(key.CY_ChargableWeight),
                    'TGT_CW_AB': window.numberWithCommas(key.TGT_ChargableWeight),
                    'VTG_CW_AB': window.numberWithCommas(key.VTG_ChargableWeight),
                    'VLY_CW_AB': window.numberWithCommas(key.VLY_ChargableWeight),
                    'CY_R_AB': window.numberWithCommas(key.CY_Revenue),
                    'TGT_R_AB': window.numberWithCommas(key.TGT_Revenue),
                    'VTG_R_AB': window.numberWithCommas(key.VTG_Revenue),
                    'VLY_R_AB': window.numberWithCommas(key.VLY_Revenue),
                    'CY_AF_AB': window.numberWithCommas(key.CY_AvgFare),
                    'TGT_AF_AB': window.numberWithCommas(key.TGT_AvgFare),
                    'VTG_AF_AB': window.numberWithCommas(key.VTG_AvgFare),
                    'VLY_AF_AB': window.numberWithCommas(key.VLY_AvgFare),
                    'CY_MS_AB': window.numberWithCommas(key.CY_AL),
                    'TGT_MS_AB': window.numberWithCommas(key.TGT_MarketShare),
                    'VTG_MS_AB': window.numberWithCommas(key.VTG_MarketShare),
                    'VLY_MS_AB': window.numberWithCommas(key.VLY_AL),

                    // 'Market Share_AB': window.numberWithCommas(key.MarketShare_CY),
                    // 'VLY_MS_AB': window.numberWithCommas(key.MarketShare_VLY),
                    // 'SA Share_AB': '---',
                    // 'VLY_SA_AB': '---'
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return channelData;
    }


    getSubChannelPerformance(endDate, startDate, regionId, countryId, cityId, getCabinValue, channelname, getAgent) {
        const url = `${API_URL}/subchannelperformance?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, 'Null')}&channelname=${encodeURIComponent(channelname)}&getAgent=${encodeURIComponent(getAgent)}`;

        var subChannelData = axios.get(url, this.getDefaultHeader()).then((response) => {

            let avgfarezeroTGT = response.data.response.filter((d) => d.AverageFare_TGT === 0 || d.AverageFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.data.response.length

            let revenuzeroTGT = response.data.response.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.response.length

            let passengerzeroTGT = response.data.response.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.data.response.length
            let firstColumnName = response.data.response[0].columnName
            var columnName = [
                {
                    headerName: '',
                    children: [
                        {
                            headerName: firstColumnName,
                            field: firstColumnName,
                            tooltipField: firstColumnName,
                            width: 250,
                            alignLeft: true,
                            underline: firstColumnName !== 'CommonOD'
                        }
                    ]
                },
                {
                    headerName: string.columnName.BOOKINGS,
                    children: [
                        {
                            headerName: string.columnName.CY, field: 'CY_B', tooltipField: "CY_B_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_B', tooltipField: "VLY_B_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TKT, field: 'TKT_B', tooltipField: "TKT_B_AB",
                            sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.PASSENGER,
                    children: [
                        {
                            headerName: string.columnName.FORECAST_ACT, field: 'Forecast_P', tooltipField: "Forecast_P_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        // {
                        //     headerName: string.columnName.TGT, field: 'TGT_P', tooltipField: "TGT_P_AB",
                        //     hide: passengerTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_P', tooltipField: "VTG_P_AB",
                        //     cellRenderer: (params) => this.arrowIndicator(params), hide: passengerTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_P', tooltipField: "VLY_P_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_$,
                    children: [
                        {
                            headerName: string.columnName.FORECAST_ACT, field: 'Forecast_A', tooltipField: "Forecast_A_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        // {
                        //     headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: "TGT_A_AB",
                        //     hide: avgfareTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: "VTG_A_AB",
                        //     cellRenderer: (params) => this.arrowIndicator(params), hide: avgfareTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: "VLY_A_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: string.columnName.FORECAST_ACT, field: 'Forecast_R', tooltipField: "Forecast_R_AB",
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        // {
                        //     headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: "TGT_R_AB", hide: revenueTGTVisible,
                        //     sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: "VTG_R_AB",
                        //     cellRenderer: (params) => this.arrowIndicator(params), hide: revenueTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: "VLY_R_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                // {
                //     headerName: string.columnName.MARKET_SHARE,
                //     children: [
                //         { headerName: string.columnName.MARKET_SHARE, field: 'Market Share', tooltipField: "Market Share_AB" },
                //         { headerName: string.columnName.VLY, field: 'VLY_MS', tooltipField: "VLY_MS_AB", cellRenderer: (params) => this.arrowIndicator(params) },
                //         { headerName: string.columnName.SA_SHARE, field: 'SA Share', tooltipField: "SA Share_AB" },
                //         { headerName: string.columnName.VLY, field: 'VLY_SA', tooltipField: "VLY_SA_AB", cellRenderer: (params) => this.arrowIndicator(params) }
                //     ]
                // }
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    [firstColumnName]: key[firstColumnName],
                    'CY_B': this.convertZeroValueToBlank(key.Bookings_CY),
                    'VLY_B': this.convertZeroValueToBlank(key.Bookings_VLY),
                    'TKT_B': this.convertZeroValueToBlank(key.Bookings_TKT),
                    'Forecast_P': this.convertZeroValueToBlank(key.Passenger_CY),
                    'TGT_P': this.convertZeroValueToBlank(key.Passenger_TGT),
                    'VTG_P': this.convertZeroValueToBlank(key.Passenger_VTG),
                    'VLY_P': this.convertZeroValueToBlank(key.Passenger_VLY),
                    'Forecast_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                    'TGT_A': this.convertZeroValueToBlank(key.AverageFare_TGT),
                    'VTG_A': this.convertZeroValueToBlank(key.AverageFare_VTG),
                    'VLY_A': this.convertZeroValueToBlank(key.AverageFare_VLY),
                    'Forecast_R': this.convertZeroValueToBlank(key.Revenue_CY),
                    'TGT_R': this.convertZeroValueToBlank(key.Revenue_TGT),
                    'VTG_R': this.convertZeroValueToBlank(key.Revenue_VTG),
                    'VLY_R': this.convertZeroValueToBlank(key.Revenue_VLY),
                    // 'Market Share': this.convertZeroValueToBlank(key.MarketShare_CY),
                    // 'VLY_MS': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    // 'SA Share': '---',
                    // 'VLY_SA': '---',
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY_B_AB': window.numberWithCommas(key.Bookings_VLY),
                    'TKT_B_AB': window.numberWithCommas(key.Bookings_TKT),
                    'Forecast_P_AB': window.numberWithCommas(key.Passenger_CY),
                    'TGT_P_AB': window.numberWithCommas(key.Passenger_TGT),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    'VLY_P_AB': window.numberWithCommas(key.Passenger_VLY),
                    'Forecast_A_AB': window.numberWithCommas(key.AverageFare_CY),
                    'TGT_A_AB': window.numberWithCommas(key.AverageFare_TGT),
                    'VTG_A_AB': window.numberWithCommas(key.AverageFare_VTG),
                    'VLY_A_AB': window.numberWithCommas(key.AverageFare_VLY),
                    'Forecast_R_AB': window.numberWithCommas(key.Revenue_CY),
                    'TGT_R_AB': window.numberWithCommas(key.Revenue_TGT),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_R_AB': window.numberWithCommas(key.Revenue_VLY),
                    // 'Market Share_AB': window.numberWithCommas(key.MarketShare_CY),
                    // 'VLY_MS_AB': window.numberWithCommas(key.MarketShare_VLY),
                    // 'SA Share_AB': '---',
                    // 'VLY_SA_AB': '---'
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Agents': 'Total',
                    'CY_B': this.convertZeroValueToBlank(key.Bookings_CY),
                    'VLY_B': this.convertZeroValueToBlank(key.Bookings_VLY),
                    'TKT_B': this.convertZeroValueToBlank(key.Bookings_TKT),
                    'Forecast_P': this.convertZeroValueToBlank(key.Passenger_CY),
                    'TGT_P': this.convertZeroValueToBlank(key.Passenger_TGT),
                    'VTG_P': this.convertZeroValueToBlank(key.Passenger_VTG),
                    'VLY_P': this.convertZeroValueToBlank(key.Passenger_VLY),
                    'Forecast_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                    'TGT_A': this.convertZeroValueToBlank(key.AverageFare_TGT),
                    'VTG_A': this.convertZeroValueToBlank(key.AverageFare_VTG),
                    'VLY_A': this.convertZeroValueToBlank(key.AverageFare_VLY),
                    'Forecast_R': this.convertZeroValueToBlank(key.Revenue_CY),
                    'TGT_R': this.convertZeroValueToBlank(key.Revenue_TGT),
                    'VTG_R': this.convertZeroValueToBlank(key.Revenue_VTG),
                    'VLY_R': this.convertZeroValueToBlank(key.Revenue_VLY),
                    // 'Market Share': this.convertZeroValueToBlank(key.MarketShare_CY),
                    // 'VLY_MS': this.convertZeroValueToBlank(key.MarketShare_VLY),
                    // 'SA Share': '---',
                    // 'VLY_SA': '---',
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY_B_AB': window.numberWithCommas(key.Bookings_VLY),
                    'TKT_B_AB': window.numberWithCommas(key.Bookings_TKT),
                    'Forecast_P_AB': window.numberWithCommas(key.Passenger_CY),
                    'TGT_P_AB': window.numberWithCommas(key.Passenger_TGT),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    'VLY_P_AB': window.numberWithCommas(key.Passenger_VLY),
                    'Forecast_A_AB': window.numberWithCommas(key.AverageFare_CY),
                    'TGT_A_AB': window.numberWithCommas(key.AverageFare_TGT),
                    'VTG_A_AB': window.numberWithCommas(key.AverageFare_VTG),
                    'VLY_A_AB': window.numberWithCommas(key.AverageFare_VLY),
                    'Forecast_R_AB': window.numberWithCommas(key.Revenue_CY),
                    'TGT_R_AB': window.numberWithCommas(key.Revenue_TGT),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_R_AB': window.numberWithCommas(key.Revenue_VLY),
                    // 'Market Share_AB': window.numberWithCommas(key.MarketShare_CY),
                    // 'VLY_MS_AB': window.numberWithCommas(key.MarketShare_VLY),
                    // 'SA Share_AB': '---',
                    // 'VLY_SA_AB': '---'
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return subChannelData;
    }

    //Segmentation
    getSegmentationData(endDate, startDate, regionId, countryId, cityId, getCabinValue) {
        const url = `${API_URL}/segmentationreport?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, 'Null')}`;

        var segmentationreport = axios.get(url, this.getDefaultHeader()).then((response) => {

            let avgfarezeroTGT = response.data.response.filter((d) => d.AverageFare_TGT === 0 || d.AverageFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.data.response.length

            let revenuzeroTGT = response.data.response.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.response.length

            let passengerzeroTGT = response.data.response.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.data.response.length

            var columnName = [
                {
                    headerName: '',
                    children: [
                        { headerName: 'Segment', field: 'Segment', tooltipField: "Segment", width: 250, alignLeft: true, underline: true }
                    ]
                },
                {
                    headerName: string.columnName.PASSENGER,
                    children: [
                        {
                            headerName: 'Actual', field: 'Forecast_P', tooltipField: "Forecast_P_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_P', tooltipField: "TGT_P_AB",
                            hide: passengerTGTVisible, sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_P', tooltipField: "VTG_P_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), hide: passengerTGTVisible, sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_P', tooltipField: "VLY_P_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_$,
                    children: [
                        {
                            headerName: 'Actual', field: 'Forecast_A', tooltipField: "Forecast_A_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: "TGT_A_AB",
                            hide: avgfareTGTVisible, sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: "VTG_A_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), hide: avgfareTGTVisible, sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: "VLY_A_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: 'Actual', field: 'Forecast_R', tooltipField: "Forecast_R_AB",
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        {
                            headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: "TGT_R_AB",
                            hide: revenueTGTVisible, sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: "VTG_R_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), hide: revenueTGTVisible, sortable: true, comparator: this.customSorting
                        },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: "VLY_R_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                }
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'Segment': key.Segment,
                    'Forecast_P': this.convertZeroValueToBlank(key.Passenger_CY),
                    'TGT_P': this.convertZeroValueToBlank(key.Passenger_TGT),
                    'VTG_P': this.convertZeroValueToBlank(key.Passenger_VTG),
                    'VLY_P': this.convertZeroValueToBlank(key.Passenger_VLY),
                    'Forecast_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                    'TGT_A': this.convertZeroValueToBlank(key.AverageFare_TGT),
                    'VTG_A': this.convertZeroValueToBlank(key.AverageFare_VTG),
                    'VLY_A': this.convertZeroValueToBlank(key.AverageFare_VLY),
                    'Forecast_R': this.convertZeroValueToBlank(key.Revenue_CY),
                    'TGT_R': this.convertZeroValueToBlank(key.Revenue_TGT),
                    'VTG_R': this.convertZeroValueToBlank(key.Revenue_VTG),
                    'VLY_R': this.convertZeroValueToBlank(key.Revenue_VLY),
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY_B_AB': window.numberWithCommas(key.Bookings_VLY),
                    'TKT_B_AB': window.numberWithCommas(key.Bookings_TKT),
                    'Forecast_P_AB': window.numberWithCommas(key.Passenger_CY),
                    'TGT_P_AB': window.numberWithCommas(key.Passenger_TGT),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    'VLY_P_AB': window.numberWithCommas(key.Passenger_VLY),
                    'Forecast_A_AB': window.numberWithCommas(key.AverageFare_CY),
                    'TGT_A_AB': window.numberWithCommas(key.AverageFare_TGT),
                    'VTG_A_AB': window.numberWithCommas(key.AverageFare_VTG),
                    'VLY_A_AB': window.numberWithCommas(key.AverageFare_VLY),
                    'Forecast_R_AB': window.numberWithCommas(key.Revenue_CY),
                    'TGT_R_AB': window.numberWithCommas(key.Revenue_TGT),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_R_AB': window.numberWithCommas(key.Revenue_VLY)
                });
            });

            var totalData = [];
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'Segment': 'Total',
                    'Forecast_P': this.convertZeroValueToBlank(key.Passenger_CY),
                    'TGT_P': this.convertZeroValueToBlank(key.Passenger_TGT),
                    'VTG_P': this.convertZeroValueToBlank(key.Passenger_VTG),
                    'VLY_P': this.convertZeroValueToBlank(key.Passenger_VLY),
                    'Forecast_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                    'TGT_A': this.convertZeroValueToBlank(key.AverageFare_TGT),
                    'VTG_A': this.convertZeroValueToBlank(key.AverageFare_VTG),
                    'VLY_A': this.convertZeroValueToBlank(key.AverageFare_VLY),
                    'Forecast_R': this.convertZeroValueToBlank(key.Revenue_CY),
                    'TGT_R': this.convertZeroValueToBlank(key.Revenue_TGT),
                    'VTG_R': this.convertZeroValueToBlank(key.Revenue_VTG),
                    'VLY_R': this.convertZeroValueToBlank(key.Revenue_VLY),
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY_B_AB': window.numberWithCommas(key.Bookings_VLY),
                    'TKT_B_AB': window.numberWithCommas(key.Bookings_TKT),
                    'Forecast_P_AB': window.numberWithCommas(key.Passenger_CY),
                    'TGT_P_AB': window.numberWithCommas(key.Passenger_TGT),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    'VLY_P_AB': window.numberWithCommas(key.Passenger_VLY),
                    'Forecast_A_AB': window.numberWithCommas(key.AverageFare_CY),
                    'TGT_A_AB': window.numberWithCommas(key.AverageFare_TGT),
                    'VTG_A_AB': window.numberWithCommas(key.AverageFare_VTG),
                    'VLY_A_AB': window.numberWithCommas(key.AverageFare_VLY),
                    'Forecast_R_AB': window.numberWithCommas(key.Revenue_CY),
                    'TGT_R_AB': window.numberWithCommas(key.Revenue_TGT),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_R_AB': window.numberWithCommas(key.Revenue_VLY)
                });
            });

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return segmentationreport;
    }

    getSubSegmentationData(endDate, startDate, regionId, countryId, cityId, getCabinValue, segmentValue) {
        const url = `${API_URL}/segmentationreport?endDate=${endDate}&startDate=${startDate}&${FilterParams(regionId, countryId, cityId, 'Null')}&segmentValue=${encodeURIComponent(segmentValue)}`;

        var subsegmentationreport = axios.get(url, this.getDefaultHeader()).then((response) => {

            let avgfarezeroTGT = response.data.response.filter((d) => d.AverageFare_TGT === 0 || d.AverageFare_TGT === null)
            let avgfareTGTVisible = avgfarezeroTGT.length === response.data.response.length

            let revenuzeroTGT = response.data.response.filter((d) => d.Revenue_TGT === 0 || d.Revenue_TGT === null)
            let revenueTGTVisible = revenuzeroTGT.length === response.data.response.length

            let passengerzeroTGT = response.data.response.filter((d) => d.Passenger_TGT === 0 || d.Passenger_TGT === null)
            let passengerTGTVisible = passengerzeroTGT.length === response.data.response.length

            var columnName = [
                {
                    headerName: '',
                    children: [
                        { headerName: string.columnName.OD, field: 'OD', tooltipField: "OD", width: 250, alignLeft: true }
                    ]
                },
                {
                    headerName: string.columnName.PASSENGER,
                    children: [
                        {
                            headerName: 'Actual', field: 'Forecast_P', tooltipField: "Forecast_P_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        // {
                        //     headerName: string.columnName.TGT, field: 'TGT_P', tooltipField: "TGT_P_AB",
                        //     hide: passengerTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_P', tooltipField: "VTG_P_AB",
                        //     cellRenderer: (params) => this.arrowIndicator(params), hide: passengerTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_P', tooltipField: "VLY_P_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.AVERAGE_FARE_$,
                    children: [
                        {
                            headerName: 'Actual', field: 'Forecast_A', tooltipField: "Forecast_A_AB",
                            sortable: true, comparator: this.customSorting
                        },
                        // {
                        //     headerName: string.columnName.TGT, field: 'TGT_A', tooltipField: "TGT_A_AB",
                        //     hide: avgfareTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_A', tooltipField: "VTG_A_AB",
                        //     cellRenderer: (params) => this.arrowIndicator(params), hide: avgfareTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: "VLY_A_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                },
                {
                    headerName: string.columnName.REVENUE_$,
                    children: [
                        {
                            headerName: 'Actual', field: 'Forecast_R', tooltipField: "Forecast_R_AB",
                            sortable: true, comparator: this.customSorting, sort: 'desc'
                        },
                        // {
                        //     headerName: string.columnName.TGT, field: 'TGT_R', tooltipField: "TGT_R_AB",
                        //     hide: revenueTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        // {
                        //     headerName: string.columnName.VTG, field: 'VTG_R', tooltipField: "VTG_R_AB",
                        //     cellRenderer: (params) => this.arrowIndicator(params), hide: revenueTGTVisible, sortable: true, comparator: this.customSorting
                        // },
                        {
                            headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: "VLY_R_AB",
                            cellRenderer: (params) => this.arrowIndicator(params), sortable: true, comparator: this.customSorting
                        }
                    ]
                }
            ]

            var rowData = [];
            response.data.response[0].TableData.forEach((key) => {
                rowData.push({
                    'OD': key.CommonOD,
                    'Forecast_P': this.convertZeroValueToBlank(key.Passenger_CY),
                    'TGT_P': this.convertZeroValueToBlank(key.Passenger_TGT),
                    'VTG_P': this.convertZeroValueToBlank(key.Passenger_VTG),
                    'VLY_P': this.convertZeroValueToBlank(key.Passenger_VLY),
                    'Forecast_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                    'TGT_A': this.convertZeroValueToBlank(key.AverageFare_TGT),
                    'VTG_A': this.convertZeroValueToBlank(key.AverageFare_VTG),
                    'VLY_A': this.convertZeroValueToBlank(key.AverageFare_VLY),
                    'Forecast_R': this.convertZeroValueToBlank(key.Revenue_CY),
                    'TGT_R': this.convertZeroValueToBlank(key.Revenue_TGT),
                    'VTG_R': this.convertZeroValueToBlank(key.Revenue_VTG),
                    'VLY_R': this.convertZeroValueToBlank(key.Revenue_VLY),
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY_B_AB': window.numberWithCommas(key.Bookings_VLY),
                    'TKT_B_AB': window.numberWithCommas(key.Bookings_TKT),
                    'Forecast_P_AB': window.numberWithCommas(key.Passenger_CY),
                    'TGT_P_AB': window.numberWithCommas(key.Passenger_TGT),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    'VLY_P_AB': window.numberWithCommas(key.Passenger_VLY),
                    'Forecast_A_AB': window.numberWithCommas(key.AverageFare_CY),
                    'TGT_A_AB': window.numberWithCommas(key.AverageFare_TGT),
                    'VTG_A_AB': window.numberWithCommas(key.AverageFare_VTG),
                    'VLY_A_AB': window.numberWithCommas(key.AverageFare_VLY),
                    'Forecast_R_AB': window.numberWithCommas(key.Revenue_CY),
                    'TGT_R_AB': window.numberWithCommas(key.Revenue_TGT),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_R_AB': window.numberWithCommas(key.Revenue_VLY)
                });
            });

            var totalData = []
            response.data.response[0].Total.forEach((key) => {
                totalData.push({
                    'OD': 'Total',
                    'Forecast_P': this.convertZeroValueToBlank(key.Passenger_CY),
                    'TGT_P': this.convertZeroValueToBlank(key.Passenger_TGT),
                    'VTG_P': this.convertZeroValueToBlank(key.Passenger_VTG),
                    'VLY_P': this.convertZeroValueToBlank(key.Passenger_VLY),
                    'Forecast_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                    'TGT_A': this.convertZeroValueToBlank(key.AverageFare_TGT),
                    'VTG_A': this.convertZeroValueToBlank(key.AverageFare_VTG),
                    'VLY_A': this.convertZeroValueToBlank(key.AverageFare_VLY),
                    'Forecast_R': this.convertZeroValueToBlank(key.Revenue_CY),
                    'TGT_R': this.convertZeroValueToBlank(key.Revenue_TGT),
                    'VTG_R': this.convertZeroValueToBlank(key.Revenue_VTG),
                    'VLY_R': this.convertZeroValueToBlank(key.Revenue_VLY),
                    'CY_B_AB': window.numberWithCommas(key.Bookings_CY),
                    'VLY_B_AB': window.numberWithCommas(key.Bookings_VLY),
                    'TKT_B_AB': window.numberWithCommas(key.Bookings_TKT),
                    'Forecast_P_AB': window.numberWithCommas(key.Passenger_CY),
                    'TGT_P_AB': window.numberWithCommas(key.Passenger_TGT),
                    'VTG_P_AB': window.numberWithCommas(key.Passenger_VTG),
                    'VLY_P_AB': window.numberWithCommas(key.Passenger_VLY),
                    'Forecast_A_AB': window.numberWithCommas(key.AverageFare_CY),
                    'TGT_A_AB': window.numberWithCommas(key.AverageFare_TGT),
                    'VTG_A_AB': window.numberWithCommas(key.AverageFare_VTG),
                    'VLY_A_AB': window.numberWithCommas(key.AverageFare_VLY),
                    'Forecast_R_AB': window.numberWithCommas(key.Revenue_CY),
                    'TGT_R_AB': window.numberWithCommas(key.Revenue_TGT),
                    'VTG_R_AB': window.numberWithCommas(key.Revenue_VTG),
                    'VLY_R_AB': window.numberWithCommas(key.Revenue_VLY)
                });
            })

            return [{
                "columnName": columnName,
                "rowData": rowData,
                'totalData': totalData
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return subsegmentationreport;
    }

    //RPS POS
    getRPSColumns(section, type, firstColumnName) {
        const userData = Constant.loggedinUser(JSON.parse(cookieStorage.getCookie('userDetails')))
        let cityId = window.localStorage.getItem('CitySelected')
        cityId = cityId && cityId !== 'Null' ? true : false
        const isEdit = (params) => {
            if (section === 'drilldown') {
                return false;
            } else {
                if (userData.canEditRPS) {
                    if (userData.isPOSCountryAdmin && params.data.drilldown_level === 'pos') {
                        if (params.data.version_action) {
                            return false;
                        } else if (params.data.Approval_level) {
                            return false;
                        } else if (userData.accessLevelPOS === params.data.Submit_level) {
                            return false;
                        } else if (params.data.Reject_level) {
                            return true;
                        } else {
                            return true;
                        }
                    } else if (userData.isPOSNetworkAdmin && params.data.drilldown_level === 'network') {
                        if (params.data.version_action === 'Unfreeze') {
                            return true;
                        } else if (userData.accessLevelPOS - 1 === parseInt(params.data.Approval_level)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }

        const EDIT = section === 'drilldown' || section === 'route' ? 'Edited' : 'Edit';

        const isSortable = section === 'drilldown' ? true : false
        const customSorting = section === 'drilldown' ? this.customSorting : null

        var columnName = [
            {
                headerName: '',
                children: section === 'total' ? [{ headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Month', width: 200, alignLeft: true }]
                    : section === 'drilldown' ?
                        [{
                            headerName: firstColumnName, field: 'firstColumnName', tooltipField: 'firstColumnName', alignLeft: true,
                            underline: type === 'Null' && firstColumnName !== 'O&D' && firstColumnName !== 'Aircraft' ? true : false, width: 200
                        }]
                        : [{
                            headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Comment', width: 200, alignLeft: true, underline: true,
                            checkboxSelection: (params) => {
                                if (userData.isPOSNetworkAdmin) {
                                    if (userData.accessLevelPOS - 1 === parseInt(params.data.Approval_level)) {
                                        return true
                                    }
                                } else if (userData.isPOSRegionAdmin) {
                                    if (userData.accessLevelPOS - 1 === parseInt(params.data.Submit_level)) {
                                        return true
                                    }
                                } else {
                                    return false
                                }
                            },
                        }]
            },
            {
                headerName: string.columnName.PASSENGER_OD,
                children: [
                    { headerName: 'Forecast', field: 'FRCT_P', tooltipField: 'FRCT_P_AB', width: 250, sortable: isSortable, comparator: customSorting },
                    { headerName: EDIT, field: 'Edit_P', tooltipField: 'Edit_P_AB', editable: (params) => isEdit(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'VLY_P', tooltipField: 'VLY_P_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }]
            },
            {
                headerName: string.columnName.AVERAGE_FARE_$,
                children: [
                    { headerName: 'Forecast', field: 'FRCT_A', tooltipField: 'FRCT_A_AB', width: 250, sortable: isSortable, comparator: customSorting },
                    { headerName: EDIT, field: 'Edit_A', tooltipField: 'Edit_A_AB', editable: (params) => isEdit(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'VLY_A', tooltipField: 'VLY_A_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: string.columnName.REVENUE_$,
                children: [
                    { headerName: 'Forecast', field: 'FRCT_R', tooltipField: 'FRCT_R_AB', width: 250, sortable: isSortable, comparator: customSorting, sort: section === 'drilldown' ? 'desc' : '' },
                    { headerName: string.columnName.VLY, field: 'VLY_R', tooltipField: 'VLY_R_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
        ]

        return columnName;
    }

    getRPSData(data, section, drilldown_level) {
        const rowData = []
        data.map((key, i) => {
            rowData.push({
                'Month': section === 'total' ? 'Total' : key.MonthName,
                'firstColumnName': section === 'total' ? 'Total' : key.ColumnName,
                // "CY_C": this.convertZeroValueToBlank(34455533),
                // "LY_C": this.convertZeroValueToBlank(34455533),
                'FRCT_P': this.convertZeroValueToBlank(key.Passenger_CY),
                'Edit_P': window.numberWithCommas(key.Passenger_EDIT, 2),
                "VLY_P": this.convertZeroValueToBlank(key.Passenger_VLY),
                'FRCT_A': this.convertZeroValueToBlank(key.AverageFare_CY),
                'Edit_A': window.numberWithCommas(key.AverageFare_EDIT, 2),
                "VLY_A": this.convertZeroValueToBlank(key.AverageFare_VLY),
                'FRCT_R': this.convertZeroValueToBlank(key.Revenue_CY),
                "VLY_R": this.convertZeroValueToBlank(key.Revenue_VLY),
                // 'LY_AL': this.convertZeroValueToBlank(key.Revenue_VLY),
                // "CY_C_AB": window.numberWithCommas(34455533),
                // "LY_C_AB": window.numberWithCommas(34455533),
                'FRCT_P_AB': window.numberWithCommas(key.Passenger_CY),
                'Edit_P_AB': window.numberWithCommas(key.Passenger_EDIT),
                "VLY_P_AB": window.numberWithCommas(key.Passenger_VLY),
                'FRCT_A_AB': window.numberWithCommas(key.AverageFare_CY),
                'Edit_A_AB': window.numberWithCommas(key.AverageFare_EDIT),
                "VLY_A_AB": window.numberWithCommas(key.AverageFare_VLY),
                'FRCT_R_AB': window.numberWithCommas(key.Revenue_CY),
                "VLY_R_AB": window.numberWithCommas(key.Revenue_VLY),
                // 'LY_AL_AB': window.numberWithCommas(key.Revenue_VLY),
                'Year': key.Year,
                'MonthName': key.Monthfullname,
                "Action": key.Action,
                "Submit_level": key.Submit_level ? parseInt(key.Submit_level) : key.Submit_level,
                "Approval_level": key.Approval_level ? parseInt(key.Approval_level) : key.Approval_level,
                "Reject_level": key.Reject_level ? parseInt(key.Reject_level) : key.Reject_level,
                'drilldown_level': drilldown_level,
                'version_action': key.version_action
            })
        })
        return rowData;
    }

    getRPSMonthTables(regionId, countryId, cityId, commonOD, getCabinValue, selectedVersion) {

        const url = `${API_URL}/rpsmonthly?${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}&version=${selectedVersion}`;
        var result = axios.get(url, this.getDefaultHeader()).then((response) => {
            const userData = Constant.loggedinUser(JSON.parse(cookieStorage.getCookie('userDetails')))
            const responseData = response.data.response;
            let isActionPerformed = false;
            let isAllApproved = false;
            let count = 0;
            responseData.TableData.forEach((d) => {
                if (!isActionPerformed) {
                    if (userData.isPOSNetworkAdmin) {
                        if (userData.accessLevelPOS - 1 === parseInt(d.Approval_level)) {
                            isActionPerformed = true
                        }
                    } else if (userData.isPOSRegionAdmin) {
                        if (userData.accessLevelPOS - 1 === parseInt(d.Submit_level)) {
                            isActionPerformed = true
                        }
                    }
                }
                if (parseInt(d.Approval_level) === 3) {
                    count = count + 1
                }
            })
            isAllApproved = count === responseData.TableData.length ? true : false

            return [{
                "columnName": this.getRPSColumns(),
                "rowData": this.getRPSData(responseData.TableData, '', Constant.DrillDownLevel(regionId, countryId, cityId)),
                'totalData': this.getRPSData(responseData.Total, 'total'),
                'columnNameTotal': this.getRPSColumns('total'),
                'apiMonthlyData': responseData,
                'isActionPerformed': isActionPerformed,
                'isAllApproved': isAllApproved
                // "currentAccess": response.data.CurretAccess
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error)
            });

        return result;
    }

    getRPSDrillDown(getYear, gettingMonth, regionId, countryId, cityId, commonOD, getCabinValue, type, selectedVersion) {

        const url = `${API_URL}/rpsdrilldown?getYear=${getYear}&gettingMonth=${gettingMonth}&${Params(regionId, countryId, cityId, getCabinValue)}&commonOD=${encodeURIComponent(commonOD)}&type=${type}&version=${selectedVersion}`;
        var result = axios.get(url, this.getDefaultHeader()).then((response) => {
            const responseData = response.data.response;
            const firstColumnName = responseData.ColumName;
            return [{
                "columnName": this.getRPSColumns('drilldown', type, firstColumnName),
                "rowData": this.getRPSData(responseData.TableData),
                'totalData': this.getRPSData(responseData.Total, 'total'),
                "currentAccess": responseData.CurrentAccess,
                'tabName': responseData.ColumName,
                'firstTabName': responseData.first_ColumName,
                'apiDrilldownData': responseData,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error)
            });

        return result;
    }

    //RPS Route
    getRPSRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, version) {

        const url = `${API_URL}/rpsmonthlyroute?selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&version=${version}`;
        var routemonthtable = axios.get(url, this.getDefaultHeader()).then((response) => {
            const responseData = response.data.response;
            return [{
                "columnName": this.getRPSColumns('route'),
                "rowData": this.getRPSData(responseData.TableData),
                'totalData': this.getRPSData(responseData.Total, 'total'),
                "currentAccess": response.data.CurretAccess
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log('error', error)
            });

        return routemonthtable;
    }

    getRPSRouteDrillDownData(getYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type, version) {
        const url = `${API_URL}/rpsdrilldownroute?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&type=${type}&version=${version}`;

        var routeRegionTable = axios.get(url, this.getDefaultHeader()).then((response) => {
            const responseData = response.data.response;
            const firstColumnName = responseData.ColumName;
            return [{
                "columnName": this.getRPSColumns('drilldown', type, firstColumnName),
                "rowData": this.getRPSData(responseData.TableData),
                'totalData': this.getRPSData(responseData.Total, 'total'),
                "currentAccess": responseData.CurrentAccess,
                'tabName': responseData.ColumName,
                'firstTabName': responseData.first_ColumName,
            }]; // the response.data is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return routeRegionTable;
    }


    //Route Profitability Solutions 
    getRPSolutionColumn(section, type, firstColumnName) {
        const isSortable = section === 'drilldown' ? true : false
        const customSorting = section === 'drilldown' ? this.customSorting : null
        var columnName = [
            {
                headerName: '',
                children: section === 'drilldown' ?
                    [{
                        headerName: firstColumnName, field: 'firstColumnName', tooltipField: 'Product_AB', alignLeft: true,
                        underline: type === 'Null' && firstColumnName !== 'Service Type' ? true : false, width: 260
                    }]
                    :
                    [{ headerName: string.columnName.MONTH, field: 'Month', tooltipField: 'Month', alignLeft: true, underline: true, width: 260 }]
            },
            {
                headerName: "VC Contribution", headerTooltip: "VC Contribution",
                children: [
                    { headerName: string.columnName.CY, field: 'VCC_CY', tooltipField: 'VCC_CY_AB', underline: section === 'drilldown' ? false : true, sortable: isSortable, comparator: customSorting, sort: section === 'drilldown' ? 'desc' : '' },
                    { headerName: string.columnName.VLY, field: 'VCC_VLY', tooltipField: 'VCC_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'VCC_VTG', tooltipField: 'VCC_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: "DOC Contribution", headerTooltip: "DOC Contribution",
                children: [
                    { headerName: string.columnName.CY, field: 'DOCC_CY', tooltipField: 'DOCC_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'DOCC_VLY', tooltipField: 'DOCC_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'DOCC_VTG', tooltipField: 'DOCC_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: "Total Cost", headerTooltip: "Total Cost",
                children: [
                    { headerName: string.columnName.CY, field: 'TC_CY', tooltipField: 'TC_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'TC_VLY', tooltipField: 'TC_VLY_AB', cellRenderer: (params) => this.costArrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'TC_VTG', tooltipField: 'TC_VTG_AB', cellRenderer: (params) => this.costArrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: "Total Revenue", headerTooltip: "Total Revenue",
                children: [
                    { headerName: string.columnName.CY, field: 'R_CY', tooltipField: 'R_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'R_VLY', tooltipField: 'R_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'R_VTG', tooltipField: 'R_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: "Surplus/Deficit", headerTooltip: "Surplus/Deficit",
                children: [
                    { headerName: string.columnName.CY, field: 'SD_CY', tooltipField: 'SD_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'SD_VLY', tooltipField: 'SD_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'SD_VTG', tooltipField: 'SD_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: "RCTKM", headerTooltip: "RCTKM",
                children: [
                    { headerName: string.columnName.CY, field: 'RCKTM_CY', tooltipField: 'RCKTM_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'RCKTM_VLY', tooltipField: 'RCKTM_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'RCKTM_VTG', tooltipField: 'RCKTM_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: "CCTKM", headerTooltip: "CCTKM",
                children: [
                    { headerName: string.columnName.CY, field: 'CCKTM_CY', tooltipField: 'CCKTM_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'CCKTM_VLY', tooltipField: 'CCKTM_VLY_AB', cellRenderer: (params) => this.costArrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'CCKTM_VTG', tooltipField: 'CCKTM_VTG_AB', cellRenderer: (params) => this.costArrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },

            {
                headerName: "Rates", headerTooltip: "Rates",
                children: [
                    { headerName: string.columnName.CY, field: 'Y_CY', tooltipField: 'Y_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'Y_VLY', tooltipField: 'Y_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'Y_VTG', tooltipField: 'Y_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
            {
                headerName: 'Load Factor(%)', headerTooltip: 'Load Factor(%)',
                children: [
                    { headerName: string.columnName.CY, field: 'LF_CY', tooltipField: 'LF_CY_AB', sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VLY, field: 'LF_VLY', tooltipField: 'LF_VLY_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting },
                    { headerName: string.columnName.VTG, field: 'LF_VTG', tooltipField: 'LF_VTG_AB', cellRenderer: (params) => this.arrowIndicator(params), sortable: isSortable, comparator: customSorting }
                ]
            },
        ]
        return columnName
    }

    getRPSolutionData(data, section) {
        var rowData = [];

        data.forEach((key) => {
            rowData.push({
                "Month": section === 'total' ? 'Total' : section === 'dayLevel' ? key.day : key.MonthName,
                'firstColumnName': section === 'total' ? 'Total' : key.ColumnName,
                'Product_AB':key.ProductToolTip,
                'Day': section,
                "VCC_CY": this.convertZeroValueToBlank(key.CY_VCContribution),
                'VCC_VLY': this.convertZeroValueToBlank(key.VLY_VCContribution),
                'VCC_VTG': this.convertZeroValueToBlank(key.VTG_VCContribution),
                "DOCC_CY": this.convertZeroValueToBlank(key.CY_DOCContribution),
                'DOCC_VLY': this.convertZeroValueToBlank(key.VLY_DOCContribution),
                'DOCC_VTG': this.convertZeroValueToBlank(key.VTG_DOCContribution),
                "TC_CY": this.convertZeroValueToBlank(key.CY_TotalCost),
                'TC_VLY': this.convertZeroValueToBlank(key.VLY_TotalCost),
                'TC_VTG': this.convertZeroValueToBlank(key.VTG_TotalCost),
                'R_CY': this.convertZeroValueToBlank(key.CY_TotalRevenue),
                'R_VLY': this.convertZeroValueToBlank(key.VLY_TotalRevenue),
                'R_VTG': this.convertZeroValueToBlank(key.VTG_TotalRevenue),
                'SD_CY': this.convertZeroValueToBlank(key.CY_Surplus),
                'SD_VLY': this.convertZeroValueToBlank(key.VLY_Surplus),
                'SD_VTG': this.convertZeroValueToBlank(key.VTG_Surplus),
                'RCKTM_CY': this.convertZeroValueToBlank(key.CY_RCTKM),
                'RCKTM_VLY': this.convertZeroValueToBlank(key.VLY_RCTKM),
                'RCKTM_VTG': this.convertZeroValueToBlank(key.VTG_RCTKM),
                'CCKTM_CY': this.convertZeroValueToBlank(key.CY_CCTKM),
                'CCKTM_VLY': this.convertZeroValueToBlank(key.VLY_CCTKM),
                'CCKTM_VTG': this.convertZeroValueToBlank(key.VTG_CCTKM),
                'Y_CY': this.convertZeroValueToBlank(key.CY_Yeild),
                'Y_VLY': this.convertZeroValueToBlank(key.VLY_Yeild),
                'Y_VTG': this.convertZeroValueToBlank(key.VTG_Yeild),
                'LF_CY': this.convertZeroValueToBlank(key.CY_LoadFactor),
                'LF_VLY': this.convertZeroValueToBlank(key.VLY_LoadFactor),
                'LF_VTG': this.convertZeroValueToBlank(key.VTG_LoadFactor),

                "VCC_CY_AB": window.numberWithCommas(key.CY_VCContribution),
                'VCC_VLY_AB': window.numberWithCommas(key.VLY_VCContribution),
                'VCC_VTG_AB': window.numberWithCommas(key.VTG_VCContribution),
                "DOCC_CY_AB": window.numberWithCommas(key.CY_DOCContribution),
                'DOCC_VLY_AB': window.numberWithCommas(key.VLY_DOCContribution),
                'DOCC_VTG_AB': window.numberWithCommas(key.VTG_DOCContribution),
                "TC_CY_AB": window.numberWithCommas(key.CY_TotalCost),
                'TC_VLY_AB': window.numberWithCommas(key.VLY_TotalCost),
                'TC_VTG_AB': window.numberWithCommas(key.VTG_TotalCost),
                'R_CY_AB': window.numberWithCommas(key.CY_TotalRevenue),
                'R_VLY_AB': window.numberWithCommas(key.VLY_TotalRevenue),
                'R_VTG_AB': window.numberWithCommas(key.VTG_TotalRevenue),
                'SD_CY_AB': window.numberWithCommas(key.CY_Surplus),
                'SD_VLY_AB': window.numberWithCommas(key.VLY_Surplus),
                'SD_VTG_AB': window.numberWithCommas(key.VTG_Surplus),
                'RCKTM_CY_AB': window.numberWithCommas(key.CY_RCTKM),
                'RCKTM_VLY_AB': window.numberWithCommas(key.VLY_RCTKM),
                'RCKTM_VTG_AB': window.numberWithCommas(key.VTG_RCTKM),
                'CCKTM_CY_AB': window.numberWithCommas(key.CY_CCTKM),
                'CCKTM_VLY_AB': window.numberWithCommas(key.VLY_CCTKM),
                'CCKTM_VTG_AB': window.numberWithCommas(key.VTG_CCTKM),
                'Y_CY_AB': window.numberWithCommas(key.CY_Yeild),
                'Y_VLY_AB': window.numberWithCommas(key.VLY_Yeild),
                'Y_VTG_AB': window.numberWithCommas(key.VTG_Yeild),
                'LF_CY_AB': window.numberWithCommas(key.CY_LoadFactor),
                'LF_VLY_AB': window.numberWithCommas(key.VLY_LoadFactor),
                'LF_VTG_AB': window.numberWithCommas(key.VTG_LoadFactor),
                'Year': key.Year,
                'MonthName': key.monthfullname
            });
        });
        return rowData;
    }

    getRouteProfitabilitySolutionMonthlyData(currency, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, getCabinValue) {

        // const url = `${API_URL}/routeprofitabilitymonthly?selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}`;
        const url = `${API_URL}`;
        var result = axios.get(url, this.getDefaultHeader()).then((data) => {

            let response = '';

            if(regionId=='*'){
                response = routeprofitabilitymonthly
            }else if(countryId=='*'){
                response = routeProfitabilityDataCountryDrillDown
            }else if(routeId == '*'){
                response = routeProfitabilityDataRouteDrillDown;
            }else if(leg == '*'){
                response = routeProfitabilityDataLegDrillDown
            }else if(flight == "*"){
                response = routeProfitabilityDataFlightDrillDown
            }else if(aircraftType == "*"){
                response = routeProfitabilityDataAircraftDrillDown
            }else{
                response = routeProfitabilityDataAircraftDrillDown
            }


            const responseData = response.data.response;
            return [{
                "columnName": this.getRPSolutionColumn(),
                "rowData": this.getRPSolutionData(responseData.TableData),
                "currentAccess": responseData.CurrentAccess,
                'totalData': this.getRPSolutionData(responseData.Total, 'total')
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log('error', error)
            });

        return result;
    }

    getRouteProfitabilitySolutionDayData(currency, getYear, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, getCabinValue) {

        // const url = `${API_URL}/rrmonthlydropDown?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}`;
        const url = `${API_URL}`;
        var result = axios.get(url, this.getDefaultHeader()).then((response) => {

            const responseData = rrmonthlydropDown;

            return [{
                "rowData": this.getRPSolutionData(responseData.TableData, 'dayLevel'),
            }]; // the response.data is string of src
        })
            .catch((error) => {
                console.log('error', error)
            });

        return result;
    }

    getRouteProfitabilitySolutionDrillDownData(getYear, currency, gettingMonth, gettingDay, routeGroup, regionId, countryId, routeId, leg, flight, aircraftType, getCabinValue, type) {
        let typeStr = type
        if (type == 'Service Type') {
            typeStr = 'svcType';
        }
        // const url = `${API_URL}/routeprofitabilitydrilldown?getYear=${getYear}&gettingMonth=${gettingMonth}&getDay=${gettingDay}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&selectedLeg=${encodeURIComponent(leg)}&flight=${String.removeQuotes(flight)}&getAircraft=${String.removeQuotes(aircraftType)}&type=${typeStr}`;
        const url = `${API_URL}`;
        var result = axios.get(url, this.getDefaultHeader()).then((data) => {

            let response = ''
            if(regionId=='*' && type=='Null'){
                response = routeProfitabilityRegionDrillDown
            }else if(countryId=='*' && type=='Null'){
                response = routeProfitabilityCountryDrillDown
            }else if(routeId=='*' && type=='Null'){
                response = routeProfitabilityRouteDrillDown
            }else if(leg=='*' && type=='Null'){
                response = routeProfitabilityLegDrillDown
            }else if(flight=='*' && type=='Null'){
                response = routeProfitabilityFlightDrillDown
            }else if(aircraftType=='*' && type=='Null'){
                response = routeProfitabilityAircraftDrillDown
            }else if(type != 'Null'){
                if(type == 'Route'){
                    response = routeProfitabilityRouteNonDrillDown
                }else if(type == 'Flights'){
                    response = routeProfitabilityFlightNonDrillDown
                }else if(type == 'Aircraft'){
                    response = routeProfitabilityAircraftNonDrillDown
                }else if(type == 'Service Type'){
                    response = routeProfitabilityServiceNonDrillDown
                }
            }
            else{
                    response = routeProfitabilityServiceDrillDown
                }
            

            const responseData = response.data.response
            const firstColumnName = responseData.ColumnName;
            const Product_AB = responseData.ProductToolTip

            return [{
                "columnName": this.getRPSolutionColumn('drilldown', type, firstColumnName),
                "rowData": this.getRPSolutionData(responseData.TableData),
                "currentAccess": responseData.CurrentAccess,
                'tabName': responseData.ColumnName,
                'Product_AB':responseData.ProductToolTip,
                'firstTabName': responseData.first_ColumnName,
                'totalData': this.getRPSolutionData(responseData.Total, 'total')
            }]; // the responseData is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return result;
    }

    exportCSVRouteProfitabilitySolutionMonthlyURL(routeGroup, regionId, countryId, routeId, flight, getCabinValue) {
        const url = `routeprofitabilitymonthly?selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&flight=${String.removeQuotes(flight)}`;
        return url;
    }

    exportCSVRouteProfitabilitySolutionDrillDownURL(getYear, gettingMonth, routeGroup, regionId, countryId, routeId, flight, getCabinValue, type) {
        const url = `routeprofitabilitydrilldown?getYear=${getYear}&gettingMonth=${gettingMonth}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&flight=${String.removeQuotes(flight)}&type=${type}`;
        return url;
    }

    //Route Profitability Consolidate
    getRPCColumns() {

        const arrowIndicator = (params) => {
            if (params.data.cost === 'cost') {
                return this.costArrowIndicator(params)
            } else if (params.data.cost === 'commercial_cost') {
                if ((params.data.Name).toLowerCase().includes('cask')) {
                    return this.costArrowIndicator(params)
                } else {
                    return this.arrowIndicator(params)
                }
            } else {
                return this.arrowIndicator(params)
            }
        }

        var columnName = [
            {
                headerName: '',
                children: [{ headerName: ' ', field: 'Name', tooltipField: 'Product_AB', alignLeft: true }]
            },
            {
                headerName: 'Daily', headerTooltip: 'Daily',
                children: [
                    { headerName: string.columnName.ACTUAL, field: 'ACTUAL_D', tooltipField: 'ACTUAL_D_AB' },
                    { headerName: "Budget", field: 'Budget_D', tooltipField: 'Budget_D_AB' },
                    { headerName: "Last Year", field: 'Last_Year_D', tooltipField: 'Last_Year_D_AB' },
                    { headerName: 'VBGT', field: 'VAR_BUDGET_D', tooltipField: 'VAR_BUDGET_D_AB', cellRenderer: (params) => arrowIndicator(params) },
                    { headerName: 'VLY', field: 'VAR_LAST_D', tooltipField: 'VAR_LAST_D_AB', cellRenderer: (params) => arrowIndicator(params) }
                ]
            },
            {
                headerName: 'Month to Date', headerTooltip: 'Month to Date',
                children: [
                    { headerName: string.columnName.ACTUAL, field: 'ACTUAL_M', tooltipField: 'ACTUAL_M_AB' },
                    { headerName: "Budget", field: 'Budget_M', tooltipField: 'Budget_M_AB' },
                    { headerName: "Last Year", field: 'Last_Year_M', tooltipField: 'Last_Year_M_AB' },
                    { headerName: 'VBGT', field: 'VAR_BUDGET_M', tooltipField: 'VAR_BUDGET_M_AB', cellRenderer: (params) => arrowIndicator(params) },
                    { headerName: 'VLY', field: 'VAR_LAST_M', tooltipField: 'VAR_LAST_M_AB', cellRenderer: (params) => arrowIndicator(params) }
                ]
            },
            {
                headerName: 'Year To Date', headerTooltip: 'Year To Date',
                children: [
                    { headerName: string.columnName.ACTUAL, field: 'ACTUAL_Y', tooltipField: 'ACTUAL_Y_AB' },
                    { headerName: "Budget", field: 'Budget_Y', tooltipField: 'Budget_Y_AB' },
                    { headerName: "Last Year", field: 'Last_Year_Y', tooltipField: 'Last_Year_Y_AB' },
                    { headerName: 'VBGT', field: 'VAR_BUDGET_Y', tooltipField: 'VAR_BUDGET_Y_AB', cellRenderer: (params) => arrowIndicator(params) },
                    { headerName: 'VLY', field: 'VAR_LAST_Y', tooltipField: 'VAR_LAST_Y_AB', cellRenderer: (params) => arrowIndicator(params) }
                ]
            },
        ]

        return columnName;
    }

    getRPCData(responseData, total, isCost) {
        const rowData = []
        responseData.map((key) => {
            rowData.push({
                'Name': total ? `Total ${total}` : key.Name,
                'Product_AB':window.numberWithCommas(key.ProductToolTip),
                "ACTUAL_D": this.convertZeroValueToBlank(key.CY_Daily),
                "Budget_D": this.convertZeroValueToBlank(key.TGT_Daily),
                "Last_Year_D": this.convertZeroValueToBlank(key.LY_Daily),
                "VAR_BUDGET_D": this.convertZeroValueToBlank(key.VTG_Daily),
                "VAR_LAST_D": this.convertZeroValueToBlank(key.VLY_Daily),
                "ACTUAL_M": this.convertZeroValueToBlank(key.CY_MTD),
                "Budget_M": this.convertZeroValueToBlank(key.TGT_MTD),
                "Last_Year_M": this.convertZeroValueToBlank(key.LY_MTD),
                "VAR_BUDGET_M": this.convertZeroValueToBlank(key.VTG_MTD),
                "VAR_LAST_M": this.convertZeroValueToBlank(key.VLY_MTD),
                "ACTUAL_Y": this.convertZeroValueToBlank(key.CY_YTD),
                "Budget_Y": this.convertZeroValueToBlank(key.TGT_YTD),
                "Last_Year_Y": this.convertZeroValueToBlank(key.LY_YTD),
                "VAR_BUDGET_Y": this.convertZeroValueToBlank(key.VTG_YTD),
                "VAR_LAST_Y": this.convertZeroValueToBlank(key.VLY_YTD),
                "ACTUAL_D_AB": window.numberWithCommas(key.CY_Daily),
                "Budget_D_AB": window.numberWithCommas(key.TGT_Daily),
                "Last_Year_D_AB": window.numberWithCommas(key.LY_Daily),
                "VAR_BUDGET_D_AB": window.numberWithCommas(key.VTG_Daily),
                "VAR_LAST_D_AB": window.numberWithCommas(key.VLY_Daily),
                "ACTUAL_M_AB": window.numberWithCommas(key.CY_MTD),
                "Budget_M_AB": window.numberWithCommas(key.TGT_MTD),
                "Last_Year_M_AB": window.numberWithCommas(key.LY_MTD),
                "VAR_BUDGET_M_AB": window.numberWithCommas(key.VTG_MTD),
                "VAR_LAST_M_AB": window.numberWithCommas(key.VLY_MTD),
                "ACTUAL_Y_AB": window.numberWithCommas(key.CY_YTD),
                "Budget_Y_AB": window.numberWithCommas(key.TGT_YTD),
                "Last_Year_Y_AB": window.numberWithCommas(key.LY_YTD),
                "VAR_BUDGET_Y_AB": window.numberWithCommas(key.VTG_YTD),
                "VAR_LAST_Y_AB": window.numberWithCommas(key.VLY_YTD),
                "cost": isCost ? isCost : null
            })
        })
        return rowData;
    }

    getRPCHeader() {
        const months = ["Passenger", "Hard Frieght", "Excess Baggage", "Express", "Mail", "Others"]
        const rowData = []
        months.map((key) => {
            rowData.push({
                'Name': key,
            })
        })
        return [{
            "columnName": this.getRPCColumns(),
            "rowData": rowData,
            // "currentAccess": response.data.CurretAccess
        }]; // the response.data is string of src 
    }

    getRPNonCommercialInfo(getYear, gettingMonth, gettingDay, routeGroup, regionId, countryId, routeId, flight, getCabinValue) {
        // const url = `${API_URL}/routeprofitabilitynoncommercialtable?getYear=${getYear}&gettingMonth=${gettingMonth}&getDay=${gettingDay}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&flight=${String.removeQuotes(flight)}`;
        const url = `${API_URL}`;
        var rpNonCommercial = axios.get(url, this.getDefaultHeader()).then((response) => {
            const rows = routeprofitabilitynoncommercialtable.data.response;
            const TableData = rows.TableData;

            return [{
                "vcContribution": this.getRPCData(rows.VC_Contribution),
                'docContribution': this.getRPCData(rows.DOC_Contribution),
                'revenue': this.getRPCData(TableData.Revenue),
                'revenueTotal': this.getRPCData(rows.Total_Revenue, 'Revenue'),
                'vc': this.getRPCData(TableData.VC, '', 'cost'),
                'vcTotal': this.getRPCData(rows.Total_VC, 'Variable Cost', 'cost'),
                'doc': this.getRPCData(TableData.DOC, '', 'cost'),
                'docTotal': this.getRPCData(rows.Total_DOC, 'Direct Fixed Cost', 'cost'),
                'totalOtherFixCost': this.getRPCData(rows.Total_Other_Fixed_Cost, '', 'cost'),
                'totalCost': this.getRPCData(rows.Total_Cost, '', 'cost'),
                'surplusDeficit': this.getRPCData(rows.surplus_Defecit),
                'noneRoute': this.getRPCData(rows.NonRoute),
                'NIAT': this.getRPCData(rows.NIAT)
            }]; // the responseData is string of src
        })

        return rpNonCommercial;
    }

    getRPCommercialInfo(getYear, gettingMonth, gettingDay, routeGroup, regionId, countryId, routeId, flight, getCabinValue) {

        // const url = `${API_URL}/routeprofitabilitycommercialtable?getYear=${getYear}&gettingMonth=${gettingMonth}&getDay=${gettingDay}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&flight=${String.removeQuotes(flight)}`;
        const url = `${API_URL}`;
        var rpCommercial = axios.get(url, this.getDefaultHeader()).then((response) => {

            const TableData = routeprofitabilitycommercialtable.data.response.TableData
            //const Total = [response.data.response.Total]

            return [{
                "rowData": this.getRPCData(TableData, '', 'commercial_cost'),
                //"total": this.getRPCData(Total, 'Commercial Info')
            }]; // the responseData is string of src
        })
        //.catch((error) => {
        //this.errorHandling(error);
        //});

        return rpCommercial;
    }

    getRPSegmentalHeader() {
        const months = ["Passenger", "Hard Frieght", "Excess Baggage", "Express", "Mail", "Others"]
        const rowData = []
        months.map((key) => {
            rowData.push({
                'Name': key,
            })
        })
        return [{
            "columnName": this.getRPSegmentColumns(),
            "rowData": rowData,
        }]; // the response.data is string of src 
    }

    getRPSegmentColumns() {
        var columnName = [
            {
                headerName: '',
                children: [{ headerName: ' ', field: 'Name', tooltipField: 'Name', alignLeft: true }]
            },
            {
                headerName: 'Segmental Forecast', headerTooltip: 'Segmental Forecast',
                children: [
                    { headerName: 'Freighter/Charter', field: 'Freighter_F', tooltipField: 'Freighter_F_AB' },
                    { headerName: "Belly", field: 'Belly_F', tooltipField: 'Freighter_F_AB' },
                    { headerName: "Warehouse & Property", field: 'Warehouse_F', tooltipField: 'Warehouse_F_AB' },
                    { headerName: 'Total', field: 'Total_F', tooltipField: 'Total_F_AB' }
                ]
            },
            {
                headerName: 'Segmental LY', headerTooltip: 'Segmental LY',
                children: [
                    { headerName: 'Freighter/Charter', field: 'Freighter_LY', tooltipField: 'Freighter_LY_AB' },
                    { headerName: "Belly", field: 'Belly_LY', tooltipField: 'Freighter_LY_AB' },
                    { headerName: "Warehouse & Property", field: 'Warehouse_LY', tooltipField: 'Warehouse_LY_AB' },
                    { headerName: 'Total', field: 'Total_LY', tooltipField: 'Total_LY_AB' }
                ]
            },
            {
                headerName: 'Segmental Budget', headerTooltip: 'Segmental Budget',
                children: [
                    { headerName: 'Freighter/Charter', field: 'Freighter_B', tooltipField: 'Freighter_B_AB' },
                    { headerName: "Belly", field: 'Belly_B', tooltipField: 'Freighter_B_AB' },
                    { headerName: "Warehouse & Property", field: 'Warehouse_B', tooltipField: 'Warehouse_B_AB' },
                    { headerName: 'Total', field: 'Total_B', tooltipField: 'Total_B_AB' }
                ]
            },
        ]

        return columnName;
    }


    getRPSegmentalData(responseData, total) {
        const rowData = []
        responseData.map((key) => {
            rowData.push({
                'Name': total ? `Total ${total}` : key.Name,
                "Freighter_F": this.convertZeroValueToBlank(key.CY_Freighter),
                "Belly_F": this.convertZeroValueToBlank(key.CY_Belly),
                "Warehouse_F": this.convertZeroValueToBlank(key.CY_WareHouseProperty),
                "Total_F": this.convertZeroValueToBlank(key.CY_Total),
                "Freighter_LY": this.convertZeroValueToBlank(key.LY_Freighter),
                "Belly_LY": this.convertZeroValueToBlank(key.LY_Belly),
                "Warehouse_LY": this.convertZeroValueToBlank(key.LY_WareHouseProperty),
                "Total_LY": this.convertZeroValueToBlank(key.LY_Total),
                "Freighter_B": this.convertZeroValueToBlank(key.TGT_Freighter),
                "Belly_B": this.convertZeroValueToBlank(key.TGT_Belly),
                "Warehouse_B": this.convertZeroValueToBlank(key.TGT_WareHouseProperty),
                "Total_B": this.convertZeroValueToBlank(key.TGT_Total),

                "Freighter_F_AB": window.numberWithCommas(key.CY_Freighter),
                "Belly_F_AB": window.numberWithCommas(key.CY_Belly),
                "Warehouse_F_AB": window.numberWithCommas(key.CY_WareHouseProperty),
                "Total_F_AB": window.numberWithCommas(key.CY_Total),
                "Freighter_LY_AB": window.numberWithCommas(key.LY_Freighter),
                "Belly_LY_AB": window.numberWithCommas(key.LY_Belly),
                "Warehouse_LY_AB": window.numberWithCommas(key.LY_WareHouseProperty),
                "Total_LY_AB": window.numberWithCommas(key.LY_Total),
                "Freighter_B_AB": window.numberWithCommas(key.TGT_Freighter),
                "Belly_B_AB": window.numberWithCommas(key.TGT_Belly),
                "Warehouse_B_AB": window.numberWithCommas(key.TGT_WareHouseProperty),
                "Total_B_AB": window.numberWithCommas(key.TGT_Total)
            })
        })
        return rowData;
    }
    getRPSegmentalInfo(getYear, gettingMonth, gettingDay, routeGroup, regionId, countryId, routeId, flight, getCabinValue, duration) {
        // const url = `${API_URL}/rr4thPageAPI?getYear=${getYear}&gettingMonth=${gettingMonth}&getDay=${gettingDay}&selectedRouteGroup=${routeGroup}&${ROUTEParams(regionId, countryId, routeId, getCabinValue)}&flight=${String.removeQuotes(flight)}&duration=${String.removeQuotes(duration)}`;
        const url = `${API_URL}`;
        var rpSegmentalData = axios.get(url, this.getDefaultHeader()).then((response) => {
            const TableData = rr4thPageAPI.data.response.freighterQuery

            return [{
                "rowData": this.getRPSegmentalData(TableData, '')
            }]; // the responseData is string of src
        })
            .catch((error) => {
                this.errorHandling(error);
            });

        return rpSegmentalData;
    }
}


