import React, { Component } from 'react';
import eventApi from '../API/eventApi';
import api from '../API/api'
import APIServices from '../API/apiservices'
import cookieStorage from '../Constants/cookie-storage';
import Access from '../Constants/accessValidation'
import Constants from '../Constants/validator'
import config from '../Constants/config';
import { images } from '../Constants/images';
import Sidebar from './Sidebar';
// import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import _, { result, includes } from 'lodash';
import './component.scss'
import RegionsDropDown from './RegionsDropDown';
import RouteRegionsDropDown from './RouteRegionsDropDown';
import { Link } from 'react-router-dom';


const apiServices = new APIServices();

class TopMenuBar extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      routeGroup: '',
      regionSelected: 'Null',
      countrySelected: 'Null',
      citySelected: 'Null',
      ODSelected: 'Null',
      routeSelected: '',
      routeService: '',
      menuData: [],
      startDate: '',
      endDate: '',
      gettingMonth: null,
      gettingYear: null,
      initial: '',
      toggleList: false,
      notification: false,
      alertData: [1],
      isNewAlert: false,
      loading: false,
      userDetails: [],
      dashboardList: [],
      selectedDashboardPath: '',
      posFiltersSearchURL: ''
    };
  }

  componentWillMount() {
    const self = this;
    let user = cookieStorage.getCookie('userDetails');
    user = user ? JSON.parse(user) : ''
    let menus = cookieStorage.getCookie('menuData');
    menus = menus ? JSON.parse(menus) : ''
    let dashboardList = menus.filter((d) => d.Name === 'Dashboard')
    let menusWithoutDashboard = menus.filter((d) => d.Name !== 'Dashboard')
    self.setState({
      userDetails: user,
      menuData: menusWithoutDashboard,
      dashboardList: dashboardList[0].submenu,
      selectedDashboardPath: window.location.pathname
    }, () => {
      self.getUserInitial();
      self.getAlertsForNotification(user)
    })
  }

  getAlertsForNotification(userData) {
    api.get(`getalertnotfication`, 'hideloader')
      .then((response) => {
        if (response && response.data.response.length > 0) {
          let data = response.data.response;
          const timeStamp = data[0].TimeStamp
          this.setState({ alertData: data })
          apiServices.getUserPreferences('Alert_timestamp', timeStamp.toString()).then((result) => {
            this.setState({ isNewAlert: result })
          });
        }
      })
      .catch((err) => {
        console.log("alert notification error", err);
      })
  }

  getUserInitial() {
    const userName = this.state.userDetails.username;
    const initial = userName.charAt(0).toUpperCase();
    this.setState({ initial })
  }

  getFilterValues = ($event) => {
    let posFiltersSearchURL = Constants.getPOSFiltersSearchURL()
    let dashboardList = this.state.dashboardList.filter((d) => {
      if ((d.Path).includes('forecastAccuracy')) {
        d.Path = `/forecastAccuracy${posFiltersSearchURL}`
      }
      if ((d.Path).includes('rpsPos')) {
        d.Path = `/rpsPos${posFiltersSearchURL}`
      }
      return d;
    })
    this.setState({
      regionSelected: $event.regionSelected,
      countrySelected: $event.countrySelected,
      citySelected: $event.citySelected,
      ODSelected: $event.ODSelected,
      startDate: $event.startDate,
      endDate: $event.endDate,
      gettingMonth: $event.gettingMonthA,
      gettingYear: $event.gettingYearA,
      posFiltersSearchURL: posFiltersSearchURL,
      dashboardList
    }, () => this.props.getPOSSelectedGeographicalDetails(this.state))
  }

  getRouteFilterValues = ($event) => {
    this.setState({
      routeGroup: $event.routeGroup,
      regionSelected: $event.regionSelected,
      countrySelected: $event.countrySelected,
      routeSelected: $event.routeSelected,
      routeService: $event.routeService,
      startDate: $event.startDate,
      endDate: $event.endDate,
      typeofCost: $event.typeofCost
    }, () => this.props.getSelectedGeographicalDetails(this.state))
  }


  handleDatePicker = (item) => {
    this.setState({ datePickerValue: [item.selection] })
  }

  dateSelected() {
    const datePickerValue = this.state.datePickerValue;
    const startDate = datePickerValue[0].startDate.toDateString();
    const endDate = datePickerValue[0].endDate.toDateString();
    this.setState({ showDatePicker: false, date: `${startDate} - ${endDate}` })
    let _ = this.props.getSelectedGeographicalDetails(this.state)
  }

  callDashboards = (e) => {
    this.props.history.push(`${e.target.value}`)
  }

  _logout = () => {
    var eventData = {
      event_id: "5",
      description: "User logged out from the system",
      where_path: "/",
      page_name: "Login Page"
    }
    eventApi.sendEvent(eventData);
    cookieStorage.deleteCookie();
  }

  toggleList() {
    this.setState({ toggleList: !this.state.toggleList, notification: false })
  }

  toggleNotification() {
    this.setState({ notification: !this.state.notification, toggleList: false, isNewAlert: false })
  }

  renderDashboardList = () => {
    let dashboardListItems = this.state.dashboardList.map((li) =>
      <option value={li.Path} selected={li.Path === this.state.selectedDashboardPath}>{li.Name}</option>
    );
    return (
      <ul className="nav navbar-nav navbar-left">
        <li style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '-2px' }}>
          <div className="" >
            <div className="form-group dashboard-select">
              <select className="form-control cabinselect dashboard-dropdown"
                onChange={(e) => this.callDashboards(e)} id="dashboardlist">
                {dashboardListItems}
              </select>
            </div>
          </div>
        </li>
      </ul>
    )
  }

  renderFilters = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>

        <li style={{ marginRight: '10px' }}>
          {this.props.routeDash ?
            <RouteRegionsDropDown
              getRouteFilterValues={this.getRouteFilterValues}
              dashboardName={this.props.dashboardName}
              {...this.props} /> :
            <RegionsDropDown
              dashboard={true}
              agentDashboard={this.props.agentDashboard}
              getFilterValues={this.getFilterValues}
              {...this.props} />}
        </li>

      </div>
    )
  }

  renderProfileDropdown = () => {
    return (
      <div className={`profile-dropdown`}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button>
            <ListItemIcon color="inherit">
              <PersonIcon />
            </ListItemIcon>
            <Link onClick={() => this.props.history.push("/userProfile")}>
              {"Profile"}
            </Link>
          </ListItem>
          <Divider />

          <ListItem button>
            <ListItemIcon color="inherit">
              <VpnKeyIcon />
            </ListItemIcon>
            <Link onClick={() => this.props.history.push("/changePassword")}>
              {"Change Password"}
            </Link>
          </ListItem>
          <Divider />

          <ListItem button>
            <ListItemIcon color="inherit">
              <LockIcon />
            </ListItemIcon>
            <a href={`${config.API_URL}/initiatelogout`} onClick={() => this._logout()}>{"Logout"}</a>
          </ListItem>

        </List>
      </div>
    )
  }

  renderNotificationDropdown = () => {
    let alertUrl = "https://www.figma.com/proto/Xm3eQbdvmkg1xFU4EqJqmv/ARMS?node-id=0%3A1&viewport=422%2C331%2C0.02&scaling=min-zoom&starting-point-node-id=1%3A2"
    return (
      <div className="notification-dropdown">
        <div className="notification-top">
          <div>
            <span className="count">6</span>New
            notifications
          </div>
          <i
            class="fa fa-close"
            aria-hidden="true"
            onClick={() => this.setState({ notification: false })}
          ></i>
        </div>
        <div className="notifications">
          {this.state.loading ? (
            <div className="ellipsis-loader">
              <img src={images.ellipsis_loader} alt="" />
            </div>
          ) : this.state.alertData.length > 0 ? (
            <div>
              {/* {this.state.alertData.map((data) => (
                <div className="notification">
                  <div className="heading">{`POS : ${data.POS}`}</div>
                  <div className="actions">
                    {`Infare rates are`}
                    <span
                      className={`${data.IStatus === "Above" ? "green" : "red"
                        }`}
                    >{` ${data.IStatus}`}</span>
                  </div>
                </div>
              ))} */}
              <div className="pos-images">
                {this.state.selectedDashboardPath==="/posDashboard" || this.state.selectedDashboardPath==="/pos"?
              //  <Link to="/pos">
              <a href ={alertUrl}>
                 <table className="table text-center">
                  <tr className="t-head">
                    <th >Month</th>
                    <th>KPI</th>
                    <th>Vs Tgt</th>
                    <th>Action</th>
                    <th>OD Exception's</th>
                  </tr>
                  <tr>
                    <td>Nov-21</td>
                    <td>Rev</td>
                    <td>-51.50%</td>
                    <td>Inventory</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>Nov-21</td>
                    <td>Rev</td>
                    <td>-51.50%</td>
                    <td>Pricing</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td>Nov-21</td>
                    <td>Rev</td>
                    <td>-51.50%</td>
                    <td>Sales</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>Dec-21</td>
                    <td>Rev</td>
                    <td>-41.11%</td>
                    <td>Inventory</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>Dec-21</td>
                    <td>Rev</td>
                    <td>-41.11%</td>
                    <td>Pricing</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td>Dec-21</td>
                    <td>Rev</td>
                    <td>-41.11%</td>
                    <td>Sales</td>
                    <td>35</td>
                  </tr>
                </table>
                </a>
                // </Link>
                : 
                this.state.selectedDashboardPath==="/routeDashboard" || this.state.selectedDashboardPath === "/route" ? 
                // <Link to="/route">
                   <a href ={alertUrl}>
                  <table className="table text-center">
                  <tr className="t-head">
                    <th >Month</th>
                    <th>KPI</th>
                    <th>Vs Tgt</th>
                    <th>Action</th>
                    <th>Sector Exception's</th>
                  </tr>
                  <tr>
                    <td>Nov-21</td>
                    <td>Rev</td>
                    <td>-76.35%</td>
                    <td>Inventory</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>Nov-21</td>
                    <td>Rev</td>
                    <td>-76.35%</td>
                    <td>Pricing</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Nov-21</td>
                    <td>Rev</td>
                    <td>-76.35%</td>
                    <td>Sales</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Dec-21</td>
                    <td>Rev</td>
                    <td>-71.02%</td>
                    <td>Inventory</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Dec-21</td>
                    <td>Rev</td>
                    <td>-71.02%</td>
                    <td>Pricing</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Dec-21</td>
                    <td>Rev</td>
                    <td>-71.02%</td>
                    <td>Sales</td>
                    <td>15</td>
                  </tr>
                </table> </a>: 
                this.state.selectedDashboardPath==="/routeProfitability" || this.state.selectedDashboardPath === '/routeProfitabilitySolution' || this.state.selectedDashboardPath === '/routeProfitabilityConsolidate' ? 
                // <Link to="/routeProfitabilitySolution">
                <a href ={alertUrl}>
                  <table className="table text-center">
                  <tr className="t-head">
                    <th >Month</th>
                    <th>KPI</th>
                    <th>Vs Tgt</th>
                    <th>Action</th>
                    <th>Route Exception's</th>
                  </tr>
                  <tr>
                    <td>Aug-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-98.75%</td>
                    <td>Inventory</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>Aug-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-98.75%</td>
                    <td>Pricing</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>Aug-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-98.75%</td>
                    <td>Sales</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>Aug-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-98.75%</td>
                    <td>Finance</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>Sep-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-19.83%</td>
                    <td>Inventory</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Sep-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-19.83%</td>
                    <td>Pricing</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Sep-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-19.83%</td>
                    <td>Sales</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>Sep-21</td>
                    <td>Surplus/Deficit</td>
                    <td>-19.83%</td>
                    <td>Finance</td>
                    <td>3</td>
                  </tr>
                </table></a>
                :
                //  <Link to="/pos">
                <a href ={alertUrl}>
                  <table className="table text-center">
                <tr className="t-head">
                  <th >Month</th>
                  <th>KPI</th>
                  <th>Vs Tgt</th>
                  <th>Action</th>
                  <th>OD Exception's</th>
                </tr>
                <tr>
                  <td>Nov-21</td>
                  <td>Rev</td>
                  <td>-51.50%</td>
                  <td>Inventory</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>Nov-21</td>
                  <td>Rev</td>
                  <td>-51.50%</td>
                  <td>Pricing</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Nov-21</td>
                  <td>Rev</td>
                  <td>-51.50%</td>
                  <td>Sales</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>Dec-21</td>
                  <td>Rev</td>
                  <td>-41.11%</td>
                  <td>Inventory</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Dec-21</td>
                  <td>Rev</td>
                  <td>-41.11%</td>
                  <td>Pricing</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Dec-21</td>
                  <td>Rev</td>
                  <td>-41.11%</td>
                  <td>Sales</td>
                  <td>35</td>
                </tr>
              </table></a>}
                </div>
              {/* <i
                className="bottom"
                onClick={() => this.props.history.push("/alerts")}
              >
                Show more
              </i> */}
            </div>
          ) : (
            <div className="ellipsis-loader">
              <h4>No new notifications to show</h4>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { userDetails, menuData, toggleList, initial, notification, isNewAlert } = this.state;
    return (
      <nav className="navbar navbar-default navbar-fixed-top" >
        <div className="container">
          <div className="navbar-header side-bar">
            <Sidebar userData={userDetails} menuData={menuData} {...this.props} />
            <div onClick={() => this.props.history.push(this.props.dashboardPath ? this.props.dashboardPath : Access.dashboardPath())}><img className='logo' src={images.airline_logo} alt=''></img></div>
          </div>

          {this.props.dashboard ? this.renderDashboardList() : <div />}

          <div id="navbar" className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">

              {this.props.dashboard ? this.renderFilters() : <div />}

             
                <li className="notification">
                  {isNewAlert ? <div className="circle-bell"></div> : ""}
                  <i
                    class="fa fa-bell-o"
                    aria-hidden="true"
                    onClick={() => this.toggleNotification()}
                  ></i>
                </li>
           
              <IconButton
                style={{ padding: toggleList ? '11px' : '1px' }}
                aria-label="open drawer"
                onClick={() => this.toggleList()}
                edge="start"
              >
                {toggleList ? <CloseIcon className='close-btn' /> :
                  <div className='initial-container'>
                    <h3 className='initial'>{initial}</h3>
                    <FiberManualRecordIcon className='circle' />
                  </div>}
              </IconButton>

            </ul>


            {toggleList ? this.renderProfileDropdown() : <div />}
            {notification}
            {notification ? this.renderNotificationDropdown() : <div />}
          </div>
        </div>
      </nav>
    );
  }
}

export default TopMenuBar;