const color = {
    primaryColor: "#2a3f54e8",
    secondaryColor: '#2c3f4c',
    green: '#21d521',
    gray: '#EDEDED',
    green: '#10da10',
    red: 'red',
    dark_grey: '#7f8c8d',
    gray: '#ededed',
    lightBlue: '#b7e4ff',
    header: '#2a3f54',
    darkPrimary: '#2e303f',
    darkSencondary: '#0d1016',
    blue: '#1784c7'
}
export default color;