import React, { Component } from 'react';
import { images } from '../Constants/images';
import cookieStorage from '../Constants/cookie-storage';
import config from '../Constants/config';
import eventApi from '../API/eventApi';

const API_URL = config.API_URL

class DownloadCSV extends Component {

    sendEvent(name, path, page) {
        var eventData = {
            event_id: '3',
            description: `User downloaded ${name}`,
            where_path: path,
            page_name: page
        }
        eventApi.sendEvent(eventData)
    }

    render() {
        let userDetails = JSON.parse(cookieStorage.getCookie('userDetails'))
        const { url, name, path, page } = this.props;
        return (
            <a className="btn download"
                href={`${API_URL}/${url}&uid=${userDetails.id}&download=do`}
                onClick={() => this.sendEvent(name, path, page)}
                target='-/'>
                {`${name}`}
                <i className='fa fa-download'></i>
            </a>
        );
    }
}

export default DownloadCSV;
