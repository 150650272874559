import React, { Component } from 'react';
import APIServices from '../../API/apiservices';
import DataTableComponent from '../../Component/DataTableComponent'
import Constant from '../../Constants/validator';
import TotalRow from '../../Component/TotalRow';
import color from '../../Constants/color';
import '../../App.scss';
import './RouteProfitabilitySegmental.scss';
import TopMenuBar from '../../Component/TopMenuBar';
import cookieStorage from '../../Constants/cookie-storage';
import BrowserToProps from 'react-browser-to-props';
import Spinners from "../../spinneranimation";
import { result } from 'lodash';

const apiServices = new APIServices();

class RouteProfitabilitySegmental extends Component {
  constructor(props) {
    super(props);
    this.userDetails = JSON.parse(cookieStorage.getCookie('userDetails'))
    this.state = {
      bcData: [],
      startDate: '',
      endDate: '',
      gettingDay: null,
      gettingMonth: null,
      gettingYear: null,
      routeGroup: '',
      regionId: '*',
      countryId: '*',
      routeId: '*',
      flight: '*',
      duration: 'YTD',
      headerData: [],
      headerColumns: [],
      revData: [],
      commercialData: [],
      loadingCommercial: false,
      loadingNonCommercial: false,
      routeMonthRowClassRule: {
        'highlight-row': 'data.highlightMe',
      },
    }
    Constant.sendEvent('1', 'viewed Route Profitability Consolidate Page', '/routeProfitabilityConsolidate', 'Route Profitability Consolidate');
  }

  componentDidMount() {
    var self = this;

    const header = apiServices.getRPSegmentalHeader();
    self.setState({ headerData: header[0].rowData, headerColumns: header[0].columnName })

    self.getFiltersValue();
  }

  getFiltersValue = () => {
    let routeGroup = window.localStorage.getItem('RouteGroupSelected')
    let RegionSelected = window.localStorage.getItem('RouteRegionSelected')
    let CountrySelected = window.localStorage.getItem('RouteCountrySelected')
    let RouteSelected = window.localStorage.getItem('RouteSelected')
    let dateRange = JSON.parse(window.localStorage.getItem('RRDateRangeValue'))
    let endDateArray = dateRange[0].endDate.split('-')
    let RRDay = window.localStorage.getItem('RRDay')
    let FlightSelected = window.localStorage.getItem('RPFlightSelected')

    if (routeGroup === null || routeGroup === '' || routeGroup === 'Null') {
      if (Object.keys(this.userDetails.route_access).length > 0) {
        routeGroup = this.userDetails.route_access['selectedRouteGroup']
      } else {
        routeGroup = ['Network']
      }
    } else {
      routeGroup = JSON.parse(routeGroup)
    }
    this.getLegends(dateRange);
    this.setState({
      routeGroup: routeGroup.join("','"),
      regionId: RegionSelected === null || RegionSelected === 'Null' || RegionSelected === '' ? '*' : JSON.parse(RegionSelected),
      countryId: CountrySelected === null || CountrySelected === 'Null' || CountrySelected === '' ? '*' : JSON.parse(CountrySelected),
      routeId: RouteSelected === null || RouteSelected === 'Null' || RouteSelected === '' ? '*' : JSON.parse(RouteSelected),
      flight: FlightSelected === null || FlightSelected === 'Null' || FlightSelected === '' ? '*' : `'${FlightSelected}'`,
      gettingYear: endDateArray[0],
      gettingMonth: window.monthNumToName(endDateArray[1]),
      gettingDay: RRDay === 'false' ? null : endDateArray[2],
    }, () => this.getInitialData())
  }

  getInitialData() {
    this.getTableData();

  }

  getTableData() {
    let self = this;
    let { routeGroup, gettingDay, gettingMonth, gettingYear, regionId, countryId, routeId, flight, duration } = this.state;

    self.setState({ loadingNonCommercial: true, loadingCommercial: true })

    apiServices.getRPSegmentalInfo(gettingYear, gettingMonth, gettingDay, routeGroup, regionId, countryId, routeId, flight, 'Null', duration).then((result) => {
      this.setState({ loadingNonCommercial: false, loadingCommercial: false });
      if (result) {
        this.setNormalizedData(result[0]['rowData'])
      }
    })
  }

  setNormalizedData = (data) => {
    const revData = ['Total Revenue', 'Total Variable Cost', 'Total Direct Fixed Cost', 'Total Other Fixed Cost', 'Total Cost', 'Surplus/Deficit'];
    const revValues = [];
    const commercialValues = [];
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (revData.indexOf(data[i]['Name']) > -1) {
          revValues.push(data[i])
        } else {
          commercialValues.push(data[i])
        }
      }
      this.setState({ revData: revValues, commercialData: commercialValues });
    }
  }

  getLegends(dateRange) {
    let bcData = window.localStorage.getItem('RRBCData')
    bcData = bcData ? JSON.parse(bcData) : []
    let startDate = dateRange[0].startDate.split('-')
    let endDate = dateRange[0].endDate.split('-')
    startDate = new Date(startDate[0], startDate[1] - 1, startDate[2])
    endDate = new Date(endDate[0], endDate[1] - 1, endDate[2])
    this.setState({ bcData, startDate: Constant.getDateFormat(startDate), endDate: Constant.getDateFormat(endDate) })
  }

  toggleDuration = (e) => {
    let dataDuration = e.target.value;
    this.setState({ duration: dataDuration }, () => this.getTableData())
  }

  render() {
    return (
      <div className="route-profitabilty-consolidate-page">
        <TopMenuBar dashboardPath={'/routeProfitability'} {...this.props} />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 top">
            <div className="navdesign">
              <div className="col-md-7 col-sm-7 col-xs-12 toggle1">
                <h2>Route Profitability Segmental</h2>
                <section>
                  <nav>
                    <ol className="cd-breadcrumb">
                      <p>{`${this.state.routeGroup} `}</p>
                      {this.state.bcData.map((item) =>
                        <div style={{ cursor: item.disable ? 'not-allowed' : 'pointer' }}>
                          <li id={item.val} title={`${item.title} : ${item.val}`}>
                            &nbsp;{` > ${item.val}`}
                          </li>
                        </div>
                      )}
                    </ol>
                  </nav>
                </section>
              </div>

              <div className="col-md-5 col-sm-5 col-xs-12 toggle2">
                <h4 style={{ marginRight: "20px" }}>Date Range : {`${this.state.startDate} - ${this.state.endDate}`}</h4>
                <h4>Select Duration :</h4>
                <select className="form-control cabinselect currency-dropdown" onChange={(e) => this.toggleDuration(e)}>
                  <option value='Daily' selected={this.state.duration === 'Daily' ? true : false}>Daily</option>
                  <option value='MTD' selected={this.state.duration === 'MTD' ? true : false}>MTD</option>
                  <option value='YTD' selected={this.state.duration === 'YTD' ? true : false}>YTD</option>
                </select>
              </div>

            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel" >
              <div className="x_content">
                <div className="tables-main">
                  <div className="tableHeader">
                    <DataTableComponent
                      rowData={this.state.headerData}
                      columnDefs={this.state.headerColumns}
                      autoHeight={'autoHeight'}
                      route={true}
                    />
                  </div>
                  <div className="tableContent" >
                    <h6>Revenue and Costs</h6>
                    <DataTableComponent
                      rowData={this.state.revData}
                      columnDefs={this.state.headerColumns}
                      autoHeight={'autoHeight'}
                      hideHeader={'0'}
                      rowClassRules={this.state.routeMonthRowClassRule}
                      route={true}
                      loading={this.state.loadingNonCommercial}
                    />

                    <h6>Commercial Info (Excluding CHARTER)</h6>
                    <DataTableComponent
                      rowData={this.state.commercialData}
                      columnDefs={this.state.headerColumns}
                      hideHeader={'0'}
                      height={'60vh'}
                      autoHeight={'autoHeight'}
                      rowClassRules={this.state.routeMonthRowClassRule}
                      route={true}
                      loading={this.state.loadingCommercial}
                    />
                    {/* <TotalRow
                      rowData={this.state.routeCommercialTotal}
                      columnDefs={this.state.headerColumns}
                      responsive={true}
                      loading={this.state.loadingCommercial}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const NewComponent = BrowserToProps(RouteProfitabilitySegmental);


export default NewComponent;