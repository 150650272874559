import React from "react";
import axios from "axios";
import eventApi from "../../API/eventApi";
import api from "../../API/api";
import APIServices from "../../API/apiservices";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { images } from "../../Constants/images";
import Validator from "../../Constants/validator";
import _ from "lodash";
import Swal from "sweetalert2";
import Modal from "react-bootstrap-modal";
import "./login.scss";
import cookieStorage from "../../Constants/cookie-storage";
import config from "../../Constants/config";
import Constants from "../../Constants/validator";
import {Redirect} from 'react-router-dom';

// const apiServices = new APIServices();

// let error = Constants.getParameterByName("error", window.location.href);
// error = error ? error : "";

class Login extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      // email: "",
      // password: "",
      // emailMessage: "",
      // passwordMessage: "",
      // token: "",
      // disable: true,
      // loading: false,
      // displayModal: false,
      email:"",
      password:"",
      loggedIn: false,
      loginFailMsg: ""
    };
    // this.textInput = React.createRef();
  }

  // componentDidMount() {
  //   cookieStorage.deleteCookie();
  //   // setTimeout(() => {
  //   //   this.textInput.current.focus();
  //   // }, 800);
  //   this.registerloginButtonClick();
  // }

  // registerloginButtonClick = () => {
  //   document.addEventListener("keydown", (event) => {
  //     if (event.keyCode === 13) {
  //       this.login();
  //     }
  //   });
  // };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // this._validate(e);
  };

  handleLogin = () => {
    const { email, password} = this.state;
    let errorMsg = "";
    if(email === ""){
      errorMsg = "Please enter your email-id";
    }else if(password === ""){
      errorMsg = "Please enter your password"
    }else{
      if(email === 'revemax@admin.com' && password === 'HardRocks'){
        cookieStorage.createCookie('Authorization', `Token wefgwrgergertreydgeg`, 1)
        const headers = {
            headers: {
                'Authorization': `Token wefgwrgergertreydgeg`
            }
        }
        cookieStorage.createCookie('userDetails','{"last_login":null,"email":"maaz.k@revemax.ai","username":"Maaz Kaleemulla","is_active":true,"is_staff":true,"created_at":"2021-06-22T00:09:00","updated_at":"2021-07-22T07:26:00","is_admin":false,"role":"Super Admin","access":"#*","is_resetpwd":false,"systemmodule":["POS Dashboard","Route Dashboard","Reject Alert","View Alerts","Alert Settings","Data Load Indicator","View Data Load Indicator","Route Profitability"],"id":4,"route_access":{}}', 1);
        cookieStorage.createCookie('menuData', '[{"ID":9,"Name":"Dashboard","Path":"/","ParentId":null,"module_type":"page","event_code":null,"visibility":"show","submenu":[{"ID":10,"Name":"POS Dashboard","Path":"/posDashboard","ParentId":"9","visibility":"show","ModuleType":"page"},{"ID":11,"Name":"Route Dashboard","Path":"/routeDashboard","ParentId":"9","visibility":"show","ModuleType":"page"},{"ID":29,"Name":"Route Profitability","Path":"/routeProfitability","ParentId":"9","visibility":"show","ModuleType":"page"}]},{"ID":26,"Name":"Data Load Indicator","Path":"/","ParentId":null,"module_type":"page","event_code":null,"visibility":"show","submenu":[{"ID":27,"Name":"View Data Load Indicator","Path":"/dataLoadIndicator","ParentId":"26","visibility":"show","ModuleType":"page"}]},{"ID":23,"Name":"Alert","Path":"/","ParentId":null,"module_type":"page","event_code":null,"visibility":"show","submenu":[{"ID":24,"Name":"View Alerts","Path":"/alerts","ParentId":"23","visibility":"show","ModuleType":"page"},{"ID":25,"Name":"Alert Settings","Path":"/alertSetting","ParentId":"23","visibility":"show","ModuleType":"page"}]}]', 1);
        //localStorage.setItem('token','wefgwrgergertreydgeg')
        this.setState({
          loggedIn: true
        })
        // console.log(this.state);
      }else{
        errorMsg = "The email-id or password that you've entered is incorrect"
      }
    }

    if(errorMsg!=''){
      this.setState({
        loginFailMsg: errorMsg
      })
    }

      
  }
  // _validate = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   let msg = ``;

    // if (name === "email") {
    //   let isValid = Validator.validateEmail(value);
    //   msg =
    //     value === ""
    //       ? `Username should not be empty*`
    //       : !isValid
    //       ? `Please enter valid username`
    //       : ``;
    //   this.setState({ emailMessage: msg });
    // } else if (name === "password") {
    //   // let isValid = Validator.validatePassword(value);
    //   msg =
    //     value === ""
    //       ? `Password should not be empty*`
    //       : value.length < 7
    //       ? `Please enter valid password`
    //       : ``;
    //   this.setState({ passwordMessage: msg });
    // }

  //   setTimeout(() => {
  //     this.formValid();
  //   }, 600);
  // };

  // formValid = () => {
  //   const { emailMessage, passwordMessage, email, password } = this.state;
  //   const errorCheck = _.isEmpty(emailMessage && passwordMessage);
  //   const emptyDataCheck = !_.isEmpty(email && password);
  //   if (errorCheck && emptyDataCheck) {
  //     this.setState({ disable: false });
  //   } else {
  //     this.setState({ disable: true });
  //   }
  // };

  // resetState = () => {
  //   this.setState({
  //     email: "",
  //     password: "",
  //     emailMessage: "",
  //     passwordMessage: "",
  //     disable: true,
  //     loading: false,
  //   });
  // };

  // login = () => {
  //   error = "";
  //   this.setState({ loading: true });
  //   window.location.href = `${config.API_URL}/initiatelogin`;
    // if (!this.state.disable) {
    //   this.setState({ loading: true })
    //   let data = {
    //     email: this.state.email,
    //     password: this.state.password
    //   }
    //   api.post('api/login', data)
    //     .then((response) => {
    //       this.redirectionToDashboard(response.data)
    //     })
    //     .catch((error) => {
    //       console.log('error', error.response)
    //       this.setState({ loading: false })
    //       if (error.response) {
    //         Swal.fire({
    //           title: 'Error!',
    //           text: error.response.data.error,
    //           icon: 'error',
    //           confirmButtonText: 'Ok'
    //         }).then(() => {
    //           this.resetState()
    //         })
    //       } else {
    //         Swal.fire({
    //           title: 'Error!',
    //           text: 'Something went wrong. Please try after some time',
    //           icon: 'error',
    //           confirmButtonText: 'Ok'
    //         }).then(() => {
    //           this.resetState()
    //         })
    //       }
    //     });
    // }
  // };

  // sendEvent = (token) => {
  //   var eventData = {
  //     event_id: "4",
  //     description: "User logged in into the system",
  //     where_path: "/",
  //     page_name: "Login Page",
  //   };
  //   eventApi.sendEventWithHeader(eventData, token);
  // };

  // redirectionToDashboard = (logInResponse) => {
  //   const token = `Token ${logInResponse.token}`;
  //   eventApi
  //     .getMenusForDashboard(token)
  //     .then((response) => {
  //       this.setState({ loading: false });
  //       console.log("rahul");
  //       if (response) {
  //         if (response.data) {
  //           this.sendEvent(token);
  //           cookieStorage.createCookie(
  //             "Authorization",
  //             `Token ${logInResponse.token}`,
  //             1
  //           );
  //           cookieStorage.createCookie(
  //             "userDetails",
  //             JSON.stringify(logInResponse),
  //             1
  //           );
  //           cookieStorage.createCookie(
  //             "menuData",
  //             JSON.stringify(response.data.menus),
  //             1
  //           );
  //           cookieStorage.createCookie(
  //             "events",
  //             JSON.stringify(response.data.events),
  //             1
  //           );
  //           let menuData = response.data.menus;
  //           let dashboard = menuData.filter(
  //             (data, i) => data.Name === "Dashboard"
  //           );
  //           let path = dashboard[0].submenu[0].Path;
  //           this.props.history.push(`${path}`);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Menu Error", err);
  //     });
  // };

  // closeModal() {
  //   this.setState({ displayModal: false });
  // }
  // const classes = useStyles();


  render() {
    const { loginFailMsg } = this.state;
    if(this.state.loggedIn){
      console.log(this.state);
      return <Redirect to = "/posDashboard" />
    }
    return (
      <Container className={`login`} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`paper`}>
          <div className={`top-login`}>
            <Avatar className={`avatar`}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          </div>
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={this.state.email}
            inputRef={this.textInput}
            onChange={(e) => this.handleChange(e)}
          /><p>{this.state.emailMessage}</p>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={this.state.password}
            onChange={(e) => this.handleChange(e)}
          /><p>{this.state.passwordMessage}</p>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          {/* For development */}
          <div className="logo-img">
            <img alt="logo" src={images.airline_logo} />
          </div>
          {/* For MH */}
          {/* <div className='logo-img'><img alt='logo' src={images.airline_logo} /></div> */}



          <div className="login-container">
            <div className="input-container">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Your Email"
                id="email"
                onChange = {(e) => this.handleChange(e)}
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Enter Your Password"
                onChange = {(e) => this.handleChange(e)}
              />
            </div>
            <p className="text-center">{ loginFailMsg }</p>
          </div>
          <Button
            // disabled={true}
            type={`submit`}
            fullWidth
            variant="contained"
            color="primary"
            className={`submit`}
            onClick={this.handleLogin}
          >
            {this.state.loading ? (
              <img src={images.ellipsis_loader} alt="" />
            ) : (
              `Click here to Login`
            )}
          </Button>
          {/* <p>{`${decodeURIComponent(error)}`}</p> */}
        </div>
      </Container>
    );
  }
}

export default Login;
